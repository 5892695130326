/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from "react";
import { Grid, Row, Col, FormControl, ControlLabel } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Card from "Admin/components/Card/Card.jsx";
import { clinicListAction } from "Admin/actions/clinic";
import { clinicchangestatusAction } from "Admin/actions/clinic";
import { countryListAction, stateListAction } from "Admin/actions/master";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Loading from "react-loading";
import Common from "Admin/components/Common/Common";
import { Button } from "react-bootstrap";
import ActionLinks from "Admin/views/ManageClinic/ActionLinks.jsx";
import moment from "moment";
import { addClinicAction } from "Admin/actions/clinic";
import { addClinicCsvAction } from "Admin/actions/clinic";
import { CSVLink } from "react-csv";
import * as Papa from "papaparse";
import * as API from "Admin/api/clinic_document";
//import { uploadFileAction } from 'Admin/actions/master';

var csvHeaders = [
  "Clinic Name",
  "Clinic Short Name",
  "Country",
  "State",
  "City",
  "Address",
  "Pin Code",
  "Email",
  "Phone",
  "Pattern",
];
var csvData = [];
class ClinicList extends Component {
  constructor(props) {
    super(props);

    let status = this.props.location.status ? this.props.location.status : "";
    this.state = {
      clinicListData: [],
      specializationList: [],
      isLoading: true,
      uploadButton: false,
      csvError: "",
      file_format_error: false,
      isUpload: false,
      isList: false,
      totalCount: 0,
      counts: {},
      currentPage: 1,
      sizePerPage: 10,
      searchText: "",
      asc_desc: true,
      formData: {
        doctorName: "",
        clinic_id: "",
        clinic_name: "",
        city: "",
        state: "",
        subscription_ending: "",
        status: status,
        data_updated: "",
        subscription_status: "",
        direction: "desc",
        order: "createdAt",
        offset: 0,
        limit: 10,
        searchText: "",
        specialization: "",
        imageType: "",
      },
    };

    this.onSearch = this.onSearch.bind(this);
    this.onUser = this.onUser.bind(this);
    this.importClinic = this.importClinic.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onUserCount = this.onUserCount.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    // this.subEndDate = this.subEndDate.bind(this);
    this.subStartDate = this.subStartDate.bind(this);
    this.getOutClass = this.getOutClass.bind(this);
    this.getClinicId = this.getClinicId.bind(this);
    this.getdoctorName = this.getdoctorName.bind(this);
    this.getClinicName = this.getClinicName.bind(this);
    this.getSpecialization = this.getSpecialization.bind(this);
    this.statusManage = this.statusManage.bind(this);
    this.getSatate = this.getSatate.bind(this);
    this.getCity = this.getCity.bind(this);
    this.sortHandle = this.sortHandle.bind(this);
  }

  componentDidMount() {
    if (this.props.location.pathname === "/admin/clinic-list/logo") {
      this.state.formData.imageType = "logo";
    } else if (this.props.location.pathname === "/admin/clinic-list/doctor") {
      this.state.formData.imageType = "doctor";
    } else if (this.props.location.pathname === "/admin/clinic-list/clinic") {
      this.state.formData.imageType = "clinic";
    }

    this.props.clinicListAction(this.state.formData);

    if (this.props.isStateList && this.props.isStateList === true) {
      this.setState({
        stateList: this.props.stateList.data.data,
      });
    } else {
      this.props.stateListAction("5c2dd6984d230fb174106cb2");
    }
    this.setState({ isList: true });
    this.specializationListAction();
  }

  componentWillReceiveProps(nextProps) {
    let formData = this.state.formData;
    if (
      nextProps.isCountryList !== this.props.isCountryList &&
      this.props.isCountryList == false
    ) {
      this.setState({
        countryList: nextProps.countryList.data.data,
      });
      let country = {};
      if (
        nextProps.countryList.data &&
        nextProps.countryList.data.data &&
        nextProps.countryList.data.data.length > 0
      ) {
        nextProps.countryList.data.data.map((v, i) => {
          if (v.short_name === "India") {
            country = { id: v._id, name: v.short_name };
          }
        });
        formData.country_id = country.id;
        formData.country = country.name;
        this.setState({ formData: formData });
      }
      this.props.stateListAction(country.id);
    }

    if (nextProps.isStateList !== this.props.isStateList) {
      this.setState({
        stateList: nextProps.stateList.data.data,
      });
    }
    if (
      nextProps.isClinicList !== this.props.isClinicList &&
      this.state.isList
    ) {
      // if(nextProps.ClinicList && nextProps.ClinicList.data && nextProps.ClinicList.data.data && nextProps.ClinicList.data.data.length>0){
      let countData = this.state.counts;
      if (
        nextProps.ClinicList.data &&
        nextProps.ClinicList.data?.total?.length > 0
      ) {
        countData = nextProps.ClinicList.data?.total[0];
      }
      this.setState({
        clinicListData: nextProps.ClinicList.data.data,
        totalCount: nextProps.ClinicList.data.count,
        counts: countData,
      });

      if (this.state.isLoading === true) {
        this.setState({ isLoading: false });
      }
      //  }
    }

    if (nextProps.isClinicListError !== this.props.isClinicListError) {
      if (this.state.isLoading === true) {
        this.setState({ isLoading: false });
      }
    }

    if (
      nextProps.isAddClinic !== this.props.isAddClinic &&
      nextProps.addClinicResponse.status === "Success" &&
      this.state.formData.clinic_name !== ""
    ) {
      this.props.handleClick("success", nextProps.addClinicResponse.msg);
    }

    if (nextProps.isAddClinicCsv !== this.props.isAddClinicCsv) {
      this.props.handleClick("success", nextProps.addClinicCsv.msg);

      // this.setState({uploadButton:true});
      this.componentDidMount();
    }

    if (nextProps.isAddClinicCsvError !== this.props.isAddClinicCsvError) {
      this.props.handleClick("error", nextProps.addClinicCsv.msg);
      //  this.setState({uploadButton:false});
    }
  }

  async specializationListAction() {
    let resp = await API.specializationList();
    let data = resp.data.data;
    const tempList = [...new Set(data && data.map((item) => item))].map(
      function (item) {
        return {
          label: item.name,
          value: item._id,
        };
      }
    );
    this.setState({ specializationList: tempList });
  }

  editButton(cell, row, enumObject, rowIndex) {
    return (
      <span>
        <Link
          to={{ pathname: `update-clinic/` + row._id, state: { row } }}
          onClick={this.toggleEdit.bind(this, row)}
        >
          <i className="fa fa-pencil" aria-hidden="true"></i>
        </Link>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <a
          href="javascript:void(0)"
          onClick={this.statusChangedHandler.bind(this, row)}
        >
          <i
            className={row.status === "active" ? "fa fa-check" : "fa fa-remove"}
            aria-hidden="true"
          ></i>
        </a>
        <a href="javascript:void(0)">
          <i className="fa fa-trash-o" aria-hidden="true"></i>
        </a>
      </span>
    );
  }

  manageRequest(cell, row, enumObject, rowIndex) {
    return (
      <span>
        <Link
          to={{
            pathname: `/admin/clinic-request/` + row._id + "/" + row.name,
            clinicId: row._id,
            state: row,
          }}
        >
          {row.totalRequest}
        </Link>
      </span>
    );
  }

  onUser(cell, row, enumObject, rowIndex) {
    if (row.userCount == 0) {
      return (
        <span>
          <Link
            to={{
              pathname: `/admin/user-list/` + row._id,
              clinicId: row._id,
              state: row,
            }}
          >
            {" "}
            <span className="inactive-color">{row.userCount}</span>
          </Link>
        </span>
      );
    } else {
      return (
        <span>
          <Link
            to={{
              pathname: `/admin/user-list/` + row._id,
              clinicId: row._id,
              state: row,
            }}
          >
            {row.userCount}
          </Link>
        </span>
      );
    }
  }

  getdoctorName(cell, row, enumObject, rowIndex) {
    return (
      <span>
        <Link to={{ pathname: `/admin/update-clinic/`, state: row }}>
          {row && row.doctorName}
        </Link>
      </span>
    );
  }

  getClinicId(cell, row, enumObject, rowIndex) {
    return (
      <span>
        <Link to={{ pathname: `/admin/update-clinic/`, state: row }}>
          {row && row.clinicId}
        </Link>
      </span>
    );
  }

  getClinicName(cell, row, enumObject, rowIndex) {
    return (
      <span>
        <Link to={{ pathname: `/admin/update-clinic/`, state: row }}>
          {row && row.name}
        </Link>
      </span>
    );
  }

  getSpecialization(cell, row, enumObject, rowIndex) {
    if (row.specializations.length > 0) {
      let specializationsA = [];
      for (let i in row.specializations) {
        specializationsA.push(row.specializations[i].name);
      }

      return specializationsA.join(", ");
    } else {
      return "";
    }
  }

  toggleEdit(event) {
    this.setState({ userRow: event });
  }

  statusChangedHandler(event, elename) {
    let newstatus = {};
    if (event.status == "active") {
      newstatus = "inactive";
    } else {
      newstatus = "active";
    }
    event["id"] = event._id;
    event["status"] = newstatus;
    this.props.clinicchangestatusAction(event);
  }

  statusManage(cell, row, enumObject, rowIndex) {
    if (row.status == "active") {
      return <span className="fletter active-color">{row.status}</span>;
    } else {
      return <span className="fletter inactive-color">{row.status}</span>;
    }
  }

  getSatate(cell, row, enumObject, rowIndex) {
    if (row && row.address && row.address.state) {
      return <span>{row.address.state}</span>;
    } else if (row && row.address2 && row.address2.state) {
      return <span>{row.address2.state}</span>;
    }
  }

  getCity(cell, row, enumObject, rowIndex) {
    if (row && row.address && row.address.city) {
      return <span>{row.address.city}</span>;
    } else if (row && row.address2 && row.address2.city) {
      return <span>{row.address2.city}</span>;
    }
  }

  handleCountryChange(event) {
    let data = this.state.formData;
    data["country"] = event.target[event.target.selectedIndex].text;
    data["country_id"] = event.target.value;
    this.setState({ formData: data });
    this.props.stateListAction(event.target.value);
  }

  handleStateChange(event) {
    let data = this.state.formData;
    data["state"] = event.target[event.target.selectedIndex].text;
    data["state_id"] = event.target.value;
    this.setState({ formData: data });
    this.props.clinicListAction(this.state.formData);
    this.setState({ isList: true });
  }

  handleChange(e) {
    e.preventDefault();
    let field = this.state.formData;
    field[e.target.name] = e.target.value;
    this.setState({ formData: field });
    this.props.clinicListAction(this.state.formData);
  }

  handleScbscription(e) {
    let entry = [];
    let field = this.state.formData;
    var new_date = "";
    var startdate = new Date();
    if (e.target.value) {
      new_date = moment(startdate, "DD-MM-YYYY").add(e.target.value, "days");
    }
    field[e.target.name] = new Date(new_date);
    this.setState({ formData: field });
  }

  getTask(cell, row, enumObject, rowIndex) {
    return row.totalTasks + " / " + row.tasks;
  }

  getDiet(cell, row, enumObject, rowIndex) {
    return (
      row.totalDietCount +
      " / " +
      row.totalDietChartPrint +
      " / " +
      row.totalDietChartShare +
      " / " +
      row.totalDietChartEmailCount
    );
  }
  onSearch(e) {
    e.preventDefault();
    let formData = this.state.formData;

    // formData['subscription_ending'] = formData.subscription_ending?Number(formData.subscription_ending):"";
    this.props.clinicListAction(formData);
    this.setState({ isList: true });
    this.setState({ currentPage: 1 });
  }

  _setTableOption() {
    if (this.state.isLoading) {
      return (
        <Loading
          type="bars"
          color="#000000"
          style={{ margin: "0px auto", width: "40px" }}
        />
      );
    } else {
      return (
        <div className="no-data">
          <h5>No data found!</h5>
        </div>
      );
    }
  }

  navigateto() {
    // if (this.clicked !== "Button") {
    window.location.href = "Admin/add-clinic";
    // }
    // reset
    this.clicked = "";
  }

  clinicListRefresh() {
    this.props.clinicListAction(this.state.formData);
  }

  actionButton(cell, row, enumObject, rowIndex) {
    //<Link to={{ pathname: `/admin/update-clinic/`, state: {row} }}  ><i className="fa fa-pencil" aria-hidden="true"></i></Link>
    //return (<span><ActionLinks clinicId={row._id} clinicDetails={row} patientclinicId={row.clinicId}  clinicListRefresh={() => { this.clinicListRefresh( )}}/></span>);
    let className = rowIndex > 5 ? "more-button" : ""
    return (
      <span className={className}>
        <ActionLinks
          clinicId={row._id}
          clinicDetails={row}
          clinicListRefresh={() => {
            this.clinicListRefresh();
          }}
        />
      </span>
    );
  }

  onUserCount(cell, row, enumObject, rowIndex) {
    return (
      (this.state.currentPage - 1) * this.state.sizePerPage + (rowIndex + 1)
    );
  }

  subStartDate(cell, row, enumObject, rowIndex) {
    return row && row.createdAt ? Common.formatDate(row.createdAt) : "";
  }

  // subEndDate(cell, row, enumObject, rowIndex){
  //  return (row && row.subscription_end_date)?Common.formatDate(row.subscription_end_date):'';
  // }

  goBackFun(e) {
    this.props.history.replace(sessionStorage.getItem("prvUrl"));
  }

  uploadContactCSV(e) {
    // this.setState({
    //   file_error: false, invalid_file: false
    // });
    let sheetData = [];
    let _this = this;
    this.setState({
      counter: -1,
    });
    const file = this.fileUpload.files[0];

    if (file && file.type == "text/csv") {
      Papa.parse(file, {
        download: true,
        header: false,
        delimiter: "\t",
        step: this.addClinic,
        complete: function (results) {
          for (var i = 0; i < results.data.length; i++) {
            if (results.data[i].length > 2) {
              if (results.data[i] !== "") {
                sheetData.push(results.data[i]);
              }
            } else if (results.data[i][0] !== "") {
              var res = results.data[i][0].split(",");
              sheetData.push(res);
            }
          }
          let hedars = [
            "Clinic Name",
            "Clinic Short Name",
            "Country",
            "State",
            "City",
            "Address",
            "Pin Code",
            "Email",
            "Phone",
            "Pattern",
          ];
          if (sheetData.length > 0) {
            if (JSON.stringify(hedars) == JSON.stringify(sheetData[0])) {
              _this.importClinic(sheetData, hedars);
            } else {
              _this.setState({
                invalid_file: true,
              });
            }
          } else {
            _this.setState({
              invalid_file: true,
            });
          }
        },
      });
    } else {
      this.setState({
        file_error: true,
      });
    }
  }

  importClinic(data, hedars) {
    let dbHedars = [
      "clinic_name",
      "doctorName",
      "short_name",
      "country",
      "state",
      "city",
      "address",
      "pin_code",
      "email",
      "phone",
      "pattern",
    ];
    let sendDatas = [];
    let sendData = [];
    let herror;
    for (var a = 0; a < hedars.length; a++) {
      if (hedars[a] === data[0][a]) {
        herror = false;
      } else {
        herror = true;
        return false;
      }
    }
    if (herror !== true) {
      for (var i = 0; i < data.length; i++) {
        let obj = {};
        for (var z = 0; z < hedars.length; z++) {
          if (i !== 0) {
            obj[dbHedars[z]] = data[i][z];
            if (hedars[z] === "Clinic Name") {
              obj[dbHedars[z]] = data[i][z];
            }
          }
        }

        if (obj.clinic_name) {
          obj["indexid"] = i - 1;
          sendData.push(obj);
        }
      }
    }

    if (herror !== true) {
      let csvData = {
        clinicCsv: sendData,
      };
      this.props.addClinicCsvAction(csvData);
      this.setState({
        uploadButton: false,
      });
    }
  }

  onInputChange() {
    this.setState({
      uploadButton: true,
      file_error: false,
      invalid_file: false,
    });
  }

  onPageChange(page, sizePerPage) {
    console.log(this.state, "state")
    let data = {};
    data["clinic_id"] = this.state.clinicId ? this.state.clinicId : "";
    if (this.state.searchText === "") {
      data["direction"] = this.state.formData.direction;
      data["order"] = this.state.formData.order;
      data["offset"] = page === -1 ? this.state.formData.offset : page - 1;
      data["limit"] = sizePerPage;
      data["subscription_ending"] = this.state.formData.subscription_ending;
    } else {
      data["direction"] = this.state.formData.direction;
      data["order"] = this.state.formData.order;
      data["offset"] = page === -1 ? this.state.formData.offset : page - 1;
      data["limit"] = sizePerPage;
      data["subscription_ending"] = this.state.formData.subscription_ending;
    }

    data["doctorName"] = this.state.formData.doctorName;
    data["clinic_name"] = this.state.formData.clinic_name;
    data["city"] = this.state.formData.city;
    data["state"] = this.state.formData.state;
    data["specialization"] = this.state.formData.specialization;
    data["searchText"] = this.state.formData.searchText;
    data["subscription_status"] = this.state.formData.subscription_status;
    data["status"] = this.state.formData.status;

    if (this.props.location.pathname === "/admin/clinic-list/logo") {
      data["imageType"] = "logo";
    } else if (this.props.location.pathname === "/admin/clinic-list/doctor") {
      data["imageType"] = "doctor";
    } else if (this.props.location.pathname === "/admin/clinic-list/clinic") {
      data["imageType"] = "clinic";
    }

    this.setState({ sizePerPage: sizePerPage });
    this.setState({ currentPage: page });
    this.props.clinicListAction(data);

    if (this.state.sizePerPage !== sizePerPage || this.state.totalCount < page * sizePerPage) {
      eval('$(".ps").scrollTop(0)');
    }
  }

  getOutClass(cell, row, enumObject, rowIndex) {
    let subscription_end_date = moment(row.subscription_end_date).format(
      "YYYY-MM-DD"
    );
    let today = moment().format("YYYY-MM-DD");
    let remaining_day = moment(row.subscription_end_date).diff(
      moment(),
      "days"
    );

    if (
      today > subscription_end_date ||
      (row.subscription_status && row.subscription_status == "inactive")
    ) {
      return (
        <div className="subs-red">
          {Common.formatDate(subscription_end_date)}
        </div>
      );
    } else if (remaining_day < 10 && remaining_day >= 1) {
      return (
        <div className="subss-yellow">
          {Common.formatDate(subscription_end_date)}
        </div>
      );
    } else {
      if (!row.subscription_end_date) {
        return;
      } else {
        return (
          <div className="subss-black">
            {Common.formatDate(subscription_end_date)}
          </div>
        );
      }
    }
  }

  handleSearchChange(e) {
    e.preventDefault();
    let field = this.state.formData;
    field[e.target.name] = e.target.value.trim();
    this.setState({ formData: field });

    if (e.target.value.length >= 3) {
      //this.onSearchChange(e.target.value);
    }

    if (e.target.value.length == 0) {
      field[e.target.name] = "";
      this.setState({ formData: field });
      this.onSearchChange(e.target.value);
    } else if (e.target.name == "searchText") {
      this.onSearchChange(e.target.value);
    }
  }

  handleSearchClinicId(e) {
    e.preventDefault();
    let field = this.state.formData;
    field[e.target.name] = e.target.value;
    this.setState({ formData: field });
    if (e.target.name == "clinic_id" && e.target.value.length > 2) {
      field[e.target.name] = e.target.value.trim();
      this.setState({ formData: field });
      //this.onSearchChange(e.target.value);
    } else if (e.target.value.length == 0) {
      field[e.target.name] = "";
      this.setState({ formData: field });
      this.onSearchChange(e.target.value);
    }
  }

  onSearchChange(text) {
    if (text !== "") {
      if (text.length >= 1) {
        let data = {};
        data["clinic_id"] = this.state.clinicId
          ? this.state.clinicId
          : this.state.formData.clinic_id;
        data["doctorName"] = this.state.formData.doctorName;
        data["clinic_name"] = this.state.formData.clinic_name;
        data["city"] = this.state.formData.city;
        data["direction"] = this.state.formData.direction;
        data["order"] = this.state.formData.order;
        data["offset"] = this.state.formData.offset;
        data["limit"] = this.state.formData.limit;
        data["state"] = this.state.formData.state;
        data["status"] = this.state.formData.status;
        data["specialization"] = this.state.formData.specialization;
        data["search"] = text;
        this.setState({ sizePerPage: this.state.sizePerPage });
        this.props.clinicListAction(data);
      }
    } else {
      this.componentDidMount();
    }
    this.setState({ searchText: text });
  }

  sortHandle() {
    this.setState({ asc_desc: !this.state.asc_desc });
    let data = this.state.formData;
    data.direction = this.state.asc_desc ? "asc" : "desc"
    this.setState({ formData: data });
    this.props.clinicListAction(data);
  }

  render() {
    const options = {
      // afterDeleteRow: this.handleDeleteButtonClick,
      onSortChange: this.onSortChange,
      clearSearch: true,
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3, // the pagination bar size.
      prePage: "Prev", // Previous page button text
      nextPage: "Next", // Next page button text
      firstPage: "First", // First page button text
      lastPage: "Last", // Last page button text
      paginationShowsTotal: this.renderShowsTotal,   //this.renderShowsTotal,  // Accept bool or function
      paginationPosition: "bottom", // default is bottom, top and both is all available
      sizePerPage: this.state.sizePerPage,
      onPageChange: this.onPageChange,
      page: this.state.currentPage,
      onSearchChange: this.onSearchChange,
      onSizePerPageList: this.sizePerPageListChange,
      noDataText: this._setTableOption(),
    };

    return (
      <div className="main-content" style={{ padding: "15px 0px" }}>
        <Grid fluid>
          <Row>
            <Card
              content={
                <div className="fresh-datatables">
                  <Row>
                    <Col md={6} Col xs={4} className="reduce-w">
                      <Button
                        className="inacti-button"
                        onClick={() => this.props.history.replace("add-clinic")}
                      >
                        Add Clinic
                      </Button>
                    </Col>
                    <Col md={6} className="fl-rr">
                      <div className="">
                        <div className="textdesc inlin-tt">
                          <span className="impot-t">IMPORT FROM FILES</span>
                          <span>
                            <CSVLink
                              headers={csvHeaders}
                              data={csvData}
                              filename={"addClinic.csv"}
                              className=""
                            >
                              Download a sample template (CSV)
                            </CSVLink>
                          </span>
                        </div>
                        {/* <div className="form-group">
                                            <input type="button"  value="Upload Companies"  onClick={this.toggle} className="btn btn-primary" />
                                        </div> */}
                      </div>
                      <div className="mange-nt">
                        <input
                          className="csv-input"
                          type="file"
                          name="files"
                          id="files"
                          accept=".csv"
                          onChange={this.onInputChange}
                          ref={(ref) => (this.fileUpload = ref)}
                        />
                        {this.state.uploadButton == true && (
                          <Button
                            bsStyle="info"
                            className="csvBtn"
                            onClick={(e) => this.uploadContactCSV(e)}
                          >
                            Upload
                          </Button>
                        )}
                        {this.state.file_error == true && (
                          <span className="errorMsg"> CSV is required.</span>
                        )}
                        {this.state.invalid_file == true && (
                          <span className="errorMsg"> Invalid CSV.</span>
                        )}
                      </div>
                      <span className="errorMsg">{this.state.csvError}</span>
                    </Col>
                  </Row>
                  <hr></hr>
                  <Row>
                    <Col md={3}>
                      <div className="clinic-list-card">
                        Total Clinic :<b> {this.state.totalCount} </b>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="clinic-list-card">
                        Total Patients :
                        <b>
                          {" "}
                          {this.state.counts.totalPatients
                            ? this.state.counts.totalPatients
                            : 0}{" "}
                        </b>
                      </div>
                    </Col>

                    <Col md={3}>
                      <div className="clinic-list-card">
                        Total App Download :
                        <b>
                          {" "}
                          {this.state.counts.totalAppPatients
                            ? this.state.counts.totalAppPatients
                            : 0}{" "}
                        </b>
                      </div>
                    </Col>

                    <Col md={3}>
                      <div className="clinic-list-card">
                        Total Diet Chart (S/P) :
                        <b>
                          {" "}
                          {this.state.counts.totalDietChartShare
                            ? this.state.counts.totalDietChartShare
                            : 0}{" "}
                          /{" "}
                          {this.state.counts.totalDietChartPrint
                            ? this.state.counts.totalDietChartPrint
                            : 0}
                        </b>
                      </div>
                    </Col>

                    <Col md={3}>
                      <div className="clinic-list-card">
                        Video/Document Shared :
                        <b>
                          {" "}
                          {this.state.counts.totalVideoShare
                            ? this.state.counts.totalVideoShare
                            : 0}{" "}
                          /{" "}
                          {this.state.counts.totalDocumentShare
                            ? this.state.counts.totalDocumentShare
                            : 0}
                        </b>
                      </div>
                    </Col>

                    <Col md={3}>
                      <div className="clinic-list-card">
                        Total EMR Created :
                        <b>
                          {" "}
                          {this.state.counts.totalEMR
                            ? this.state.counts.totalEMR
                            : 0}{" "}
                        </b>
                      </div>
                    </Col>

                    <Col md={3}>
                      <div className="clinic-list-card">
                        Glucose Diary Users :
                        <b>
                          {" "}
                          {this.state.counts.totalGlucoseDiaryUser
                            ? this.state.counts.totalGlucoseDiaryUser
                            : 0}{" "}
                        </b>
                      </div>
                    </Col>

                    <Col md={3}>
                      <div className="clinic-list-card">
                        Insulin Calculator Users :
                        <b>
                          {" "}
                          {this.state.counts.totalInsulinCalculatorUser
                            ? this.state.counts.totalInsulinCalculatorUser
                            : 0}{" "}
                        </b>
                      </div>
                    </Col>
                  </Row>
                  <hr></hr>
                  <Row>
                    <Col md={2}>
                      <Col componentClass={ControlLabel}>
                        Subscription Ending
                      </Col>
                      {/*<FormControl componentClass="select" name="subscription_ending" onChange={e => { this.handleScbscription(e); }}> */}
                      <FormControl
                        componentClass="select"
                        name="subscription_ending"
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                      >
                        <option value="">Select</option>
                        <option value="7">7 Days</option>
                        <option value="30">1 Month</option>
                        <option value="60">2 Months</option>
                        <option value="180">6 Months</option>
                        <option value="365">1 Year</option>
                      </FormControl>
                    </Col>
                    <Col md={2}>
                      <Col componentClass={ControlLabel}>Clinic Status</Col>
                      <FormControl
                        componentClass="select"
                        name="status"
                        value={this.state.formData.status}
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                      >
                        <option value="">Select</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </FormControl>
                    </Col>
                    {/*<Col md={2}>
                          <Col componentClass={ControlLabel}>
                              Data Update
                          </Col>
                          <FormControl componentClass="select" name="data_updated" onChange={e => { this.handleChange(e); }}>
                              <option value="">-- Select Data Update --</option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                          </FormControl>
                        </Col>  */}
                    <Col md={3}>
                      <Col componentClass={ControlLabel}>
                        Subscription Status
                      </Col>
                      <FormControl
                        componentClass="select"
                        name="subscription_status"
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                      >
                        <option value="">Select</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </FormControl>
                    </Col>
                    <Col sm={3}>
                      <Col componentClass={ControlLabel}>State</Col>
                      <FormControl
                        componentClass="select"
                        name="state"
                        onChange={(e) => {
                          this.handleStateChange(e);
                        }}
                      >
                        <option value="">Please select</option>
                        {this.state.stateList &&
                          this.state.stateList.map(function (item) {
                            return (
                              <option key={item._id} value={item._id}>
                                {item.name}
                              </option>
                            );
                          })}
                      </FormControl>
                    </Col>
                    <Col md={1}>
                      <Button
                        className="primary searchBtn"
                        onClick={(e) => {
                          this.onSearch(e);
                        }}
                      >
                        Search
                      </Button>
                    </Col>
                  </Row>

                  <br />
                  {/*<Row className="search-section">
                    <Col sm={3}>
                      <Col componentClass={ControlLabel}>
                        Country
                      </Col>
                      <FormControl componentClass="select" name="country" value={this.state.formData.country_id} onChange={e => { this.handleChange(e); this.handleCountryChange(e); }}>
                        <option value="">Please select</option>
                        {this.state.countryList && this.state.countryList.map(function (item) {
                          return <option key={item._id} value={item._id}>{item.short_name}</option>
                        })}
                      </FormControl>
                      </Col>
                    
                  </Row>
                  <br />*/}

                  <Row className="search-section">
                    <Col md={2} className="form-group">
                      <FormControl
                        type="text"
                        name="clinic_id"
                        id="clinic_id"
                        defaultValue={this.state.formData.clinic_id}
                        onChange={(e) => {
                          this.handleSearchClinicId(e);
                        }}
                        placeholder="Clinic Id"
                      />
                    </Col>
                    <Col md={3} className="form-group">
                      <FormControl
                        type="text"
                        name="clinic_name"
                        id="clinic_name"
                        defaultValue={this.state.formData.clinic_name}
                        onChange={(e) => {
                          this.handleSearchChange(e);
                        }}
                        placeholder="Search Clinic, Dr Name"
                      />
                    </Col>

                    <Col md={3} className="form-group">
                      <FormControl
                        type="text"
                        name="searchText"
                        id="searchText"
                        defaultValue={this.state.formData.searchText}
                        onChange={(e) => {
                          this.handleSearchChange(e);
                        }}
                        placeholder="Search City, Phone, Email"
                      />
                    </Col>

                    <Col md={3} className="form-group">
                      <FormControl
                        componentClass="select"
                        name="specialization"
                        onChange={(e) => {
                          this.handleSearchChange(e);
                        }}
                      >
                        <option value="">Select specialization</option>
                        {this.state.specializationList &&
                          this.state.specializationList.map(function (item) {
                            return (
                              <option key={item.value} value={item.value}>
                                {item.label}
                              </option>
                            );
                          })}
                      </FormControl>
                    </Col>
                  </Row>
                  <hr></hr>
                  <div className="table-responsive ui-tbles">
                    <BootstrapTable
                      deleteRow={false}
                      data={this.state.clinicListData}
                      search={false}
                      multiColumnSearch={true}
                      pagination={true}
                      options={options}
                      striped
                      hover
                      condensed
                      scrollTop={"Bottom"}
                      remote={true}
                      fetchInfo={{ dataTotalSize: this.state.totalCount }}
                    >
                      <TableHeaderColumn
                        hidden={true}
                        isKey
                        tdAttr={{ "data-attr": "_id" }}
                        dataField="invitationId"
                        searchable={false}
                      >
                        Id
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ width: "35px", textAlign: "center" }}
                        tdStyle={{ width: "35px", textAlign: "center" }}
                        tdAttr={{ "data-attr": "#" }}
                        dataField=""
                        dataFormat={this.onUserCount}
                      >
                        #
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ width: "50px", textAlign: "center" }}
                        tdStyle={{ width: "50px", textAlign: "center" }}
                        tdAttr={{ "data-attr": "Clinic Id" }}
                        dataField="clinicId"
                        dataFormat={this.getClinicId}
                      >
                        Id
                        {
                          this.state.asc_desc ?
                            <i onClick={(e) => { this.sortHandle() }} style={{ cursor: 'pointer' }} class="fa fa-caret-up" aria-hidden="true"></i> :
                            <i onClick={(e) => { this.sortHandle() }} style={{ cursor: 'pointer' }} class="fa fa-caret-down" aria-hidden="true"></i>
                        }
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ width: "90px", textAlign: "center" }}
                        tdStyle={{ width: "90px", textAlign: "center" }}
                        tdAttr={{ "data-attr": "Dr.Name" }}
                        dataField="doctorName"
                        dataFormat={this.getdoctorName}
                      >
                        Dr.Name
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ width: "120px", textAlign: "center" }}
                        tdStyle={{ width: "120px", textAlign: "center" }}
                        tdAttr={{ "data-attr": "Clinic Name" }}
                        dataField="name"
                        dataFormat={this.getClinicName}
                      >
                        Clinic Name
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ width: "130px", textAlign: "center" }}
                        tdStyle={{ width: "130px", textAlign: "center" }}
                        tdAttr={{ "data-attr": "Clinic Name" }}
                        dataField="name"
                        dataFormat={this.getSpecialization}
                      >
                        Specialization
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ width: "70px", textAlign: "center" }}
                        tdStyle={{ width: "70px", textAlign: "center" }}
                        tdAttr={{ "data-attr": "State" }}
                        dataField="state"
                        dataFormat={this.getSatate}
                      >
                        State
                      </TableHeaderColumn>

                      <TableHeaderColumn
                        thStyle={{ width: "70px", textAlign: "center" }}
                        tdStyle={{ width: "70px", textAlign: "center" }}
                        tdAttr={{ "data-attr": "City" }}
                        dataField="city"
                        dataFormat={this.getCity}
                      >
                        City
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ width: "90px", textAlign: "center" }}
                        tdStyle={{ width: "90px", textAlign: "center" }}
                        tdAttr={{ "data-attr": "Request Pending" }}
                        dataField="totalRequest"
                        dataFormat={this.manageRequest.bind(this)}
                      >
                        Request Pending
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ width: "100px", textAlign: "center" }}
                        tdStyle={{ width: "100px", textAlign: "center" }}
                        tdAttr={{ "data-attr": "Subscription Ends" }}
                        dataField="subscription_end_date"
                        dataFormat={this.getOutClass}
                      >
                        Subscription Ends
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ width: "90px", textAlign: "center" }}
                        tdStyle={{ width: "90px", textAlign: "center" }}
                        tdAttr={{ "data-attr": "Clinic Status" }}
                        dataFormat={this.statusManage}
                        dataField="status"
                      >
                        Clinic Status
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ width: "100px", textAlign: "center" }}
                        tdStyle={{ width: "100px", textAlign: "center" }}
                        tdAttr={{ "data-attr": "Registration Date" }}
                        dataField="createdAt"
                        dataFormat={this.subStartDate}
                      >
                        Registration Date
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ width: "60px", textAlign: "center" }}
                        tdStyle={{ width: "60px", textAlign: "center" }}
                        tdAttr={{ "data-attr": "Users" }}
                        dataField="userCount"
                        dataFormat={this.onUser}
                      >
                        Users
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ width: "70px", textAlign: "center" }}
                        tdStyle={{ width: "70px", textAlign: "center" }}
                        tdAttr={{ "data-attr": "Patients" }}
                        dataField="patient"
                      >
                        Patients
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ width: "80px", textAlign: "center" }}
                        tdStyle={{ width: "80px", textAlign: "center" }}
                        tdAttr={{ "data-attr": "Task" }}
                        dataFormat={this.getTask}
                      >
                        Task(T/S)
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ width: "90px", textAlign: "center" }}
                        tdStyle={{ width: "90px", textAlign: "center" }}
                        tdAttr={{ "data-attr": "Diet Chart" }}
                        dataFormat={this.getDiet}
                      >
                        Diet Chart (T/P/S/E)
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ width: "70px", textAlign: "center" }}
                        tdStyle={{ width: "70px", textAlign: "center" }}
                        tdAttr={{ "data-attr": "totalGlucoseDiaryUser" }}
                        dataField="totalGlucoseDiaryUser"
                      >
                        Glucose Diary User
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ width: "90px", textAlign: "center" }}
                        tdStyle={{ width: "90px", textAlign: "center" }}
                        tdAttr={{ "data-attr": "totalInsulinCalculatorUser" }}
                        dataField="totalInsulinCalculatorUser"
                      >
                        Insulin Calculator User
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ width: "60px", textAlign: "center" }}
                        tdStyle={{ width: "60px", textAlign: "center" }}
                        tdAttr={{ "data-attr": "totalChatPatientCount" }}
                        dataField="totalChatPatientCount"
                      >
                        Chat Patient
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ width: "70px", textAlign: "center" }}
                        tdStyle={{ width: "70px", textAlign: "center" }}
                        tdAttr={{ "data-attr": "totalReportShareCount" }}
                        dataField="totalReportShareCount"
                      >
                        Report Share
                      </TableHeaderColumn>

                      <TableHeaderColumn
                        thStyle={{ width: "100px", textAlign: "center" }}
                        tdStyle={{ width: "100px", textAlign: "center" }}
                        tdAttr={{ "data-attr": "Action" }}
                        dataField="city"
                        dataFormat={this.actionButton.bind(this)}
                      >
                        Action
                      </TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                </div>
              }
            />
          </Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ClinicList: state.clinic.ClinicList,
    isClinicList: state.clinic.isClinicList,
    isClinicListError: state.clinic.isClinicListError,

    ClinicChangeStatus: state.clinic.ClinicChangeStatus,
    isClinicChangeStatus: state.clinic.isClinicChangeStatus,
    isClinicChangeStatusError: state.clinic.isClinicChangeStatusError,

    isAddClinic: state.clinic.isAddClinic,
    isAddClinicError: state.clinic.isAddClinicError,
    addClinicResponse: state.clinic.addClinicResponse,

    isAddClinicCsv: state.clinic.isAddClinicCsv,
    isAddClinicCsvError: state.clinic.isAddClinicCsvError,
    addClinicCsv: state.clinic.addClinicCsv,

    stateList: state.master.stateList,
    isStateList: state.master.isStateList,
    isStateListError: state.master.isStateListError,

    countryList: state.master.countryList,
    isCountryList: state.master.isCountryList,
    isCountryListError: state.master.isCountryListError,
  };
}
export default withRouter(
  connect(mapStateToProps, {
    clinicListAction,
    addClinicAction,
    addClinicCsvAction,
    clinicchangestatusAction,
    countryListAction,
    stateListAction,
  })(ClinicList)
);
