/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useEffect, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { withRouter, useParams } from "react-router-dom";
import LabTestDetail from './LabTestDetail';
import { useDispatch, useSelector } from "react-redux";
import * as API from "Front/api/emr";
import moment from 'moment';

const PrintLabDetail = () => {
    const componentRef = useRef();
    const onBeforeGetContentResolve = useRef(null);
    const [isPrinting, setIsPrinting] = useState(false)
    let { appointmentId, patientId } = useParams()
    const emrData = useSelector(store => store.emr)
    const [patinetData, setPatientData] = useState({});
    const [clinicData, setClinicData] = useState([]);
    const [phoneNo, setPhoneNo] = useState("");
    const [printMarginTop, setPrintMarginTop] = useState("150px");
    const [printMarginBottom, setPrintMarginBottom] = useState("20px");
    const [printMarginLeft, setPrintMarginLeft] = useState("10px");
    const [printMarginRight, setPrintMarginRight] = useState("10px");
    const [loading, setLoading] = useState(false);
    const [text, setText] = useState("Data Not Loaded");
    const [showPatientInfo, setShowPatientInfo] = useState(false)
    const [reportArr, setReportArr] = useState([])
    const [isDisabled, setIsDisabled] = useState(false)

    const reactToPrintContent = React.useCallback(() => {
        document.getElementById('divBreadCrumb').style.display = 'none';
        document.getElementById('divGeneralInformation').style.display = 'none';
        document.getElementById('divEmrMenuList').style.display = 'none';
        document.getElementById('divDates').style.display = 'none';
        document.getElementById('btnPrint').style.display = 'none';
        document.getElementById('divHead').style.display = 'block';
        //document.getElementById('trPrintHeader').style.display = 'inline-block';
        // document.getElementById('trHeader').style.display = 'none';


        return componentRef.current;
    }, [componentRef.current]);

    const reactToPrintTrigger = React.useCallback(() => {
        return <button disabled={isDisabled} id="btnPrint">Print</button>;
    }, []);

    const handleBeforePrint = React.useCallback(async () => {
        const collection = document.getElementsByClassName("img-icon");
        //console.log(reportArr, "reportArr")
        for (let i in reportArr) {
            let ele = document.getElementById(`img-icon-${i}`)
            if (ele != null)
                ele.style.display = 'none';


        }
        setIsPrinting(true)
    }, []);

    const convertPercentageToPx = (dimension, val) => {
        let a4WidthInPx = 793.7007874
        let a4HeightInPx = 1110.519685
        let result = 0
        result = dimension === "width" ? ((a4WidthInPx * val) / 100) : ((a4HeightInPx * val) / 100)
        return result
    }

    const handleAfterPrint = React.useCallback(async () => {
        document.getElementById('divBreadCrumb').style.display = 'block';
        document.getElementById('divGeneralInformation').style.display = 'block';
        document.getElementById('divEmrMenuList').style.display = 'block';
        document.getElementById('divDates').style.display = 'inline-flex';
        document.getElementById('btnPrint').style.display = 'block';
        document.getElementById('divHead').style.display = 'none';
        //document.getElementById('trPrintHeader').style.display = 'none';
        // document.getElementById('trHeader').style.display = 'block';
        setShowPatientInfo(false)
    }, [])

    const setPrintDataFun = (data, datesArr) => {
        if (data.patientData.length > 0)
            setPatientData(data?.patientData[0]);
        if (data?.clinicData.length > 0) {
            setClinicData(data?.clinicData[0]);
            setPhoneNo(data?.clinicData[0].address.phone);
            setPrintMarginTop(convertPercentageToPx("height", data?.clinicData[0].prescription_print_margin_top) + "px")
            setPrintMarginBottom(convertPercentageToPx("height", data?.clinicData[0].prescription_print_margin_bottom) + "px")
            setPrintMarginLeft(convertPercentageToPx("width", data?.clinicData[0].prescription_print_margin_left) + "px")
            setPrintMarginRight(convertPercentageToPx("width", 10) + "px")
            setReportArr([...datesArr])

        }




    }

    const handleOnBeforeGetContent = React.useCallback(() => {
        //console.log(reportArr, "DatesArr")
        setLoading(true);
        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;

            setTimeout(() => {
                setLoading(false);
                setShowPatientInfo(true)
                resolve();
            }, 500);
        });
    }, [setLoading, setText]);


    useEffect(() => {
        if (
            showPatientInfo &&
            typeof onBeforeGetContentResolve.current === "function"
        ) {
            onBeforeGetContentResolve.current();
        }
    }, [onBeforeGetContentResolve.current, showPatientInfo]);

    useEffect(() => {
        emrData.labData.length > 0 ? setIsDisabled(false) : setIsDisabled(true)
    }, [emrData.isLabDetails])

    const marginTopCss =
        "@media print {.testValueNotInRange {color:red !important} .pagebreak-avoid {page-break-inside: avoid !important;page-break-after: avoid !important;page-break-before: avoid !important;break-inside: avoid !important;}.print-table {width: 85%;}.component-to-print-itm{page-break-inside: avoid !important;}@page {  margin-top:" +
        printMarginTop +
        ";margin-bottom:" +
        printMarginBottom +
        ";margin-left:" +
        printMarginLeft +
        ";margin-right:" + printMarginRight + "}}";

    return (
        <div className="container-fluid" id='divContainer'>

            <div id="printable" >
                <style scoped>{marginTopCss}</style>
                <LabTestDetail props={isPrinting} ref={componentRef} showPatientInfo={showPatientInfo} />
                <div style={{ pointerEvents: isDisabled ? "none" : "initial", opacity: isDisabled ? "0.3" : "1" }} className='print-btn-wrapper print-btn-right is-no-data'>
                    <ReactToPrint
                        content={reactToPrintContent}
                        trigger={reactToPrintTrigger}
                        onBeforePrint={handleBeforePrint}
                        onAfterPrint={handleAfterPrint}
                        onBeforeGetContent={handleOnBeforeGetContent}

                    />
                </div>
            </div>
        </div>
    )
}

export default PrintLabDetail