/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { forwardRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import * as API from "../../api/emr";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loading from "react-loading";

const PrescriptionSnapShot = (props) => {
  let convertToMl = 0;
  const [onPageLoad, setOnPageLoad] = useState(true);
  let appointmentId = props.patientVisitData.emrVisitId;
  let patientId = props.patientVisitData.pId;
  let serachType =
    localStorage.getItem("is_brand_with_ingredients_disable") == "yes"
      ? "brand"
      : "barndwithingredients";
  let printLanguage = props.patientVisitData.language;
  const [patinetData, setPatientData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [compalintData, setComplaintData] = useState([]);
  const [diagnosisData, setDiagnosisData] = useState([]);
  const [recommendedPrescriptionData, setRecommendedPrescriptionData] =
    useState([]);
  const [
    recommendedPrescriptionSettingData,
    setRecommendedPrescriptionSettingData,
  ] = useState([]);
  const [patientPresciptionData, setPatientPresciptionData] = useState([]);
  const [clinicData, setClinicData] = useState([]);
  const [doctorImage, setDoctorImage] = useState("");
  const [title, setTitle] = useState(localStorage.getItem("title"));
  const [phoneNo, setPhoneNo] = useState("");
  const [valueInMl, setValueInMl] = useState("");
  const [isVitalDataExist, setIsVitalDataExist] = useState(false);
  const [instructionPrintData, setInstructionPrintData] = useState([]);
  const [doseTimePrintList, setDoseTimePrintList] = useState([]);
  const [dietAdvised, setDietAdvised] = useState(
    props?.patientVisitData?.lastDiet[0]
      ? props?.patientVisitData?.lastDiet[0]?.treatmentName
      : ""
  );
  const data = useSelector((store) => store.taskScreen);
  const getPrescriptionPrint = async () => {
    let resp = await API.prescriptionPrint({
      patientId: patientId,
      appointmentId: appointmentId,
      patientDetail: true,
    });
    if (resp.data.status === "Success" && resp.data.statusCode === 200) {
      setIsLoading(false);
      if (resp.data.patientData.length > 0) {
        setPatientData(resp.data?.patientData[0]);
        setDietAdvised(resp.data?.patientData[0]?.lastDiet[0]?.treatmentName);
      }
      setComplaintData(resp.data?.complainData);
      setDiagnosisData(resp.data?.diagonsisData);
      setPatientPresciptionData(resp.data?.patientPresciptionData);
      if (resp.data?.patientPresciptionData) {
        let patientPresciptionData = resp.data?.patientPresciptionData;
        if (patientPresciptionData.length > 0) {
          var local_index = patientPresciptionData
            .map(function (el) {
              return el.insulinCalculator;
            })
            .indexOf(true);
          if (local_index != -1) {
            props.setIsInsulinCalculator(true);
          }
        }
      }

      if (resp.data?.recommendedPrescriptionSettingData.length > 0)
        setRecommendedPrescriptionSettingData(
          resp.data?.recommendedPrescriptionSettingData[0]
            .recommendedPrescription
        );
      if (resp.data?.recommendedPrescription.length > 0)
        setRecommendedPrescriptionData(resp.data?.recommendedPrescription);

      if (resp.data?.clinicData.length > 0) {
        setClinicData(resp.data?.clinicData[0]);
        setDoctorImage(resp.data?.clinicData[0].logo_image);
        setPhoneNo(resp.data?.clinicData[0].address.phone);
      }
      if (resp.data?.instructionPrintData.length > 0)
        setInstructionPrintData(resp.data.instructionPrintData);

      if (resp.data?.instructionPrintData.length > 0)
        setDoseTimePrintList(resp.data.doseTimeList);
    }
  };

  const calculateBmi = (height, weight) => {
    let height_in_m = height / 100;
    if (height_in_m > 0 && weight > 0) {
      let bmi = weight / (height_in_m * height_in_m);
      return bmi.toFixed(2);
    } else {
      return;
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      if (onPageLoad) getPrescriptionPrint();
      setOnPageLoad(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (data) setDietAdvised(data.visitData.lastDiet[0]?.treatmentName);
  }, [props]);
  return (
    <div className="priscription-snapshot">
      {isLoading && (
        <Loading
          type="bars"
          color="#000000"
          style={{ margin: "0px auto", width: "40px" }}
        />
      )}
      <div style={{ margin: "0px auto", border: "0", width: "100%" }}>
        <div>
          <div>
            <span style={{ border: "0" }}>
              <div style={{ fontWeight: "normal", width: "100%", border: "0" }}>
                <div>
                  {dietAdvised && (
                    <div className="complaints-list">
                      <span style={{ border: "0" }}>
                        <strong>Diet Advised</strong>: <span></span>
                        {dietAdvised}
                      </span>
                    </div>
                  )}
                  {compalintData.length > 0 && (
                    <div className="complaints-list">
                      <span style={{ border: "0" }}>
                        <strong>Complaint</strong>:{" "}
                        <span>
                          {compalintData.map((complaint, idx) => (
                            <span>
                              {complaint.symptomsSubCategoryName}
                              {compalintData.length - 1 !== idx ? " | " : ""}
                            </span>
                          ))}
                        </span>
                      </span>
                    </div>
                  )}
                  {diagnosisData.length > 0 && (
                    <div>
                      <span style={{ border: "0" }}>
                        <strong>Diagnosis</strong>:
                        <span>
                          {diagnosisData.map((diagnosis, idx) => (
                            <span>
                              {diagnosis.diagonsisSubCategoryName}
                              {diagnosisData.length - 1 !== idx ? " | " : ""}
                            </span>
                          ))}
                        </span>
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="clearfix"></div>
            </span>
          </div>
          {patientPresciptionData.length > 0 && (
            <>
              <div>
                <span className="py-15" style={{ border: "0" }}>
                  <div style={{ width: "100%", margin: "4px 0", border: "0" }}>
                    <div>
                      <div>
                        <span style={{ fontSize: "18px", border: "0" }}>
                          <strong>Rx</strong>
                        </span>
                      </div>
                    </div>
                  </div>
                </span>
              </div>
              <div className="recommendations-list">
                {recommendedPrescriptionSettingData.map((itm, idx) =>
                  itm.recommendedType.map((obj, key) => {
                    if (obj.top !== undefined && obj.top == 1) {
                      return (
                        <span className="flex-itm">
                          <span className="fs-8">●</span>{" "}
                          {recommendedPrescriptionData.map((obj1, key1) => {
                            if (obj1._id === itm.recommendedPrescriptionId) {
                              return obj1.recommendedLanguage.hasOwnProperty(
                                printLanguage
                              )
                                ? obj1.recommendedLanguage[printLanguage]
                                : obj1.recommendedLanguage[1];
                            }
                          })}
                        </span>
                      );
                    }
                  })
                )}
              </div>

              <div>
                <span style={{ border: "0" }}>
                  <div
                    className="p-td-10 table-row-border"
                    style={{ width: "100%", border: "0" }}
                  >
                    <div style={{ verticalAlign: "top" }}>
                      {patientPresciptionData.map((itm, idx) => {
                        return (
                          <div className="drug-list-item component-to-print-itm">
                            <span
                              className="drug-details"
                              style={{ border: "0", width: "100%" }}
                            >
                              <div className="t-drug-type">
                                <strong>
                                  {++idx}. {itm.drugType}</strong>−{" "}
                                {serachType === "brand"
                                  ? <strong> {itm.drugNameWithDose} </strong>
                                  : serachType === "barndwithingredients"
                                    ? <><strong>{itm.drugNameWithDose}</strong>
                                      {itm.drugNameWithIngredients ?
                                        <div className="ingredients-item">
                                          ({itm.drugNameWithIngredients})
                                        </div> : ""
                                      }
                                    </>
                                    : itm.drugNameWithIngredients}{" "}
                                {" "}
                              </div>
                              <div className="dose-instructions">
                                <div className="t-dose-time">
                                  {itm.doseTimeSlot.map((ele, key) => {
                                    return (
                                      <div>
                                        {ele.customizeFrequency.map(
                                          (subEle, subKey) => {
                                            if (itm.drugType === "SYP")
                                              convertToMl = subEle.key;
                                            return (
                                              <span>
                                                {subEle.key === "." ? (
                                                  <strong>{subEle.key}</strong>
                                                ) : (
                                                  subEle.key
                                                )}
                                              </span>
                                            );
                                          }
                                        )}
                                        {itm.drugType === "INJ" ||
                                          itm.drugType === "INSULIN"
                                          ? itm.unit ? ` ${itm.unit}` : " U"
                                          : itm.drugType === "SYP"
                                            ? itm.unit ?
                                              ` ${itm.unit} (${5 * eval(convertToMl)} ml)`
                                              : ` TSF ( ${5 * eval(convertToMl)} ml)`
                                            : itm.unit ? itm.unit : ""}
                                        {doseTimePrintList.map((obj, key2) => {
                                          if (
                                            ele.doseTimeSlotId &&
                                            ele.doseTimeSlotId !== null &&
                                            obj._id === ele.doseTimeSlotId
                                          ) {
                                            if (
                                              obj.doseTimeSlotLanguage !==
                                              undefined
                                            ) {
                                              //lang = obj.instructionInDiffLanguage.hasOwnProperty(printLanguage)? obj.instructionInDiffLanguage[printLanguage]:obj.instructionInDiffLanguage[1];
                                              return (
                                                <span>
                                                  (
                                                  {obj.doseTimeSlotLanguage.hasOwnProperty(
                                                    printLanguage
                                                  )
                                                    ? obj.doseTimeSlotLanguage[
                                                    printLanguage
                                                    ]
                                                    : obj.doseTimeSlotLanguage[1]}
                                                  )
                                                </span>
                                              );
                                            } else {
                                              return (
                                                <span>
                                                  ({ele.doseTimeSlotName})
                                                </span>
                                              );
                                            }
                                          }
                                        })}
                                      </div>
                                    );
                                  })}
                                </div>
                                <span
                                  className="drug-instrns"
                                  style={{ textAlign: "left", border: "0" }}
                                >
                                  {itm.instructionName.map((ele1, key1) => {
                                    return (
                                      <span className="t-insdivuction">
                                        {instructionPrintData.map((obj, key2) => {
                                          //   if (ele1.instructionId && ele1.instructionId !== null && ele1.instructionType === "clinic" && obj._id === ele1.instructionId)
                                          if (
                                            ele1.instructionId &&
                                            ele1.instructionId !== null &&
                                            obj._id === ele1.instructionId
                                          ) {
                                            if (
                                              ele1.instructionType === "patient"
                                            ) {
                                              if (
                                                obj.instructionName ===
                                                ele1.instructionName
                                              ) {
                                                if (
                                                  obj.instructionInDiffLanguage !==
                                                  undefined
                                                ) {
                                                  //lang = obj.instructionInDiffLanguage.hasOwnProperty(printLanguage)? obj.instructionInDiffLanguage[printLanguage]:obj.instructionInDiffLanguage[1];
                                                  return (
                                                    <span>
                                                      (
                                                      {obj.instructionInDiffLanguage.hasOwnProperty(
                                                        printLanguage
                                                      )
                                                        ? obj
                                                          .instructionInDiffLanguage[
                                                        printLanguage
                                                        ]
                                                        : obj
                                                          .instructionInDiffLanguage[1]}
                                                      )
                                                    </span>
                                                  );
                                                }
                                              } else {
                                                return (
                                                  <span>
                                                    ({ele1.instructionName})
                                                  </span>
                                                );
                                              }
                                            } else {
                                              if (
                                                obj.instructionInDiffLanguage !==
                                                undefined
                                              ) {
                                                //lang = obj.instructionInDiffLanguage.hasOwnProperty(printLanguage)? obj.instructionInDiffLanguage[printLanguage]:obj.instructionInDiffLanguage[1];
                                                return (
                                                  <span>
                                                    (
                                                    {obj.instructionInDiffLanguage.hasOwnProperty(
                                                      printLanguage
                                                    )
                                                      ? obj
                                                        .instructionInDiffLanguage[
                                                      printLanguage
                                                      ]
                                                      : obj
                                                        .instructionInDiffLanguage[1]}
                                                    )
                                                  </span>
                                                );
                                              } else {
                                                return (
                                                  <span>
                                                    ({ele1.instructionName})
                                                  </span>
                                                );
                                              }
                                            }
                                            // return(<span>{lang}</span>)
                                          }
                                          // else if (ele1.instructionName === obj.instructionName) {
                                          //     if (obj.instructionInDiffLanguage !== undefined) {
                                          //         return (<span>({obj.instructionInDiffLanguage[1]})</span>)
                                          //     }
                                          // }
                                        })}
                                      </span>
                                    );
                                  })}
                                  <span className="t-duration">
                                    {itm.duration.no !== "" ? <strong> X</strong> : ""}{" "}
                                    {itm.duration.no} {itm.duration.period}{" "}
                                  </span>
                                </span>
                              </div>
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="clearfix pagebreak"></div>
                </span>
              </div>
              <div className="bottom-recommendations-list">
                {recommendedPrescriptionSettingData.map((itm, idx) => (
                  <div style={{ border: "0" }}>
                    {itm.recommendedType.map((obj, key) => {
                      if (obj.bottom !== undefined && obj.bottom == 1) {
                        return (
                          <span className="flex-itm">
                            <span className="fs-8">●</span>{" "}
                            {recommendedPrescriptionData.map((obj1, key1) => {
                              if (obj1._id === itm.recommendedPrescriptionId) {
                                return obj1.recommendedLanguage.hasOwnProperty(
                                  printLanguage
                                )
                                  ? obj1.recommendedLanguage[printLanguage]
                                  : obj1.recommendedLanguage[1];
                              }
                            })}
                          </span>
                        );
                      }
                    })}
                  </div>
                ))}
              </div>
              <div className="doctor-signs">
                <span style={{ border: "0", marginTop: "15px" }}>
                  <div
                    style={{
                      width: "100%",
                      position: "relative",
                      bottom: "0",
                      border: "0",
                    }}
                  >
                    <div>
                      <span style={{ border: "0" }}>
                        <div style={{ width: "100%", border: "0" }}>
                          <div>
                            <div>
                              {patinetData.nextDate && (
                                <span className="next-visit">
                                  <span
                                    className="py-15"
                                    style={{ border: "0" }}
                                  >
                                    <span
                                      style={{ border: "0" }}
                                      className="investigation-advised"
                                    >
                                      <strong>
                                        Next visit date :
                                        <span>
                                          {" "}
                                          {moment(patinetData.nextDate).format(
                                            "DD-MMM-YYYY"
                                          )}
                                        </span>
                                      </strong>
                                    </span>
                                  </span>
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="clearfix"></div>
                      </span>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PrescriptionSnapShot;
