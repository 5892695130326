/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  patientRegistrationAction,
  patientEditAction,
  getPatientDetailAction,
  visitListAction,
} from "Front/actions/home";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import { titleOptions } from "Front/variables/Variables.jsx";
import { appConstants } from "Front/_constants/app.constants.js";
import { patientListAction, addVisitAction } from "Front/actions/patient";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import loadingImg from "Admin/assets/img/loading.gif";
import MaskedInput from "react-maskedinput";
import {
  taskScreenAction,
  taskScreenHeightWeightRequireForCaloryAction,
} from "Front/actions/taskScreen";
import { emrSetVitalSignsAction } from "Front/actions/emr";
import * as PF from "Front/views/Home/PublicFunction.jsx";
import NotificationSystem from "react-notification-system";
import { style } from "Admin/variables/Variables.jsx";

let Validator = require("validatorjs");
let formArr = {};
let rules = {
  title: "required",
  first_name: "required|string",
  last_name: "string",
  gender: "required",
  // height: 'required|numeric|min:1',
  // weight: 'required|numeric|min:1',
  // height: 'numeric|min:1',
  // weight: 'numeric|min:1',
  height: "numeric",
  weight: "numeric",
  city: "string",
  //dob: 'required',
  email: "email",
  age: "required|numeric",
  phone:
    localStorage.getItem("is_mobile_no_mandatory") === "yes"
      ? "required|digits_between:10,12"
      : "digits_between:10,12",
};
let mess = {
  required: "This field is required",
};
let validation = [];

let editApi = false;

let isOnline = false;

class PatientRegistration extends Component {
  constructor(props) {
    super(props);
    console.log(props, "-----props-------")
    this.state = {
      formArr: [],
      patientInfo: this.props.patientData,
      patient_idError: null,
      titleError: null,
      first_nameError: null,
      last_nameError: null,
      genderError: "",
      dobError: null,
      ageError: null,
      emailError: null,
      heightError: null,
      weightError: null,
      cityError: null,
      remarkError: null,
      econsultationError: null,
      alert: null,
      formData: {
        patientClinicId: this.props.patientData
          ? this.props.patientData.patientId
          : "",
        patientId: this.props.patientData ? this.props.patientData._id : "",
        visitId: this.props.patientData
          ? this.props.patientData.emrVisitId
          : "",
        patientEditId: this.props.patientData ? this.props.patientData._id : "",
        title: this.props.patientData ? this.props.patientData.title : "",
        first_name: this.props.patientData
          ? this.props.patientData.firstName
          : "",
        last_name: this.props.patientData
          ? this.props.patientData.lastName
          : "",
        gender: this.props.patientData ? this.props.patientData.gender : "",
        phone: this.props.patientData ? this.props.patientData.phone : "",
        dob:
          this.props.vitalSignsData && this.props.vitalSignsData.dob
            ? moment(this.props.vitalSignsData.dob).format("YYYY-MM-DD")
            : this.props.patientData && this.props.patientData.dob
              ? moment(this.props.patientData.dob).format("YYYY-MM-DD")
              : "",
        is_dob: this.props.patientData ? this.props.patientData.is_dob : false,
        age:
          this.props.parentComponent == "headerlinks"
            ? ""
            : this.props.vitalSignsData.patientId === this.props.patientData._id
              ? this.props.vitalSignsData.age
                ? this.props.vitalSignsData.age
                : this.props.patientData.age
                  ? this.props.patientData.age
                  : ""
              : this.props.patientData.age
                ? this.props.patientData.age
                : "",
        email: this.props.patientData ? this.props.patientData.email : "",
        height:
          this.props.parentComponent == "headerlinks"
            ? ""
            : this.props.vitalSignsData.patientId === this.props.patientData._id
              ? this.props.vitalSignsData
                ? this.props.vitalSignsData.height
                : this.props.patientData
                  ? this.props.patientData.height
                  : ""
              : this.props.patientData
                ? this.props.patientData.height
                : "",
        weight:
          this.props.parentComponent == "headerlinks"
            ? ""
            : this.props.vitalSignsData.patientId === this.props.patientData._id
              ? this.props.vitalSignsData
                ? this.props.vitalSignsData.weight
                : this.props.patientData
                  ? this.props.patientData.weight
                  : ""
              : this.props.patientData
                ? this.props.patientData.weight
                : "",
        city: this.props.patientData ? this.props.patientData.city : "",
        remark: this.props.patientData ? this.props.patientData.remark : "",
        dobd:
          this.props.patientData && this.props.patientData.dob
            ? new Date(this.props.patientData.dob)
            : "",
        age_color: "",
        econsultation:
          this.props.patientData && this.props.patientData.econsultation
            ? true
            : false,
        appointmentId:
          this.props.patientData && this.props.patientData.appointmentId
            ? this.props.patientData.appointmentId
            : "",
      },
      showProcessing: false,
      search_date: this.props.search_date,
      patientClinicIdError: "",
      phoneError: "",
      datepickeropen: false,
      isOpdConsultation: false,
      isOnlineConsultation: false,
      lengthWeight: false,
      lengthHeight: false
    };

    validation = new Validator(this.state, rules, mess);
    validation.passes();
    validation.fails();

    this.handleDob = this.handleDob.bind(this);

    if (this.props.patientData) {
      //this.handleDob(this.props.patientData.dob, 1);
    }

    this.getPatientList = this.getPatientList.bind(this);
    this.dateChanged = this.dateChanged.bind(this);
    this.datepickeropenClickFun = this.datepickeropenClickFun.bind(this);
    isOnline = false;
  }

  componentDidMount() {
    let tempFormData = JSON.stringify(this.state.formData)
    let fields = JSON.parse(tempFormData)
    if (fields.dob) {
      fields.age = PF.getAgeByDob(fields.dob)
    } else {
      fields.is_dob = false;
      fields.age_color = "red";
      let dob = moment().subtract("years", fields.age);

      fields.dob = moment(dob._d).format("YYYY-MM-DD");
      fields.dobd = dob._d;
      //field['dobd'] = "";
      let dobDisable = false;
      if (fields.age > 0) {
        dobDisable = true;
      }

      this.setState({
        formData: fields,
        dobDisable: dobDisable,
        ageDisable: false,
      });
    }

    this.setState({ _notificationSystem: this.refs.notificationSystem });
    this.props.patientData && this.props.patientData.econsultation
      ? this.setState({ isOnlineConsultation: true, isOpdConsultation: false })
      : this.setState({ isOpdConsultation: true, isOnlineConsultation: false });

    let tempRules = rules;
    tempRules.phone =
      localStorage.getItem("is_mobile_no_mandatory") === "yes"
        ? "required|digits_between:10,12"
        : "digits_between:10,12";
    rules = tempRules;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isPatientDetail !== this.props.isPatientDetail) {
      if (nextProps.PatientDetail && nextProps.PatientDetail.data) {
        let formData = this.state.formData;
        formData["patientId"] = nextProps.PatientDetail.data._id;
        formData["patientClinicId"] =
          nextProps.PatientDetail.data.patientClinicId;
        // formData['patientEditId'] = nextProps.PatientDetail.data.pId;
        formData["title"] = nextProps.PatientDetail.data.title;
        formData["first_name"] = nextProps.PatientDetail.data.firstName;
        formData["last_name"] = nextProps.PatientDetail.data.lastName;
        formData["gender"] = nextProps.PatientDetail.data.gender;
        formData["phone"] = nextProps.PatientDetail.data.phone;
        formData["dob"] = moment(nextProps.PatientDetail.data.dob).format(
          "YYYY-MM-DD"
        );
        formData["email"] = nextProps.PatientDetail.data.email;
        formData["height"] = nextProps.PatientDetail.data.height;
        formData["weight"] = nextProps.PatientDetail.data.weight;
        formData["city"] = nextProps.PatientDetail.data.city;
        formData["remark"] = nextProps.PatientDetail.data.remark;
        //formData['dobd'] = moment(nextProps.PatientDetail.data.dob).format('MM/DD/YYYY');
        formData["dobd"] = new Date(nextProps.PatientDetail.data.dob);
        formData["age"] = nextProps.PatientDetail.data.age;
        formData["econsultation"] = nextProps.PatientDetail.data.econsultation;
        this.setState({ formData: formData });
        this.setState({ patientClinicIdError: "" });
      }
    }

    if (nextProps.isPatientDetailError !== this.props.isPatientDetailError) {
      this.setState({ patientClinicIdError: "Patient not found for this id." });
      this.setState({
        formData: {
          patientId: "",
          patientClinicId: "",
          patientEditId: "",
          title: "",
          first_name: "",
          last_name: "",
          gender: "",
          dob: "",
          age: "",
          email: "",
          height: "",
          weight: "",
          city: "",
          remark: "",
          dobd: "",
          phone: "",
          econsultation: false,
        },
      });
    } else {
      this.setState({ patientClinicIdError: "" });
    }

    if (nextProps.isPatientEdit !== this.props.isPatientEdit && editApi) {
      //this.successAlert('Patient Successfully Updated');

      if (this.props.patientUpdate != undefined) {
        let patientUpdateData = this.state.formData;
        if (this.props.appointmentData != undefined) {
          patientUpdateData.appointmentId =
            this.props.appointmentData.appointmentId;
        }

        this.props.patientUpdate(JSON.parse(JSON.stringify(patientUpdateData)));
      }

      // if(this.props && this.props.location && this.props.location.state && this.props.location.state.pagination){
      //     this.getPatientList(this.props.location.state.pagination)
      // }

      editApi = false;
      this.setState({ showProcessing: false });
      // setTimeout(function () {
      //   let params = {
      //     clinicId: localStorage.getItem("clinicId"),
      //   };
      //   appConstants.socket.emit("addPatient", params);
      // }, 1000);

      let formData = JSON.parse(JSON.stringify(this.state.formData));

      //this.getPatientList()
      // console.log(this.props.parent,this.state.patientInfo,"jjjjjjj")
      if (this.props.parent === "history") {
        let taskData = this.state.patientInfo;
        taskData.title = formData.title;
        taskData.firstName = formData.first_name
          ? formData.first_name
          : taskData.firstName;
        taskData.lastName = formData.last_name ? formData.last_name : "";
        taskData.gender = formData.gender;
        taskData.dob = formData.dob ? formData.dob : taskData.dob;
        taskData.age = formData.age ? formData.age : taskData.age;
        taskData.height = formData.height ? formData.height : 0.0;
        taskData.weight = formData.weight ? formData.weight : 0.0;
        taskData.city = formData.city ? formData.city : "";
        taskData.remark = formData.remark ? formData.remark : taskData.remark;
        taskData.phone = formData.phone ? formData.phone : "";

        taskData.econsultation = this.state.isOnlineConsultation ? true : false;
        // console.log(isOnline,this.state.isOnlineConsultation, this.props.parent,formData,taskData,"111111")
        let bmi = 0.0;
        let c1 = 0.0;
        let c2 = 0.0;
        let c3 = 0.0;
        let calorie = 0;
        taskData.calorieHieghtWeightFilledOnEdit = true; //this Variable Is Maintained For Filling Calorie Value In Drop Down If Height,Weight Not Filled At The Time Of Patinet Registeraation.
        if (formData.height != "" && formData.weight != "") {
          let h = formData.height ? formData.height : taskData.height;
          let w = formData.weight ? formData.weight : taskData.weight;
          let g = formData.gender;
          let height_in_m = h / 100;
          if (height_in_m > 0 && w > 0) {
            bmi = w / (height_in_m * height_in_m);
            bmi = bmi.toFixed(2);
            c1 = PF.getMaintainWeight(h, w, g);
            c2 = PF.getLooseWeight(h, w, g);
            c3 = PF.getGainWeight(h, w, g);
            calorie = PF.getCalorie(h, w, g);
            taskData.segCalorie = calorie;
          }
        } else {
          taskData.segCalorie = 0;
        }
        taskData.c1 = c1;
        taskData.c2 = c2;
        taskData.c3 = c3;
        taskData.bmi = bmi;
        this.props.taskScreenAction(taskData);
        let vitalSignData = this.props.vitalSignsData;
        vitalSignData.patientId = formData.patientId;
        vitalSignData.patientName =
          formData.first_name + " " + formData.last_name;
        vitalSignData.age = formData.age;
        vitalSignData.height = formData.height ? formData.height : 0.0;
        vitalSignData.weight = formData.weight ? formData.weight : 0.0;
        vitalSignData.gender = formData.gender;
        vitalSignData.bmi = bmi;
        vitalSignData.temperature =
          this.props.vitalSignsData?.temperature != undefined
            ? this.props.vitalSignsData.temperature
            : "";
        vitalSignData.bp =
          this.props.vitalSignsData?.bp != undefined
            ? this.props.vitalSignsData.bp
            : "";
        vitalSignData.pulse =
          this.props.vitalSignsData?.pulse != undefined
            ? this.props.vitalSignsData.pulse
            : "";
        vitalSignData.diabetesDuration =
          this.props.vitalSignsData?.diabetesDuration != undefined
            ? this.props.vitalSignsData.diabetesDuration
            : "";
        vitalSignData.toggleDuration =
          this.props.vitalSignsData?.toggleDuration != undefined
            ? this.props.vitalSignsData.toggleDuration
            : false;
        this.props.emrSetVitalSignsAction(vitalSignData);
        this.props.taskScreenHeightWeightRequireForCaloryAction(false);
        if (this.props.getCalorieList !== undefined)
          this.props.getCalorieList();
        //this.onDismiss()
      }
      let formArr = [];
      formData["patientId"] = "";
      formData["patientEditId"] = "";
      formData["patientClinicId"] = "";
      formData["title"] = "";
      formData["first_name"] = "";
      formData["last_name"] = "";
      formData["gender"] = "";
      formData["dob"] = "";
      formData["age"] = "";
      formData["email"] = "";
      formData["height"] = "";
      formData["weight"] = "";
      formData["city"] = "";
      formData["remark"] = "";
      formData["dobd"] = "";
      formData["phone"] = "";
      formData["econsultation"] = false;
      this.setState({ formData: formData, patientClinicIdError: "", formArr });
      if (nextProps?.PatientEditMessage?.isRedirect) {
        if (nextProps?.PatientEditMessage?.isRedirect)
          this.props.history.push(`/dashboard`);
      }

      this.props.onDismiss();

      // let url = "/"
      // if (this.props.history?.location?.pathname === "/")
      //     url = "/dashboard"
      // this.props.history.push({ pathname: url, econsultation: isOnline });
      //  let taskData=this.state.patientInfo
      //  taskData.treatmentId=this.state.formData.treatmentId;
      //  taskData.selectCalorie=this.state.formData.selectCalorie;

      // this.props.taskScreenAction(taskData);

      //this.props.history.push(`/dashboard`);
    }

    if (
      nextProps.isPatientRegistration !== this.props.isPatientRegistration &&
      editApi
    ) {
      editApi = false;
      this.setState({ showProcessing: false });
      setTimeout(function () {
        let params = {
          clinicId: localStorage.getItem("clinicId"),
        };
        appConstants.socket.emit("addPatient", params);
      }, 1000);

      let formData = this.state.formData;
      let formArr = [];
      formData["patientId"] = "";
      formData["patientEditId"] = "";
      formData["patientClinicId"] = "";
      formData["title"] = "";
      formData["first_name"] = "";
      formData["last_name"] = "";
      formData["gender"] = "";
      formData["dob"] = "";
      formData["age"] = "";
      formData["email"] = "";
      formData["height"] = "";
      formData["weight"] = "";
      formData["city"] = "";
      formData["remark"] = "";
      formData["dobd"] = "";
      formData["phone"] = "";
      formData["econsultation"] = false;
      this.setState({ formData: formData, patientClinicIdError: "", formArr });
      this.props.onDismiss();
      let url = "/";
      if (this.props.history?.location?.pathname === "/") url = "/dashboard";
      this.props.history.push({ pathname: url, econsultation: isOnline });
    }
  }

  dateChanged(d) {
    this.setState({ date1: d });
  }

  getPatientList() {
    let data = {};
    data["patient_id"] = "";
    data["city"] = "";
    data["first_name"] = "";
    data["last_name"] = "";
    data["direction"] = "desc";
    data["order"] = "createdAt";
    data["offset"] = 0;
    data["limit"] = 10;
    this.props.patientListAction(data);
  }

  successAlert(msg, link) {
    // this.setState({
    //     alert: (
    //         <SweetAlert
    //             success
    //             style={{ display: "block", marginTop: "-100px" }}
    //             title="Success"
    //             onConfirm={() => this.hideAlert()}
    //             //onCancel={() => this.hideAlert()}
    //             confirmBtnBsStyle="info"
    //         >
    //             {msg}
    //         </SweetAlert>
    //     )
    // });

    this.state._notificationSystem.addNotification({
      title: (
        <span
          data-notify="icon"
          className="pe-7s-check"
          style={{
            fontSize: "30px",
            color: "#fff",
            padding: "0px !important",
            top: "0% !important",
          }}
        ></span>
      ),
      message: (
        <div>
          {/* <p>{from}</p> */}
          <span>{msg}</span>
        </div>
      ),
      level: "success",
      position: "tr",
      autoDismiss: 2,
    });

    let _this = this;
    setTimeout(function () {
      _this.hideAlert();
    }, 3000);
  }

  hideAlert() {
    this.props.onDismiss();
    this.setState({
      alert: false,
    });
  }

  handleChange(e) {
    let { formData } = this.state;
    formData[e.target.name] =
      e.target.type === "radio" ? !formData[e.target.name] : e.target.value;

    if (e.target.name === "age") {
      // var today = new Date();
      formData["dob"] = moment().subtract(e.target.value, "years"); //(today.getFullYear()-e.target.value) + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    }

    if (e.target.name === "phone") {
      if (e.target.value && e.target.value.length != 10) {
        //this.setState({ phoneError: (<small className="text-danger">Phone number has to be 10 digits.</small>) })
      } else {
        //this.setState({ phoneError: null })
      }
    }

    if (
      e.target.name === "first_name" ||
      e.target.name === "last_name" ||
      e.target.name === "city"
    ) {
      formData[e.target.name] = e.target.value.toUpperCase();
    }

    if (e.target.name === "econsultation") {
      if (e.target.value === "true") isOnline = true;
      if (e.target.value === "false") isOnline = false;
      formData[e.target.name] = isOnline ? true : false;
    }

    if (e.target.name === "weight") {
      if (e.target.value.length < 7) {
        this.setState({ e, weightError: (<small className="text-danger">Maximum 5 Character Allowed</small>) })
      } else {
        this.setState({ weightError: null })
      }
    }

    if (e.target.name === "height") {
      if (e.target.value.length < 4) {
        this.setState({ heightError: (<small className="text-danger">Maximum 3 Character Allowed</small>) })
      } else {
        this.setState({ heightError: null })
      }
    }
    this.setState({ ...formData });
  }

  onPatientId(e) {
    e.preventDefault();
    let formData = this.state.formData;

    this.props.getPatientDetailAction({
      id: formData.patientClinicId,
      clinic_id: localStorage.getItem("clinicId"),
    });
  }

  onClear(e) {
    e.preventDefault();
    // let formData = this.state.formData;
    //
    // formData['patientClinicId'] = '';
    // this.setState({formData:formData});

    this.setState({
      formData: {
        patientId: "",
        patientClinicId: "",
        patientEditId: "",
        title: "",
        first_name: "",
        last_name: "",
        gender: "",
        dob: "",
        age: "",
        email: "",
        height: "",
        weight: "",
        city: "",
        remark: "",
        dobd: "",
        phone: "",
        econsultation: false,
      },
    });
  }

  handleDob(date, type = 2) {
    var selectedDate = moment(date);
    //var today = moment();
    let day = moment().diff(selectedDate, "day");

    let field = this.state.formData;
    field["age_color"] = "green";
    if (day <= 0) {
      this.setState({ formData: field });
      this.setState({ dobError: "Invalid Date." });
    } else {
      field["dob"] = moment(date).format("YYYY-MM-DD");
      field["dobd"] = new Date(date);

      let a = moment(date);
      let b = moment();

      let year = b.diff(a, "years");

      let month = b.diff(a, "month") % 12;

      var dateDiff = year;

      if (year == 0) {
        //dateDiff = year+'.'+month;
      }

      //let dateDiff = new Date().getFullYear() - new Date(date).getFullYear();

      field["age"] = dateDiff;

      if (type != 1) {
        field["is_dob"] = true;
        let ageDisable = true;
        this.setState({
          formData: field,
          dobError: "",
          ageDisable: ageDisable,
          dobDisable: false,
        });
      } else {
        this.setState({ formData: field, dobError: "" });
      }
    }
  }

  handleAge(e) {
    e.preventDefault();
    let field = this.state.formData;
    field["age"] = e.target.value;
    field["is_dob"] = false;
    field["age_color"] = "red";
    let dob = moment().subtract("years", e.target.value);

    field["dob"] = moment(dob._d).format("YYYY-MM-DD");
    field["dobd"] = dob._d;
    //field['dobd'] = "";
    let dobDisable = false;
    if (e.target.value.length > 0) {
      dobDisable = true;
    }

    this.setState({
      formData: field,
      dobDisable: dobDisable,
      ageDisable: false,
    });
  }

  allValidate(check) {
    if (!check) {
      formArr = [];
      Object.keys(rules).forEach(function (key) {
        formArr[key] = "TT";
      });
      this.setState({
        formArr,
      });
    }
    if (validation.passes()) {
      if (
        this.state.formData["dob"] === "" &&
        this.state.formData["age"] === ""
      ) {
        this.setState({ dobError: "The dob or age is required" });
      } else {
        return 1;
      }
    }
  }

  patientRegistration(evt) {
    evt.preventDefault();
    let formData = this.state.formData;
    formData["patient_id"] = formData.patientId;
    // formData['patientId'] = formData.patientId;

    if (this.allValidate(false)) {
      this.setState({ showProcessing: true });
      if (
        this.state.formData.patientId &&
        this.state.formData.patientId !== ""
      ) {
        this.props.patientEditAction(this.state);
      } else {
        this.props.patientRegistrationAction(this.state);
      }
      editApi = true;
    }
    //return validation.errors()
  }

  validDate(current) {
    var yesterday = moment().toDate();
    return current.isBefore(yesterday);
  }

  datepickeropenClickFun() {
    this.setState({ datepickeropen: true });
  }

  render() {
    validation = new Validator(this.state.formData, rules, mess);
    validation.passes();
    validation.fails();
    let title = this.state.formData.title;

    return (
      <Row>
        <NotificationSystem ref="notificationSystem" style={style} />
        {/* <div className="actionProcess" style={{ display: this.state.showProcessing ? 'block' : 'none' }}>
                    <img src={loadingImg} alt="Loading" width="40px" />
                    <center>Logging In - Please Wait</center>
                </div> */}
        <Col md={12}>
          {this.state.alert}
          <Form horizontal>
            {/*<Col sm={12} className="patienSearchBox">
                       <FormGroup>
                          <Col sm={2}>
                          </Col>
                          <Col sm={6}>
                          <FormControl type="text" placeholder="Patient Id" name="patientClinicId" id="patientClinicId" value={this.state.formData.patientClinicId} onChange={e => { this.handleChange(e); }} autoComplete="off"/>
                              <span className="errorMsg">
                              {this.state.patientClinicIdError}
                              {this.state.formArr.patientClinicId && validation.errors.first('patientClinicId')}</span>
                          </Col>
                          <Col sm={4} className="regist-b">
                              <button type="button" onClick={(e)=>{this.onPatientId(e)}} className="btn-fill btn-wd btn btn-info mTpv">Search</button>
                              <button type="button" onClick={(e)=>{this.onClear(e)}} className="btn-fill btn-wd btn btn-info mTpv">Clear</button>
                          </Col>

                      </FormGroup>
                  </Col>*/}

            <Col sm={12} md={6}>
              <FormGroup>
                <Col componentClass={ControlLabel} sm={12} md={4}>
                  Title <span className="star">*</span>
                </Col>
                <Col sm={12} md={8}>
                  <select
                    className="form-control"
                    name="title"
                    onChange={(event) => this.handleChange(event)}
                    value={title}
                  >
                    <option value="">Select title</option>
                    {titleOptions.map(function (item) {
                      return (
                        <option key={item.value} value={item.label}>
                          {item.label}
                        </option>
                      );
                    })}
                  </select>
                  <span className="errorMsg">
                    {this.state.titleError}
                    {this.state.formArr.title &&
                      validation.errors.first("title")}
                  </span>
                </Col>
              </FormGroup>
              <FormGroup>
                <Col componentClass={ControlLabel} sm={12} md={4}>
                  First Name <span className="star">*</span>
                </Col>
                <Col sm={12} md={8}>
                  <FormControl
                    type="text"
                    name="first_name"
                    id="first_name"
                    value={this.state.formData.first_name}
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                    autoComplete="off"
                  />
                  <small>
                    Note:-Do not write any title in the first name, please
                    select it from the title drop down.
                  </small>
                  <div className="errorMsg">
                    {this.state.first_nameError}
                    {this.state.formArr.first_name &&
                      validation.errors.first("first_name")}
                  </div>
                </Col>
              </FormGroup>

              <FormGroup>
                <Col
                  componentClass={ControlLabel}
                  sm={12}
                  md={4}
                  className="um-mandentory"
                >
                  Last Name
                </Col>
                <Col sm={12} md={8}>
                  <FormControl
                    type="text"
                    name="last_name"
                    id="last_name"
                    className="unmandt-f"
                    value={this.state.formData.last_name}
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                    autoComplete="off"
                  />
                  <span className="errorMsg">
                    {this.state.last_nameError}
                    {this.state.formArr.last_name &&
                      validation.errors.first("last_name")}
                  </span>
                </Col>
              </FormGroup>

              <FormGroup>
                <Col componentClass={ControlLabel} sm={12} md={4}>
                  Gender <span className="star">*</span>
                </Col>
                <Col sm={12} md={8}>
                  <select
                    className="form-control"
                    name="gender"
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                    value={this.state.formData.gender}
                  >
                    <option value="">-- Select Gender --</option>
                    <option value="Female">Female</option>
                    <option value="Male">Male</option>
                    {/* <option value="Other" selected={this.state.formData.gender === 'Other' ? true : false}>Other</option> */}
                  </select>
                  <span className="errorMsg">
                    {this.state.genderError}
                    {this.state.formArr.gender &&
                      validation.errors.first("gender")}
                  </span>
                </Col>
              </FormGroup>

              <FormGroup>
                <Col componentClass={ControlLabel} sm={12} md={4}>
                  DOB/Age <span className="star">*</span>
                </Col>
                <Col sm={12} md={4} className="cus_datepicker">
                  <DatePicker
                    selected={
                      this.state.formData.dobd ? this.state.formData.dobd : ""
                    }
                    placeholderText="dd/mm/yyyy"
                    className="form-control"
                    name="dob"
                    maxDate={new Date()}
                    onChange={this.handleDob}
                    //disabled = {this.state.dobDisable}
                    dateFormat="dd/MM/yyyy"
                    value={
                      this.state.formData.dobd &&
                        this.state.formData.is_dob === true
                        ? this.state.formData.dobd
                        : ""
                    }
                    isValidDate={this.validDate}
                    closeOnSelect={false}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    autoComplete="off"
                    customInput={
                      <MaskedInput mask="11/11/1111" placeholder="dd/mm/yyyy" />
                    }
                  />

                  <span className="errorMsg">
                    {this.state.dobError}
                    {this.state.formArr.dob && validation.errors.first("dob")}
                  </span>
                </Col>
                <Col sm={12} md={4}>
                  <FormControl
                    type="text"
                    name="age"
                    id="age"
                    disabled={this.state.ageDisable}
                    value={
                      this.state.formData.age >= 0 ? this.state.formData.age : ""
                    }
                    onChange={(e) => {
                      this.handleAge(e);
                    }}
                    autoComplete="off"
                    maxLength="3"
                  />
                  <span className="errorMsg">
                    {this.state.ageError}
                    {this.state.formArr.age && validation.errors.first("age")}
                  </span>
                </Col>
              </FormGroup>

              <FormGroup>
                <Col
                  componentClass={ControlLabel}
                  sm={12}
                  md={4}
                  className="um-mandentory"
                >
                  Phone{" "}
                  {localStorage.getItem("is_mobile_no_mandatory") === "yes" ? (
                    <span className="star">*</span>
                  ) : (
                    ""
                  )}
                </Col>
                <Col sm={12} md={8}>
                  <FormControl
                    type="text"
                    autoComplete="off"
                    name="phone"
                    id="phone"
                    className="unmandt-f"
                    maxLength={12}
                    value={this.state.formData.phone}
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                  />
                  <span className="errorMsg">
                    {this.state.phoneError}
                    {this.state.formArr.phone &&
                      validation.errors.first("phone")}
                  </span>
                </Col>
              </FormGroup>
            </Col>
            <Col sm={12} md={6}>
              {localStorage.getItem("isEmailFacility") === "yes" ? (
                <FormGroup>
                  <Col
                    componentClass={ControlLabel}
                    sm={12}
                    md={4}
                    className="um-mandentory"
                  >
                    Email
                  </Col>
                  <Col sm={12} md={8}>
                    <FormControl
                      type="email"
                      name="email"
                      id="email"
                      className="unmandt-f"
                      value={this.state.formData.email}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      autoComplete="off"
                    />
                    <span className="errorMsg">
                      {this.state.emailError}
                      {this.state.formArr.email &&
                        validation.errors.first("email")}
                    </span>
                  </Col>
                </FormGroup>
              ) : (
                ""
              )}

              <FormGroup>
                <Col componentClass={ControlLabel} sm={12} md={4}>
                  Height(CM)<span style={{ color: "blue" }}>*</span>
                  <br />
                  <small>optional</small>
                </Col>
                <Col sm={12} md={8}>
                  <FormControl
                    type="text"
                    name="height"
                    id="height"
                    maxLength="4"
                    value={
                      this.state.formData.height == 0
                        ? ""
                        : this.state.formData.height
                    }
                    onChange={(e) => {
                      if (e.target.value.length < 4) {
                        this.handleChange(e, "height");
                        this.setState({ ...this.state, lengthHeight: false });
                      } else this.setState({ ...this.state, lengthHeight: true });
                    }}
                    autoComplete="off"
                  />
                  {this?.state?.lengthHeight && (
                    <div style={{ color: "red" }}>
                      <p style={{ fontSize: "13px" }}>
                        Maximum 3 Character Allowed
                      </p>
                    </div>
                  )}
                  <span className="errorMsg">
                    {this.state.heightError}
                    {this.state.formArr.height &&
                      validation.errors.first("height")}
                  </span>
                </Col>
              </FormGroup>

              <FormGroup>
                <Col componentClass={ControlLabel} sm={12} md={4}>
                  Weight(KG)<span style={{ color: "blue" }}>*</span>
                  <br />
                  <small>optional</small>
                </Col>
                <Col sm={12} md={8}>
                  <FormControl
                    type="text"
                    name="weight"
                    id="weight"
                    maxLength="7"
                    value={
                      this.state.formData.weight == 0
                        ? ""
                        : this.state.formData.weight
                    }
                    onChange={(e) => {
                      if (e.target.value.length < 7) {
                        this.handleChange(e, "weight");
                        this.setState({ ...this.state, lengthWeight: false });
                      } else this.setState({ ...this.state, lengthWeight: true });
                    }}
                    autoComplete="off"
                  />
                  {this?.state?.lengthWeight && (
                    <div style={{ color: "red" }}>
                      <p style={{ fontSize: "13px" }}>
                        Maximum 5 Character Allowed
                      </p>
                    </div>
                  )}
                  <span className="errorMsg">
                    {this.state.weightError}
                    {this.state.formArr.weight &&
                      validation.errors.first("weight")}
                  </span>
                </Col>
              </FormGroup>

              <FormGroup>
                <Col
                  componentClass={ControlLabel}
                  sm={12}
                  md={4}
                  className="um-mandentory"
                >
                  City
                </Col>
                <Col sm={12} md={8}>
                  <FormControl
                    type="text"
                    name="city"
                    id="city"
                    className="unmandt-f"
                    value={this.state.formData.city}
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                    autoComplete="off"
                  />
                  <span className="errorMsg">
                    {this.state.cityError}
                    {this.state.formArr.city && validation.errors.first("city")}
                  </span>
                </Col>
              </FormGroup>

              <FormGroup>
                <Col
                  componentClass={ControlLabel}
                  sm={12}
                  md={4}
                  className="um-mandentory"
                >
                  Remark
                </Col>
                <Col sm={12} md={8}>
                  <FormControl
                    rows="4"
                    componentClass="textarea"
                    name="remark"
                    bsClass="form-control unmandt-f"
                    value={this.state.formData.remark}
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                    autoComplete="off"
                  />
                  <span className="errorMsg">
                    {this.state.remarkError}
                    {this.state.formArr.remark &&
                      validation.errors.first("remark")}
                  </span>
                </Col>
              </FormGroup>
              <FormGroup>
                <Col sm={12} md={4}></Col>
                <Col sm={12} md={8} className="e-consultation">
                  <Col componentClass={ControlLabel} className="um-mandentory">
                    <form className="consultation-radio">
                      <input
                        name="econsultation"
                        type="radio"
                        id="OPD-Consultation"
                        value={false}
                        onClick={(e) => {
                          this.setState({
                            isOpdConsultation: true,
                            isOnlineConsultation: false,
                          });
                          this.handleChange(e);
                        }}
                        checked={
                          this.state.isOpdConsultation === true ? "true" : ""
                        }
                      />
                      <label for="OPD-Consultation">OPD Consultation</label>
                      <br />
                      <input
                        name="econsultation"
                        type="radio"
                        id="online-consultation"
                        value={true}
                        onClick={(e) => {
                          this.setState({
                            isOnlineConsultation: true,
                            isOpdConsultation: false,
                          });
                          this.handleChange(e);
                        }}
                        checked={
                          this.state.isOnlineConsultation === true ? "true" : ""
                        }
                      />
                      <label for="online-consultation">
                        Online Consultation
                      </label>
                    </form>
                  </Col>
                </Col>
                <span className="errorMsg">
                  {this.state.econsultationError}
                  {this.state.formArr.econsultation &&
                    validation.errors.first("econsultation")}
                </span>
              </FormGroup>
            </Col>

            <Col mdOffset={2} sm={12} md={6}>
              <button
                type="button"
                onClick={this.patientRegistration.bind(this)}
                className="btn-fill btn-wd btn btn-primary"
                disabled={this.state.showProcessing}
              >
                Save
              </button>
            </Col>
          </Form>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    PatientRegistrationMessage: state.home.PatientRegistration,
    isPatientRegistration: state.home.isPatientRegistration,
    isPatientRegistrationError: state.home.isPatientRegistrationError,

    PatientEditMessage: state.home.PatientEdit,
    isPatientEdit: state.home.isPatientEdit,
    isPatientEditError: state.home.isPatientEditError,

    PatientDetail: state.home.PatientDetail,
    isPatientDetail: state.home.isPatientDetail,
    isPatientDetailError: state.home.isPatientDetailError,

    VisitList: state.home.VisitList,
    isVisitList: state.home.isVisitList,
    isVisitListError: state.home.isVisitListError,

    addVisit: state.patient.addVisit,
    isAddVisit: state.patient.isAddVisit,
    isAddVisitError: state.patient.isAddVisitError,

    taskScreen: state.taskScreen,
    isHeightWeightValidation: state.isHeightWeightValidation,
    vitalSignsData: state.emr.vitalSignsData,
  };
}
export default withRouter(
  connect(mapStateToProps, {
    patientRegistrationAction,
    patientEditAction,
    patientListAction,
    getPatientDetailAction,
    visitListAction,
    addVisitAction,
    taskScreenAction,
    emrSetVitalSignsAction,
    taskScreenHeightWeightRequireForCaloryAction,
  })(PatientRegistration)
);
