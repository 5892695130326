/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Row, Col, OverlayTrigger, Tooltip, Nav, NavDropdown, MenuItem, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { clinicchangestatusAction, clinicDeletedAction, sendLoginDetailAction } from 'Admin/actions/clinic';
import { confirmAlert } from 'react-confirm-alert';
import SweetAlert from 'react-bootstrap-sweetalert';
import { LinkContainer } from 'react-router-bootstrap';
import $ from 'jquery';
class ActionLinks extends Component {

  constructor(props) {
    super(props);
    //console.log(props);
    this.state = {
      formData: {
        patient_id: "",
        height: "",
        weight: "",
        remark: "",
      },
      clinicDelete: true,
      SendLoginDetail: true,
      moreMenuOpen: false
    };
    this.onDismiss = this.onDismiss.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.handleMenu = this.handleMenu.bind(this);
  }

  componentDidMount() {
    this.handleMenu();
  }

  handleMenu() {
    var obj = this;
    $('body').click(function (e) {
      if (!$(e.target).hasClass('dropdown') && $(e.target).parents('.dropdown').length == 0) {
        obj.setState({ 'moreMenuOpen': false });
      }
    });

    $('body').delegate('.dropdown li', 'click', function () {
      obj.setState({ 'moreMenuOpen': false });
    });

  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.isClinicDeleted !== this.props.isClinicDeleted && this.props.isClinicDeleted === false && this.state.clinicDelete === false) {
      //console.log(nextProps);
      this.setState({ clinicDelete: true });
      this.successAlert('Clinic Successfully Deleted');
      this.props.clinicListRefresh();
    }

    if (nextProps.isSendLoginDetail !== this.props.isSendLoginDetail && this.props.isSendLoginDetail === false && this.state.SendLoginDetail === false) {
      this.setState({ SendLoginDetail: true });
      this.successAlert('Successfully login detail sent.');
    }

  }

  addVisit(patient) {
    let field = this.state.formData;
    field['patient_id'] = patient.id;
    field['height'] = patient.height ? patient.height : '';
    field['weight'] = patient.weight ? patient.weight : '';
    field['remark'] = '';
    this.setState({ formData: field });
    confirmAlert({
      title: 'Confirm to add visit',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.addVisitAction(this.state.formData)
        },
        {
          label: 'No',
          onClick: () => this.resetFormData()
        }
      ]
    })
  }

  resetFormData() {
    let field = this.state.formData;
    field['patient_id'] = '';
    field['height'] = '';
    field['weight'] = '';
    field['remark'] = '';
    this.setState({ formData: field });
  }

  onDismiss() {
    this.setState({ patientModal: false });
    this.successAlert('Patient Successfully Updated');
  }

  successAlert(msg) {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Success"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          {msg}
        </SweetAlert>
      )
    });
  }

  statusChangedHandler(event, elename) {
    let newstatus = {};
    if (event.status === 'active') {
      newstatus = 'inactive';
    } else {
      newstatus = 'active';
    }
    event['id'] = event._id;
    event['status'] = newstatus;
    this.props.clinicchangestatusAction(event);
  }

  clinicDeleteHandler(event, elename) {
    this.setState({ clinicDelete: false });
    confirmAlert({
      title: 'Confirm to Delete Clinic',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.clinicDeletedAction({ 'id': event._id })
        },
        {
          label: 'No',
          onClick: () => { }
        }
      ]
    })
  }


  sendLoginDetail(event, elename) {
    this.setState({ SendLoginDetail: false });
    this.props.sendLoginDetailAction({ 'id': event._id })
  }



  hideAlert() {
    this.setState({
      alert: false
    });
  }

  render() {

    return (<div>
      {this.state.alert}
      <div className="flxx d-flex">
        <OverlayTrigger placement="top" overlay={<Tooltip id="edit">Edit</Tooltip>}>
          <Link className="fl-left" to={{ pathname: `/admin/update-clinic/`, state: this.props.clinicDetails }} >
            <i className="fa fa-pencil" aria-hidden="true"></i></Link>
        </OverlayTrigger>
        <Nav className="fl-rights">
          <NavDropdown
            eventKey={4}
            title={(
              <div>
                More
                <b className="caret"></b>
              </div>
            )} noCaret id="basic-nav-dropdown-3" bsClass="dropdown-with-icons dropdown" className={this.state.moreMenuOpen ? "open" : ""} onClick={() => this.setState({ 'moreMenuOpen': !this.state.moreMenuOpen })}>
            <LinkContainer to={{ pathname: `update-clinic`, state: this.props.clinicDetails }}>
              <MenuItem eventKey={4.1}><i className="fa fa-info-circle"></i> Clinic Details</MenuItem>
            </LinkContainer>

            <LinkContainer to={{ pathname: `add-clinic-subscription/` + this.props.clinicId, state: this.props.clinicDetails }}>
              <MenuItem eventKey={4.2}><i className="fa fa-bell"></i> Subscription</MenuItem>
            </LinkContainer>

            {/*<LinkContainer to={{ pathname: `add-clinic-calendar`, clinicId: this.props.clinicId, state: this.props.clinicDetails }}>
                      <MenuItem eventKey={4.4} data-id={this.props.chatPatientId} ><i className="fa fa-calendar"></i> Set Calender</MenuItem>
                    </LinkContainer>*/}

            <LinkContainer to={{ pathname: `/admin/user-list/` + this.props.clinicId, clinicId: this.props.clinicId, state: this.props.clinicDetails }}>
              <MenuItem eventKey={4.5} data-id={this.props.chatPatientId}><i className="fa fa-user-circle"></i> Manage Users</MenuItem>
            </LinkContainer>

            <LinkContainer to={{ pathname: `/admin/clinic-request/` + this.props.clinicId + '/' + this.props.clinicDetails.name, clinicId: this.props.clinicId, state: this.props.clinicDetails }}>
              <MenuItem eventKey={4.10} data-id={this.props.chatPatientId}><i className="fa fa-user-circle"></i> Clinic Requests</MenuItem>
            </LinkContainer>

            <MenuItem eventKey={4.8} onClick={this.sendLoginDetail.bind(this, this.props.clinicDetails)}><i className="fa fa-user-circle"></i>Send Login Detail</MenuItem>

            {/*<LinkContainer to={{ pathname: `/admin/patient-request/`+this.props.patientclinicId, clinicId:this.props.clinicId, state: this.props.clinicDetails }}>
                      <MenuItem eventKey={4.5} data-id={this.props.chatPatientId}><i className="fa fa-user-circle"></i> Manage Patient</MenuItem>
                    </LinkContainer>*/}

            <MenuItem eventKey={4.7} data-id={this.props.chatPatientId} onClick={this.statusChangedHandler.bind(this, this.props.clinicDetails)}><i className={(this.props.clinicDetails.status === "active") ? ('fa fa-check') : ('fa fa-remove')} aria-hidden="true"></i>{(this.props.clinicDetails.status === "active") ? ('Active') : ('Inactive')}</MenuItem>

            <MenuItem eventKey={4.9} onClick={this.clinicDeleteHandler.bind(this, this.props.clinicDetails)}><i className="fa fa-remove" aria-hidden="true"></i>Delete</MenuItem>
          </NavDropdown>
        </Nav>
      </div>

      <Modal className="pa-patient-registration" show={this.state.patientModal} onHide={() => this.setState({ patientModal: false })} dialogClassName="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">Patient Registration</Modal.Title>
        </Modal.Header>
        <Modal.Body className="Knowledge-Share card">

        </Modal.Body>

      </Modal>
    </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ClinicDeleted: state.clinic.ClinicDeleted,
    isClinicDeleted: state.clinic.isClinicDeleted,
    isClinicDeletedError: state.clinic.isClinicDeletedError,

    SendLoginDetail: state.clinic.SendLoginDetail,
    isSendLoginDetail: state.clinic.isSendLoginDetail,
    isSendLoginDetailError: state.clinic.isSendLoginDetailError,
  }
}

export default withRouter(connect(mapStateToProps, { clinicchangestatusAction, clinicDeletedAction, sendLoginDetailAction })(ActionLinks));
