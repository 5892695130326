/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import {
    Row, Col, Modal, Form, FormGroup, ControlLabel, FormControl, Table, Grid
} from 'react-bootstrap';
import Card from 'Front/components/Card/Card.jsx';
import Button from 'Admin/elements/CustomButton/CustomButton.jsx';
import * as API from 'Front/api/master';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loading from 'react-loading';
import moment from 'moment';
import InsulinDose from '../TaskScreen/InsulinDose';
import GlucoseDiaryChart from '../Home/GlucoseDiaryChart';
const InsulinPatient = () => {

    const [formData, setFormData] = useState({ page: 0, limit: 10, order: 'glucoseDate', direction: 'desc' });
    const [glucoseList, setGlucoseList] = useState([]);
    const [sortingorder, setSortingorder] = useState('');
    const [isClear, SetIsClear] = useState(false)
    const [totalCount, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const getGlucoseListAction = async (from = {}) => {
        setIsLoading(true);
        let resp = await API.getPatientGlucoselist(from.page ? from : formData);
        setIsLoading(false);
        //setGlucoseList(resp.data.data.data.filter((data) => data.lastTwo[0].glucoseTime === "Fasting"));//Filter Applied For Fasting Data
        setGlucoseList(resp.data.data.data);
        setTotal(resp.data.data.count);


    }

    useEffect(() => {
        getGlucoseListAction();

    }, []);

    useEffect(() => {
        if (isClear)
            getGlucoseListAction();
    }, [isClear])

    const handleChange = (e) => {
        let saveFormData = formData;
        saveFormData[e.target.name] = e.target.value;

        setFormData({ ...formData, ...saveFormData });
        getGlucoseListAction(saveFormData);

    }

    const handleClearBtn = (e) => {
        setFormData({ page: 0, limit: 10, order: 'glucoseDate', direction: 'desc', patient_id: '', first_name: '', last_name: '' });
        SetIsClear(true)
    }

    const getName = (cell, row, enumObject, rowIndex) => {
        row.pId = row._id;
        return <InsulinDose visitInfo={row} callType="name" />
        // return (<div className={!row.app ? "pt-blue-color" : ''}>{row.title+' '+row.firstName+' '+row.lastName}</div>)
    }
    const getGlucoseReport = (cell, row, enumObject, rowIndex) => {
        row.pId = row._id;
        // return <InsulinDose visitInfo={row} callType="glucose" />
        return <GlucoseDiaryChart patientData={row} callType="name" />
    }

    const getPatientId = (cell, row, enumObject, rowIndex) => {
        row.pId = row._id;
        return <InsulinDose visitInfo={row} callType="patientId" />
    }
    const actualDose = (cell, row, enumObject, rowIndex) => {
        let dose = row.lastTwo && row.lastTwo[0] && row.lastTwo[0].currentDose ? row.lastTwo[0].currentDose : 0;
        return (dose);
    }


    const srNo = (cell, row, enumObject, rowIndex) => {
        return (rowIndex + 1 + (formData.limit * formData.page));
    }

    const glucoseDate = (cell, row) => {

        return moment(row.glucoseDate).format("D MMM YYYY");

    }

    const suggestedDose = (cell, row) => {

        let dose = row.lastTwo && row.lastTwo[0] && row.lastTwo[0].suggestedDose ? row.lastTwo[0].suggestedDose : 0;

        return dose;

    }

    const Remark = (cell, row) => {

        let remrk = row.doseRemark ? row.doseRemark : (row.lastTwo && row.lastTwo[0] && row.lastTwo[0].remark ? row.lastTwo[0].remark : '');

        return remrk;

    }

    const lastDose = (cell, row, enumObject, rowIndex) => {
        if (row.lastTwo && row.lastTwo.length > 1) {
            let lastOne = row.lastTwo[0];
            let lastTwo = row.lastTwo[1];

            return (<div><b>{lastOne.glucoseValue}</b> - {moment(lastOne.glucoseDate).format('D MMM YYYY')}, <b>{lastTwo.glucoseValue}</b> - {moment(lastTwo.glucoseDate).format('D MMM YYYY')}</div>);

        } else {

            return (<div><b>{row.glucoseLast}</b> - {moment(row.glucoseDate).format('D MMM YYYY')}</div>);

        }

    }

    const _setTableOption = () => {
        if (isLoading) {
            return (
                <Loading type='bars' color='#000000' style={{ margin: '0px auto', width: "40px" }} />
            );
        } else {
            return "No data found!";
        }
    }

    const onPageChange = (page, sizePerPage) => {



        let data = formData;
        data['page'] = page - 1;
        data['limit'] = sizePerPage;

        setFormData({ ...formData, ...data });

        getGlucoseListAction(data);



        // this.setState({ sizePerPage: sizePerPage })
        // this.setState({ currentPage: page })
        // this.props.patientListAction(data);
    }


    const options = {
        //onSortChange: this.onSortChange,
        clearSearch: true,
        pageStartIndex: 1, // where to start counting the pages
        paginationSize: 3,  // the pagination bar size.
        prePage: 'Prev', // Previous page button text
        nextPage: 'Next', // Next page button text
        firstPage: 'First', // First page button text
        lastPage: 'Last', // Last page button text
        //paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
        paginationPosition: 'bottom',  // default is bottom, top and both is all available
        sizePerPage: formData.limit,
        onPageChange: onPageChange,
        page: formData.page + 1,
        noDataText: _setTableOption(),
    };


    return (<div className="main-content patient-search" style={{ padding: '15px 0px' }}>
        <Grid fluid>
            <Row>
                <Col md={12}>
                    <Card
                        content={
                            <div className="fresh-datatables table-responsive-new-withoutScroll">
                                <h4 style={{}}><strong>Basal Insulin Dose Guide</strong></h4>
                                <Row className="search-section">
                                    <Col sm={6} md={2} className="form-group">
                                        <FormControl type="text" autocomplete="off" name="patient_id" id="patient_id" value={formData.patient_id} onChange={e => { handleChange(e); }} placeholder="Patient Id" />

                                    </Col>
                                    <Col sm={6} md={2} className="form-group">
                                        <FormControl type="text" autocomplete="off" name="first_name" id="first_name" value={formData.first_name} onChange={e => { handleChange(e); }} placeholder="First Name" />

                                    </Col>
                                    <Col sm={6} md={2} className="form-group">
                                        <FormControl type="text" autocomplete="off" name="last_name" id="last_name" value={formData.last_name} onChange={e => { handleChange(e); }} placeholder="Last Name" />

                                    </Col>
                                    <Col sm={6} md={3} className="form-group">
                                        <select onChange={e => { handleChange(e); }} value={formData.direction} name="direction" className="form-control">
                                            <option value="desc">Latest Updated</option>
                                            <option value="today">Today Updated</option>
                                            <option value="same">Updated with Right Dose</option>
                                            <option value="different">Updated with Different Dose</option>
                                            <option value="not">Today Not Updated</option>
                                            <option value="asc">Long Time No Updated</option>
                                        </select>
                                        {/* <FormControl type="text" name="city" id="city" value={formData.city} onChange={e => { handleChange(e); }} placeholder="City" /> */}

                                    </Col>
                                    <Col sm={6} md={2} className="form-group btn-hold">
                                        <Button type="button" onClick={e => { handleClearBtn(e); }}>Clear</Button>

                                    </Col>

                                </Row>

                                <div className="fresh-datatables dashboard">

                                    <BootstrapTable
                                        deleteRow={false}
                                        key={glucoseList}
                                        data={glucoseList}
                                        search={false}
                                        pagination={true}
                                        options={options}
                                        striped hover condensed scrollTop={'Bottom'}
                                        remote={true}
                                        fetchInfo={{ dataTotalSize: totalCount }}
                                        sort={true}
                                    //ref={this.onBootstrapTableRef}
                                    >
                                        <TableHeaderColumn thStyle={{}} tdStyle={{}} hidden={true} tdAttr={{ 'data-attr': '_id' }} dataField='patientId' dataSort={true} isKey={true} searchable={false}>Id</TableHeaderColumn>

                                        <TableHeaderColumn tdAttr={{ 'data-attr': '#' }} thStyle={{ width: '3%' }} tdStyle={{ width: '3%', }} dataField='sn' dataFormat={srNo}>S.No.</TableHeaderColumn>

                                        <TableHeaderColumn thStyle={{ width: '7%' }} tdStyle={{ width: '7%' }} tdAttr={{ 'data-attr': 'Patient ID,' }} dataField='patientClinicId' dataFormat={getPatientId}>Patient ID</TableHeaderColumn>

                                        <TableHeaderColumn thStyle={{ width: '8%' }} tdStyle={{ width: '8%' }} tdAttr={{ 'data-attr': 'Name' }} dataField='firstName' dataFormat={getName}>Name</TableHeaderColumn>

                                        <TableHeaderColumn thStyle={{ width: '7%' }} tdStyle={{ width: '7%' }} tdAttr={{ 'data-attr': 'Glucose' }} dataField='GlucoseReport' dataFormat={getGlucoseReport}>Glucose Diary</TableHeaderColumn>

                                        <TableHeaderColumn thStyle={{ width: '7%' }} tdStyle={{ width: '7%' }} tdAttr={{ 'data-attr': 'Dose at initation' }} dataField='initialDose' >Dose at initation</TableHeaderColumn>

                                        <TableHeaderColumn thStyle={{ width: '10%' }} tdStyle={{ width: '10%' }} tdAttr={{ 'data-attr': 'Insulin Dose Taken' }} dataField='initialDose' dataFormat={suggestedDose}>Suggested insulin dose</TableHeaderColumn>

                                        <TableHeaderColumn thStyle={{ width: '10%' }} tdStyle={{ width: '10%' }} tdAttr={{ 'data-attr': 'Insulin Dose Taken' }} dataField='initialDose' dataFormat={actualDose}>Actual insulin dose</TableHeaderColumn>

                                        <TableHeaderColumn thStyle={{ width: '5%' }} tdStyle={{ width: '5%' }} tdAttr={{ 'data-attr': 'Initial Insulin Dose' }} dataField='remark' dataFormat={Remark}>Remark</TableHeaderColumn>

                                        <TableHeaderColumn thStyle={{ width: '5%' }} tdStyle={{ width: '5%' }} tdAttr={{ 'data-attr': 'Date' }} dataField='date' dataFormat={glucoseDate}>Date</TableHeaderColumn>

                                        <TableHeaderColumn thStyle={{ width: '20%' }} tdStyle={{ width: '20%' }} tdAttr={{ 'data-attr': 'Last 2 Glucose Value' }} dataField='glucoseLast' dataFormat={lastDose}>Glucose values used to<br />adjust insulin dose</TableHeaderColumn>

                                        {/* <TableHeaderColumn tdAttr={{ 'data-attr': 'Action' }} dataFormat="">Action</TableHeaderColumn> */}
                                    </BootstrapTable>
                                </div>
                            </div>
                        }
                    />
                </Col>
            </Row>
        </Grid></div>);
}
export default withRouter(InsulinPatient);
