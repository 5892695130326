/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React from "react";
import { useState, useEffect, useReducer } from "react";
import { withRouter, useParams, NavLink, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  FormControl,
  FormGroup,
  ControlLabel,
  InputGroup,
  Grid,
  Modal,
} from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Button from "Front/elements/CustomButton/CustomButton.jsx";
import Card from "Front/components/Card/Card.jsx";
import * as API from "Front/api/emr";
import $ from "jquery";
import { Autosave, useAutosave } from "react-autosave";
import Breadcrum from "../../../../components/common/Breadcrum";
import GeneralUsrImg from "../../../../assets/img/default-avatar.png";
import PendingIc from "../../../../assets/img/icons/pending-action.svg";
import EditIcon from "../../../../assets/img/icons/edit-ic.svg";
import DeleteIcon from "../../../../assets/img/icons/table-delete-ic.svg";
import InlargeIc from "../../../../assets/img/icons/inlarge-ic.svg";
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from "../../../../actions/actionTypes";
import { taskScreenAction } from "Front/actions/taskScreen";
import * as PF from "Front/views/Home/PublicFunction.jsx";

const VitalSign = () => {
  const emrData = useSelector((store) => store.emr);
  const taskData = useSelector((store) => store.taskScreen.visitData);
  const [formData, setFormData] = useState({});
  const [alertMsg, setAlertMsg] = useState(null);
  const [generalInformation, setGeneralInformation] = useState({
    patientName: emrData?.vitalSignsData
      ? emrData.vitalSignsData.patientName
      : "",
    age: emrData?.vitalSignsData ? emrData.vitalSignsData.age : "",
    height: emrData?.vitalSignsData ? emrData.vitalSignsData.height : "",
    weight: emrData?.vitalSignsData ? emrData.vitalSignsData.weight : "",
    bmi: emrData?.vitalSignsData ? emrData.vitalSignsData.bmi : "",
    bp: emrData?.vitalSignsData ? emrData.vitalSignsData.bp : "",
    gender: emrData?.vitalSignsData ? emrData.vitalSignsData.gender : "",
    pulse: emrData?.vitalSignsData ? emrData.vitalSignsData.pulse : "",
    temperature: emrData?.vitalSignsData
      ? emrData.vitalSignsData.temperature
      : "",
    diabetesDuration: emrData?.diabetesDuration
      ? emrData.vitalSignsData.diabetesDuration
      : "",
  });
  const [duration, setDuration] = useState(
    emrData
      ? emrData.vitalSignsData.diabetesDuration
        ? emrData.vitalSignsData.diabetesDuration
        : "Year Month Day"
      : "Year Month Day"
  );
  const [onPageLoad, setOnPageLoad] = useState(true);
  const [toggleDuration, setToggleDuration] = useState(
    emrData
      ? emrData.vitalSignsData.toggleDuration
        ? emrData.vitalSignsData.toggleDuration
        : false
      : false
  );
  const [errorHeight, setErrorHeight] = useState(false);
  const [LengthHeight, setLengthHeight] = useState(false);
  const [errorWeight, setErrorWeight] = useState(false);
  const [requiredHeight, setRequiredHeight] = useState(false);
  const [requiredWeight, setRequiredWeight] = useState(false);
  const [lengthWeight, setLengthWeight] = useState(false);
  const [errorBp, setErrorBp] = useState(false);
  const [errorBp1, setErrorBp1] = useState(false);
  const [errorPulse, setErrorPulse] = useState(false);
  const [errorTemprature, setErrorTemprature] = useState(false);
  const [isSideNav, setIsSideNav] = useState(false);
  const [autoSave, setAuoSave] = useState(true);
  const [isAutoSaveUse, setIsAuoSaveUse] = useState(false);
  const location = useLocation();
  let { appointmentId, patientId, printingLanguage } = useParams();
  const dispatch = useDispatch();
  let userType = localStorage.getItem("userType");
  let isPrescriptionEditByReceptionist = localStorage.getItem(
    "is_prescription_edit_by_receptionist"
  );
  let isPrescriptionEditByEducator = localStorage.getItem(
    "is_prescription_edit_by_educator"
  );
  let clinicNumber = localStorage.getItem("clinicNumber");

  const getPatientGeneralInfo = async () => {
    if (
      emrData.vitalSignsData &&
      Object.keys(emrData.vitalSignsData).length === 0
    ) {
      let resp = await API.generalInformation({ patientId: patientId });

      if (resp.data && resp.data.status === "Success") {
        setGeneralInformation({
          patientName: resp.data.data.name,
          age: resp.data.data.age != undefined ? resp.data.data.dob != undefined ? PF.getAgeByDob(resp.data.data.dob) : resp.data.data.age : "",
          height: resp.data.data.height,
          weight: resp.data.data.weight,
          bmi: resp.data.data.bmi,
          bp: resp.data.data.bp,
          gender: resp.data.data.gender,
          pulse: resp.data.data.pulse,
          temperature: resp.data.data.temprature,
        });

        resp.data.data.height === 0
          ? setRequiredHeight(true)
          : setRequiredHeight(false);
        resp.data.data.weight === 0
          ? setRequiredWeight(true)
          : setRequiredWeight(false);
        let strDuration = "";
        if (resp.data.data.diabitic === "yes") {
          if (
            resp.data.data.diabtiesDuration?.years === 0 &&
            resp.data.data.diabtiesDuration?.months === 0 &&
            resp.data.data.diabtiesDuration?.days === 0
          ) {
            strDuration = "Day Month Year";
            setToggleDuration(false);
          }
          setToggleDuration(true);
          if (resp.data.data.diabtiesDuration?.years > 0) {
            strDuration = resp.data.data.diabtiesDuration?.years + " years ";
          }
          if (resp.data.data.diabtiesDuration?.months > 0) {
            strDuration += resp.data.data.diabtiesDuration?.months + " months ";
          }
          if (resp.data.data.diabtiesDuration?.days > 0) {
            strDuration += resp.data.data.diabtiesDuration?.days + " days ";
          }
          setDuration(strDuration);
        }
        let tempdata = {};
        tempdata.patientName =
          resp.data.data.firstname + " " + resp.data.data.lastName;
        tempdata.patientId = patientId;
        tempdata.age = resp.data.data.age != undefined ? resp.data.data.dob != undefined ? PF.getAgeByDob(resp.data.data.dob) : resp.data.data.age : "";
        tempdata.height = resp.data.data.height;
        tempdata.weight = resp.data.data.weight;
        tempdata.bmi = resp.data.data.bmi;
        tempdata.bp = resp.data.data.bp;
        tempdata.gender = resp.data.data.gender;
        tempdata.pulse = resp.data.data.pulse;
        tempdata.temperature = resp.data.data.temprature;
        tempdata.diabetesDuration = strDuration;
        tempdata.toggleDuration =
          resp.data.data.diabitic === "yes" ? true : false;
        dispatch({ type: actionTypes.SET_VITAL_SIGNS_DATA, payload: tempdata });
      }
    }
  };

  const inputHandler = (e, type) => {
    if (/\s/g.test(e.target.value)) return;

    let ex = /^\d*\.?\d*$/;
    switch (type) {
      case "height":
        {
          if (!ex.test(e.target.value.trim())) setErrorHeight(true);
          else {
            setErrorHeight(false);
            let bmi = calculateBmi(e.target.value, formData.weight);
            setGeneralInformation({
              ...generalInformation,
              height: e?.target?.value,
              bmi: bmi,
            });
          }
          if (e.target.value === "") setRequiredHeight(true);
          else setRequiredHeight(false);
        }
        break;

      case "weight":
        {
          if (!ex.test(e.target.value)) setErrorWeight(true);
          else {
            setErrorWeight(false);
            let bmi = calculateBmi(formData.height, e.target.value);
            setGeneralInformation({
              ...generalInformation,
              weight: e.target.value,
              bmi: bmi,
            });
          }
          if (e.target.value === "") setRequiredWeight(true);
          else setRequiredWeight(false);
        }
        break;

      case "pulse":
        if (!ex.test(e.target.value)) setErrorPulse(true);
        else {
          setErrorPulse(false);
          setGeneralInformation({
            ...generalInformation,
            pulse: e.target.value,
          });
        }
        break;

      case "temprature":
        if (!ex.test(e.target.value)) setErrorTemprature(true);
        else {
          setErrorTemprature(false);
          setGeneralInformation({
            ...generalInformation,
            temperature: e.target.value,
          });
        }
        break;

      case "bp":
        if (!ex.test(e.target.value)) setErrorBp(true);
        else {
          setErrorBp(false);
          // setGeneralInformation({...generalInformation,bp:`${e.target.value}-${formData.bp1}`})
        }
        break;

      case "bp1":
        if (!ex.test(e.target.value)) setErrorBp1(true);
        else {
          setErrorBp1(false);
          //setGeneralInformation({...generalInformation,bp:`${formData.bp}-${e.target.value}`})
        }
        break;
    }

    let saveFormData = formData;
    saveFormData[e.target.name] = e.target.value;
    setFormData({ ...formData, ...saveFormData });
  };

  const calculateBmi = (height, weight) => {
    let height_in_m = height / 100;
    if (height_in_m > 0 && weight > 0) {
      let bmi = weight / (height_in_m * height_in_m);
      return bmi.toFixed(2);
    } else {
      return;
    }
  };

  const submitHandlerAutoSave = async () => {
    let saveFormData = formData;
    setFormData({ ...formData, ...saveFormData });
    try {
      let tempData = formData;
      tempData.patientId = patientId;
      tempData.appointmentId = appointmentId;
      tempData.bp = formData.bp + "/" + formData.bp1;
      let resp = "";
      let validateVitalSingForm = 1;
      $(".vital-sign-reuired").each(function () {
        if (isNaN($(this).val())) {
          validateVitalSingForm = 0;
        }
        if ($(this).hasClass("bp-first") || $(this).hasClass("bp-second")) {
          if ($(this).val().length > 3) {
            validateVitalSingForm = 0;
          }
        }
      });
      if (validateVitalSingForm == 0) {
        return false;
      }
      if (
        !errorHeight &&
        !errorPulse &&
        !errorPulse &&
        !errorTemprature &&
        !errorWeight &&
        !errorBp &&
        !errorBp1
      )
        // setIsAuoSaveUse(true)
        resp = await API.addVitalSign(tempData);
      if (resp.data.status === "Success" && resp.data.statusCode == 200) {
        let c1 = 0.0;
        let c2 = 0.0;
        let c3 = 0.0;
        let calorie = 0;
        let bmi = 0.0;
        let h = tempData.height;
        let w = tempData.weight;
        let g = tempData.gender;
        let tempTaskData = taskData;
        if (tempData.height > 0 && tempData.weight > 0) {
          c1 = PF.getMaintainWeight(h, w, g);
          c2 = PF.getLooseWeight(h, w, g);
          c3 = PF.getGainWeight(h, w, g);
          calorie = PF.getCalorie(h, w, g);
          let height_in_m = h / 100;
          if (height_in_m > 0 && w > 0) {
            bmi = w / (height_in_m * height_in_m);
            bmi = bmi.toFixed(2);
          }
          tempTaskData.c1 = c1;
          tempTaskData.c2 = c2;
          tempTaskData.c3 = c3;
          tempTaskData.segCalorie = calorie;
          tempTaskData.bmi = bmi;
          tempTaskData.calorieHieghtWeightFilledOnEdit = true; //this Variable Is Maintained For Filling Calorie Value In Drop Down If Height,Weight Not Filled At The Time Of Patinet Registeraation In Task Screen PopUp.
        }
        //console.log(tempTaskData,"tempTaskData")
        dispatch({ type: actionTypes.SET_TASK_SCREEN_DATA, payload: taskData });
      }
    } catch (err) { }
  };

  const getVitalSignData = async () => {
    let tempData = {};
    let resp = await API.vitalSignData({ patientId: patientId });
    if (resp.data && resp.data.status === "Success") {
      tempData.weight = resp.data.vitalPatientData[0].weight;
      tempData.height = resp.data.vitalPatientData[0].height;
      tempData.temprature = resp.data.vitalPatientData[0].temprature;
      tempData.pulse = resp.data.vitalPatientData[0].pulse;
      let bpData = [];
      if (resp.data.vitalPatientData[0].bp !== undefined) {
        bpData = resp.data.vitalPatientData[0].bp.split("/");
      }
      if (bpData[0] !== undefined) {
        tempData.bp = bpData[0];
      } else {
        tempData.bp = "";
      }
      if (bpData[1] !== undefined) {
        tempData.bp1 = bpData[1];
      } else {
        tempData.bp1 = "";
      }
      setFormData({ ...tempData });
    }
  };

  const successAlert = (msg) => {
    setAlertMsg(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Success"
        onConfirm={() => {
          setAlertMsg(null);
        }}
        confirmBtnBsStyle="info"
      >
        {msg}{" "}
      </SweetAlert>
    );

    setTimeout(function () {
      setAlertMsg(null);
    }, 3000);
  };

  useEffect(() => {
    if (onPageLoad) {
      if (
        emrData.vitalSignsData &&
        Object.keys(emrData.vitalSignsData).length === 0
      ) {
        getPatientGeneralInfo();
        getVitalSignData();
      } else {
        let tempData = emrData.vitalSignsData;

        let bpData = [];
        if (emrData.vitalSignsData.bp !== undefined) {
          bpData = emrData.vitalSignsData.bp.split("/");
        }
        if (bpData[0] !== undefined) {
          tempData.bp = bpData[0];
        } else {
          tempData.bp = "";
        }
        if (bpData[1] !== undefined) {
          tempData.bp1 = bpData[1];
        } else {
          tempData.bp1 = "";
        }
        tempData.temprature = emrData.vitalSignsData.temperature;
        setFormData({ ...tempData });
      }
      emrData.vitalSignsData.height === 0 ||
        emrData.vitalSignsData.height === ""
        ? setRequiredHeight(true)
        : setRequiredHeight(false);
      emrData.vitalSignsData.weight === 0 ||
        emrData.vitalSignsData.weight === ""
        ? setRequiredWeight(true)
        : setRequiredWeight(false);

      setOnPageLoad(false);
    }
  }, []);

  useAutosave({ data: autoSave, onSave: submitHandlerAutoSave });

  useEffect(() => {
    if (!onPageLoad) {
      if (autoSave) setAuoSave(false);
      else setAuoSave(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!onPageLoad) {
      let tempData = generalInformation;
      if (!errorBp && !errorBp1) tempData.bp = formData.bp + "/" + formData.bp1;
      else tempData.bp = "";
      tempData.patientId = patientId;
      tempData.diabetesDuration = duration;
      tempData.bmi = generalInformation.bmi;
      tempData.toggleDuration = toggleDuration;
      dispatch({ type: actionTypes.SET_VITAL_SIGNS_DATA, payload: tempData });
    }
  }, [formData]);

  useEffect(() => {
    setGeneralInformation({
      patientName: emrData?.vitalSignsData
        ? emrData.vitalSignsData.patientName
        : generalInformation.patientName,
      age: emrData?.vitalSignsData
        ? emrData.vitalSignsData.age
        : generalInformation.age,
      height: emrData?.vitalSignsData
        ? emrData.vitalSignsData.height
        : generalInformation.height,
      weight: emrData?.vitalSignsData
        ? emrData.vitalSignsData.weight
        : generalInformation.weight,
      bmi: emrData?.vitalSignsData
        ? emrData.vitalSignsData.bmi
        : generalInformation.bmi,
      bp: emrData?.vitalSignsData
        ? emrData.vitalSignsData.bp
        : generalInformation.bp,
      gender: emrData?.vitalSignsData
        ? emrData.vitalSignsData.gender
        : generalInformation.gender,
      pulse: emrData?.vitalSignsData
        ? emrData.vitalSignsData.pulse
        : generalInformation.pulse,
      temperature: emrData?.vitalSignsData
        ? emrData.vitalSignsData.temperature
        : generalInformation.temperature,
      diabetesDuration: emrData?.diabetesDuration
        ? emrData.vitalSignsData.diabetesDuration
        : generalInformation.diabetesDuration,
    });
  }, [emrData]);

  const renderNavTabs = () => {
    let html = ""
    if (userType === "doctor") {
      html = <>
        <li className="current">
          <NavLink
            to={`/vital-sign/${appointmentId}/${patientId}/${printingLanguage}`}
          >
            <span className="sidebar-mini">Vital Signs</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/print-labs/${appointmentId}/${patientId}/${printingLanguage}`}
          >
            <span className="sidebar-mini">Lab Tests</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/investigation/${appointmentId}/${patientId}/${printingLanguage}`}
          >
            <span className="sidebar-mini">Investigation</span>
          </NavLink>
        </li>

        <li>
          <NavLink
            to={`/complaints/${appointmentId}/${patientId}/${printingLanguage}`}
            className="nav-link"
          >
            <span className="sidebar-mini">Complaint</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/diagnosis/${appointmentId}/${patientId}/${printingLanguage}`}
            className="nav-link"
          >
            <span className="sidebar-mini">Diagnosis</span>
          </NavLink>
        </li>
        {/* <li><a>Investigation Advised</a></li>
  <li><a>All Investigations</a></li> */}
        <li>
          <NavLink
            to={`/prescription/${appointmentId}/${patientId}/${printingLanguage}`}
          >
            <span className="sidebar-mini">Prescription</span>
          </NavLink>
        </li>
      </>
    }

    if (userType === "receptionist") {
      if (localStorage.getItem("is_prescription_edit_by_receptionist") === "yes") {
        html = <>
          <li className="current">
            <NavLink
              to={`/vital-sign/${appointmentId}/${patientId}/${printingLanguage}`}
            >
              <span className="sidebar-mini">Vital Signs</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/print-labs/${appointmentId}/${patientId}/${printingLanguage}`}
            >
              <span className="sidebar-mini">Lab Tests</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/investigation/${appointmentId}/${patientId}/${printingLanguage}`}
            >
              <span className="sidebar-mini">Investigation</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/prescription/${appointmentId}/${patientId}/${printingLanguage}`}
            >
              <span className="sidebar-mini">Prescription</span>
            </NavLink>
          </li>
        </>
      } else {
        html = <>
          <li className="current">
            <NavLink
              to={`/vital-sign/${appointmentId}/${patientId}/${printingLanguage}`}
            >
              <span className="sidebar-mini">Vital Signs</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/print-labs/${appointmentId}/${patientId}/${printingLanguage}`}
            >
              <span className="sidebar-mini">Lab Tests</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/investigation/${appointmentId}/${patientId}/${printingLanguage}`}
            >
              <span className="sidebar-mini">Investigation</span>
            </NavLink>
          </li>
        </>
      }
    }

    if (userType === "educator") {
      if (localStorage.getItem("is_prescription_edit_by_educator") === "yes") {
        html = <>
          <li className="current">
            <NavLink
              to={`/vital-sign/${appointmentId}/${patientId}/${printingLanguage}`}
            >
              <span className="sidebar-mini">Vital Signs</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/print-labs/${appointmentId}/${patientId}/${printingLanguage}`}
            >
              <span className="sidebar-mini">Lab Tests</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/investigation/${appointmentId}/${patientId}/${printingLanguage}`}
            >
              <span className="sidebar-mini">Investigation</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/prescription/${appointmentId}/${patientId}/${printingLanguage}`}
            >
              <span className="sidebar-mini">Prescription</span>
            </NavLink>
          </li>
        </>
      } else {
        html = <>
          <li className="current">
            <NavLink
              to={`/vital-sign/${appointmentId}/${patientId}/${printingLanguage}`}
            >
              <span className="sidebar-mini">Vital Signs</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/print-labs/${appointmentId}/${patientId}/${printingLanguage}`}
            >
              <span className="sidebar-mini">Lab Tests</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/investigation/${appointmentId}/${patientId}/${printingLanguage}`}
            >
              <span className="sidebar-mini">Investigation</span>
            </NavLink>
          </li>
        </>
      }
    }

    return html;

  }

  return (
    <div
      className="main-content emr-wrapper dashboard-2-0"
      style={{ padding: "15px 0px" }}
    >
      <span> {alertMsg}</span>
      <Grid fluid>
        {userType === "doctor" && <Breadcrum />}
        <div>
          <div className="general-information">
            <div className="card_header">
              <div className="card_header_inner">
                <ul className="patient_details">
                  <li>
                    <a className="user-info-img">
                      {/* <span><img src={GeneralUsrImg} alt="" />  </span> */}
                      {generalInformation.patientName}
                    </a>
                  </li>
                  <span className="flex">
                    <li>
                      <a>
                        <span>Age: </span> {generalInformation.age} Y /{" "}
                        {generalInformation.gender === "Male" ? " M" : " F"}
                      </a>
                    </li>
                    <li>
                      <a>
                        <span>
                          Height{generalInformation.height > 0 ? ":" : ""}{" "}
                        </span>{" "}
                        {generalInformation.height > 0
                          ? generalInformation.height
                          : ""}{" "}
                        {generalInformation.height > 0 ? "CM" : ""}
                      </a>
                    </li>
                    <li>
                      <a>
                        <span>
                          Weight{generalInformation.weight > 0 ? ":" : ""}{" "}
                        </span>{" "}
                        {generalInformation.weight > 0
                          ? generalInformation.weight
                          : ""}{" "}
                        {generalInformation.weight > 0 ? "KG" : ""}
                      </a>
                    </li>
                    <li>
                      <a>
                        <span>BMI{generalInformation.bmi > 0 ? ":" : ""} </span>{" "}
                        {generalInformation.bmi > 0
                          ? generalInformation.bmi
                          : ""}
                      </a>
                    </li>
                    <li>
                      <a>
                        <span>
                          BP{generalInformation.bp !== "/" ? ":" : ""}{" "}
                        </span>{" "}
                        {generalInformation.bp !== "/"
                          ? generalInformation.bp
                          : ""}{" "}
                        {generalInformation.bp !== "/" ? " mmHg" : ""}
                      </a>
                    </li>
                    <li>
                      <a>
                        <span>
                          Pulse{generalInformation.pulse > 0 ? ":" : ""}{" "}
                        </span>{" "}
                        {generalInformation.pulse > 0
                          ? generalInformation.pulse
                          : ""}{" "}
                        {generalInformation.pulse > 0 ? "/min" : ""}
                      </a>
                    </li>
                    <li>
                      <a>
                        <span>
                          Temperature
                          {generalInformation.temperature > 0 ? ":" : ""}{" "}
                        </span>{" "}
                        {generalInformation.temperature > 0
                          ? generalInformation.temperature
                          : ""}{" "}
                        {generalInformation.temperature > 0 ? "F" : ""}{" "}
                      </a>
                    </li>
                    {toggleDuration && (
                      <li>
                        <a>
                          <span>Diabetes Duration: </span>{" "}
                          {emrData
                            ? emrData?.diabetesDuration
                              ? emrData?.diabetesDuration
                              : duration
                            : duration}{" "}
                        </a>
                      </li>
                    )}
                  </span>
                </ul>
                {userType === "doctor" && (
                  <div className="patient_history_btn">
                    <NavLink
                      to={`/patient-details`}
                      style={{ marginRight: "10px" }}
                      className="btn btn-fill btn-light btn-sm"
                    >
                      Go Back
                    </NavLink>

                    <NavLink
                      to={`/history/${appointmentId}/${patientId}`}
                      className="btn btn-fill btn-light pull-right btn-sm"
                    >
                      All Visits
                    </NavLink>
                  </div>
                )}
              </div>
            </div>
            {/* <div className="pending-action" onClick={() => this.handleSideModal("PendingAction")}>
                            <div className='pending-ic'>
                                <img src={PendingIc} alt='' />
                            </div>
                            <div className="pending-title">
                                <h4>Pending Action <strong>3</strong></h4>
                            </div>
                        </div> */}
          </div>
          <div className="card-content-inner">
            <div className="emr-menu-list">
              <ul>
                {renderNavTabs()}
              </ul>
            </div>
            <div className="emr-tab-content">
              <div className="center-content">
                <Form
                  autoComplete="off"
                  noValidate
                  className="add-new-drug-form"
                >
                  <Row>
                    <Col className="vital-sign-css" sm={6} md={6} lg={4}>
                      <FormGroup>
                        <label>
                          <strong>Height(cm)</strong>
                        </label>
                        <FormControl
                          type="text"
                          placeholder="Height(cm)"
                          name="height"
                          className="vital-sign-reuired"
                          maxlength="4"
                          value={formData.height ? formData.height : ""}
                          onChange={(e) => {
                            if (e.target.value.length < 4) {
                              inputHandler(e, "height");
                              setLengthHeight(false)
                            } else setLengthHeight(true)
                          }}
                        />
                        {errorHeight && (
                          <div style={{ color: "red" }}>
                            <p style={{ fontSize: "13px" }}>
                              Only numeric values are allowed.
                            </p>
                          </div>
                        )}
                        {requiredHeight && (
                          <div style={{ color: "red" }}>
                            <p style={{ fontSize: "13px" }}>
                              Height is required for BMI.
                            </p>
                          </div>
                        )}
                        {LengthHeight && (
                          <div style={{ color: "red" }}>
                            <p style={{ fontSize: "13px" }}>
                              Maximum 3 Character Allowed
                            </p>
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col sm={6} md={6} lg={4} className="vital-sign-css">
                      <FormGroup>
                        <label componentClass={ControlLabel}>
                          <strong>Weight(kg)</strong>
                        </label>
                        <FormControl
                          type="text"
                          placeholder="Weight(kg)"
                          name="weight"
                          className="vital-sign-reuired"
                          maxlength="7"
                          value={formData.weight ? formData.weight : ""}
                          onChange={(e) => {
                            if (e.target.value.length < 7) {
                              inputHandler(e, "weight");
                              setLengthWeight(false);
                            } else setLengthWeight(true);
                          }}
                        />
                        {errorWeight && (
                          <div style={{ color: "red" }}>
                            <p style={{ fontSize: "13px" }}>
                              Only numeric values are allowed.
                            </p>
                          </div>
                        )}
                        {requiredWeight && (
                          <div style={{ color: "red" }}>
                            <p style={{ fontSize: "13px" }}>
                              Weight is required for BMI.
                            </p>
                          </div>
                        )}
                        {lengthWeight && (
                          <div style={{ color: "red" }}>
                            <p style={{ fontSize: "13px" }}>
                              Maximum 5 Character Allowed
                            </p>
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col sm={6} md={6} lg={4} className="vital-sign-css">
                      <FormGroup>
                        <label componentClass={ControlLabel}>
                          <strong>BP</strong>
                        </label>
                        <Row>
                          <Col sm={5} md={5}>
                            <FormControl
                              id="txtBp"
                              type="text"
                              className="vital-sign-reuired vital-sign-reuired-bp bp-first"
                              placeholder="Systolic BP"
                              name="bp"
                              maxlength="3"
                              value={formData.bp ? formData.bp : ""}
                              onChange={(e) => {
                                inputHandler(e, "bp");
                              }}
                            />
                            {errorBp && (
                              <div style={{ color: "red" }}>
                                <p style={{ fontSize: "13px" }}>
                                  Only 3 character numeric values are allowed.
                                </p>
                              </div>
                            )}
                          </Col>
                          <Col
                            sm={2}
                            md={2}
                            style={{ textAlign: "center", padding: "10px" }}
                          >
                            /
                          </Col>

                          <Col sm={5} md={5}>
                            <FormControl
                              id="txtBp"
                              type="text"
                              className="vital-sign-reuired vital-sign-reuired-bp bp-second"
                              placeholder="Diastolic BP"
                              name="bp1"
                              maxlength="3"
                              value={formData.bp1 ? formData.bp1 : ""}
                              onChange={(e) => {
                                inputHandler(e, "bp1");
                              }}
                            />
                            {errorBp1 && (
                              <div style={{ color: "red" }}>
                                <p style={{ fontSize: "13px" }}>
                                  Only 3 character numeric values are allowed.
                                </p>
                              </div>
                            )}
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                    <Col sm={6} md={6} lg={4} className="vital-sign-css">
                      <FormGroup>
                        <label componentClass={ControlLabel}>
                          <strong>Pulse(/min)</strong>
                        </label>
                        <FormControl
                          type="text"
                          className="vital-sign-reuired"
                          placeholder="Pulse(/min)"
                          name="pulse"
                          maxlength="5"
                          value={formData.pulse ? formData.pulse : ""}
                          onChange={(e) => {
                            inputHandler(e, "pulse");
                          }}
                        />
                        {errorPulse && (
                          <div style={{ color: "red" }}>
                            <p style={{ fontSize: "13px" }}>
                              Only numeric values are allowed.
                            </p>
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col sm={6} md={6} lg={4} className="vital-sign-css">
                      <FormGroup>
                        <label componentClass={ControlLabel}>
                          <strong>Temprature(F)</strong>
                        </label>
                        <FormControl
                          type="text"
                          className="vital-sign-reuired"
                          placeholder="Temprature(F)"
                          name="temprature"
                          maxlength="5o"
                          value={formData.temprature ? formData.temprature : ""}
                          onChange={(e) => {
                            inputHandler(e, "temprature");
                          }}
                        />
                        {errorTemprature && (
                          <div style={{ color: "red" }}>
                            <p style={{ fontSize: "13px" }}>
                              Only numeric values are allowed.
                            </p>
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </div>
  );
};

export default withRouter(VitalSign);
