/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from "react";
import {
  Col,
  OverlayTrigger,
  Tooltip,
  Row,
  Grid,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Modal,
} from "react-bootstrap";
import { connect } from "react-redux";
import Card from "Front/components/Card/Card.jsx";
import { withRouter, Link } from "react-router-dom";
import * as PF from "Front/views/Home/PublicFunction.jsx";
import Button from "Admin/elements/CustomButton/CustomButton.jsx";
import "react-confirm-alert/src/react-confirm-alert.css";
import Loading from "react-loading";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import {
  patientRegistrationAction,
  patientEditAction,
  getPatientDetailAction,
  patientOutAction,
  visitListAction,
  holidayListAction,
  setVisitListActiveTabAction,
  updateVisitListAction,
  tagListAction,
  s3SiginedUrlArrayAction,
  UpdateVisitListS3SiginedUrlArrayAction,
  UpdateVisitListS3SiginedUrlArrayOnlineListAction,
  UpdateVisitListS3SiginedUrlArrayOutListAction,
  getOnlineVisitListAction,
  starStatusListAction
} from "Front/actions/home";
import SweetAlert from "react-bootstrap-sweetalert";
import { titleOptions } from "Front/variables/Variables.jsx";
import Datetime from "react-datetime";
import { appConstants } from "Front/_constants/app.constants.js";
import { addVisitAction } from "Front/actions/patient";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import mobileImg from "../../assets/img/mobile-r.png";
import mobilegreenImg from "../../assets/img/mobile-g.png";
import loadingImg from "Admin/assets/img/loading.gif";
import NextVisit from "Front/views/Home/NextVisitPopup.jsx";
import MaskedInput from "react-maskedinput";
import Calendar from "Front/components/Calendar";
import PrescriptionGreenIc from "../../assets/img/icons/PrescriptionGreen.png";
import DietIc from "../../assets/img/icons/diet.png";
import PasswordIc from "../../assets/img/icons/password.png";
import DeleteIc from "../../assets/img/icons/out-btn.png";
import ScheduleIc from "../../assets/img/icons/calendar-ic.svg";
import DietChat from "Front/views/DietChart/DietChart";
import NotificationSystem from "react-notification-system";
import { style } from "Admin/variables/Variables.jsx";
import VisitListButton from "./VisitListButton";
import VisitListTableReceptioonist from "./VisitListTableReceptioonist";
import MoreIc from "../../assets/img/icons/more.svg";
import PatientMoreDetailEducator from "./PatientMoreDetailEducator";
import EventEmitter from "Front/actions/events.js";
import { taskScreenAction } from "Front/actions/taskScreen";
import { emrSetAction } from "Front/actions/emr";

let Validator = require("validatorjs");
let formArr = {};
let rules = {
  title: "required",
  first_name: "required|string",
  last_name: "string",
  gender: "required",
  // height: 'required|numeric|min:1',
  // weight: 'required|numeric|min:1',
  city: "string",
  //dob: 'required',
  email: "email",
  age: "required|numeric",
  phone: localStorage.getItem("is_mobile_no_mandatory") === "yes" ? "required|digits_between:10,12" : "digits_between:10,12",
};
let mess = {
  //required: 'This field is required',
};
let validation = [];
let isOnline = false;
let varActiveTab = "offline"
let csvReportHeaders = [
  { label: "Clinic Id", key: "clinicId" },
  { label: "Doctor Name", key: "doctorName" },
  { label: "First Name", key: "firstName" },
  { label: "Gender", key: "gender" },
  { label: "Age", key: "age" },
];

class ReceptionistScreen extends Component {
  constructor(props) {
    super(props);
    //console.log(this.props, "ooooooooo")
    let outList = this.props.VisitList.filter((obj) => obj.doctorOut != "in");
    let inList = this.props.VisitList.filter((obj) => obj.doctorOut === "in");
    let visitListData = [];
    if (outList.length > 0) {
      visitListData = [...outList, ...inList];
    } else {
      visitListData = inList;
    }
    this.state = {
      visitList: this.props.otherVisitDate ? [] : visitListData,
      allvisitList: this.props.otherVisitDate ? [] : visitListData,
      search: this.props.search,
      search_date: this.props.search_date,
      taskContent: "",
      nextVisit: false,
      nextVisitData: [],
      row: [],
      outId: "",
      formArr: [],
      patientInfo: this.props.location.state,
      patient_idError: null,
      titleError: null,
      first_nameError: null,
      last_nameError: null,
      genderError: "",
      dobError: null,
      ageError: null,
      emailError: null,
      heightError: null,
      weightError: null,
      cityError: null,
      remarkError: null,
      alert: null,
      formData: {
        patientId: "",
        patientClinicId: "",
        patientEditId: "",
        title: "",
        first_name: "",
        last_name: "",
        gender: "",
        dob: "",
        phone: "",
        age: "",
        email: "",
        height: "",
        weight: "",
        city: "",
        remark: this.props.patientData ? this.props.patientData.patientRemark : "",
        dobd:
          this.props.patientData && this.props.patientData.dobd
            ? new Date(this.props.patientData.dobd)
            : "",
        is_dob: this.props.patientData ? this.props.patientData.is_dob : false,
        econsultation: false,
        fasting: "",
        pp: "",
        random: "",
        hbA1C: "",
        totalCholesterol: "",
        triglyceride: "",
        hdl: "",
        ldl: "",
        serumCreatinine: "",
        eFGR10: "",
        bun: "",
        acr: "",
        hb: "",
        tlc: "",
        platelet: "",
        sgot: "",
        sgpt: "",
        fib4: "",
        uricAcid: "",
        freeT4: "",
        t4: "",
        t3: "",
        tsh: "",
        cPeptideFasting: "",
        cPeptideStimulated: "",
        lengthHeight: false,
        lengthWeight: false
      },
      chkStatus: false,
      hideDeleteBtn: true,
      ageDisable: false,
      patientClinicIdError: "",
      holidayList: [],
      phoneError: "",
      showProcessing: false,
      econsultation:
        this.props.history?.location?.econsultation === true ? true : false,
      patientPasswordData: {},
      dietChatModal: false,
      dietChartTableData: {
        visitId: "",
        patientId: "",
        app: "",
        notification_token: "",
        remark: "",
        type: "",
        treatmentType: "",
        optionCount: "",
        dietType: "",
        dietLanguage: "",
        treatmentId: "",
        treatmentName: "",
        startTimeDelay: 0,
        dietId: "",
        color: "",
        patient: {},
        dietOption: 2,
        mealType: "veg-s",
        selectCalorie: 0,
        email: "",
        firstName: "",
        comments: [],
        file: [],
        mealTime: "09:00 am",
        ptVisitDate: "",
        shareDate: "",
      },
      dietId: "",
      _notificationSystem: null,
      isOpdConsultation: true,
      isOnlineConsultation: false,
      outPatientCount: this.props.otherVisitDate ? 0 : this.props.outPatientList.length > 0 ? this.props.outPatientList.length : 0,
      eVisitCount: this.props.otherVisitDate ? 0 : this.props.eVisitList.length > 0 ? this.props.eVisitList.length : 0,
      visitCount: this.props.otherVisitDate ? 0 : this.props.VisitList.length > 0 ? this.props.VisitList.length : 0,
      activeTab: this.props.history?.location?.econsultation === true ? "online" : "offline",
      moreDetails: false,
      moreDetailsData: {},
      starStatus: "",
      labTests: false,
      pId: ""
    };

    validation = new Validator(this.state, rules, mess);
    validation.passes();
    validation.fails();

    this.handleDob = this.handleDob.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.renderDay = this.renderDay.bind(this);
    this.onDismissNextVisit = this.onDismissNextVisit.bind(this);
    this.getRedirect = this.getRedirect.bind(this);
    this.backPage = this.backPage.bind(this);
    this.changeMealTime = this.changeMealTime.bind(this);
    this.getVisitList = this.getVisitList.bind(this);
    this.getIndex = this.getIndex.bind(this);
    this.taskScreen = this.taskScreen.bind(this);
    this.nameContent = this.nameContent.bind(this);
    this.ageContent = this.ageContent.bind(this);
    this.cityContent = this.cityContent.bind(this);
    this.isApp = this.isApp.bind(this);
    this.action = this.action.bind(this);
    this.tagsContent = this.tagsContent.bind(this);
    this.moreDetailsButton = this.moreDetailsButton.bind(this);
    isOnline = false;
    this.s3UrlIntervalFlag = null;
    if (this.props.location.state) {
      this.handleDob(this.props.location.state.dob);
    }
    EventEmitter.subscribe(
      "reload_home",
      this.fetchReloadDashboardFun.bind(this)
    );
    this.onDismissMoreDetail = this.onDismissMoreDetail.bind(this);
    this.getLabData = this.getLabData.bind(this);
    this.onDismissLabTests = this.onDismissLabTests.bind(this);
  }

  componentDidMount() {
    let tempRules = rules
    tempRules.phone = localStorage.getItem("is_mobile_no_mandatory") === "yes" ? "required|digits_between:10,12" : "digits_between:10,12"
    rules = tempRules
    varActiveTab = this.props.history?.location?.econsultation === true ? "online" : "offline";
    if (this.props.history?.location?.goToHome) {
      // let visitData =   !this.state.econsultation ? this.state.visitList:this.props.eVisitList;
      let visitData = [];
      if (this.props.history?.location?.activeTab === 'OutPatientList') {
        visitData = this.props.outPatientList;
        this.setState({ activeTab: 'OutPatientList' })
      } else if (this.props.history?.location?.activeTab === 'online') {
        visitData = this.props.eVisitList;
        this.setState({ activeTab: 'online' })
      } else {
        visitData = this.state.visitList
        this.setState({ activeTab: 'offline' })
      }
      // let visitData =  this.props.history?.location?.activeTab === 'OutPatientList' ? this.state.outPatientList : !this.state.econsultation ? this.state.visitList:this.props.eVisitList;
      let homePid = this.props.history?.location?.goToHome;
      var localindex_index = visitData
        .map(function (el) {
          return el.pId;
        })
        .indexOf(homePid);
      if (localindex_index != -1) {
        let localData = visitData[localindex_index];
        visitData.splice(localindex_index, 1);
        visitData.unshift(localData);
      }
      this.setState({ visitList: visitData })
    }

    if (!this.props.isTagList)
      this.props.tagListAction();

    if (!this.props.isStarStatusList)
      this.props.starStatusListAction();

    this.s3UrlIntervalFlag = setInterval(() => {
      if (this.props.TagList.length > 0) {
        let payload = []
        for (let i in this.props.TagList) {
          let obj = { "filePath": this.props.TagList[i].image, "id": this.props.TagList[i]._id }
          payload.push(obj)
        }
        this.props.s3SiginedUrlArrayAction({ "imageArray": payload })
      }

    }, 810000);


    let callVisit = true;
    this.setState({ _notificationSystem: this.refs.notificationSystem });

    this.props.holidayListAction();

    let _this = this;
    const search_date = this.props.search_date;

    // appConstants.socket.on("updateTaskScreen", function (responce) {
    //   callVisit = false;
    //   setTimeout(function () {
    //     _this.props.visitListAction(search_date);
    //   }, 1000);
    // });

    // appConstants.socket.on("addPatient", function (responce) {
    //   callVisit = false;
    //   setTimeout(function () {
    //     _this.props.visitListAction(search_date);
    //   }, 1000);
    // });

    if (callVisit) {
      if (this.props.otherVisitDate) {
        if (this.state.econsultation)
          this.props.getOnlineVisitListAction(moment(this.state.search_date).format("YYYY-MM-DD"), "", "")

        else this.props.visitListAction(moment(this.state.search_date).format("YYYY-MM-DD"), "", "", "searchDate");
      } else {
        if (this.props.VisitList.length === 0 && !this.props.isOfflineApiCalled) {

          this.props.visitListAction(moment(this.state.search_date).format("YYYY-MM-DD"));
        } else {
          if (this.props.TagList.length > 0) {
            let payload = []
            for (let i in this.props.TagList) {
              let obj = { "filePath": this.props.TagList[i].image, "id": this.props.TagList[i]._id }
              payload.push(obj)
            }
            this.props.s3SiginedUrlArrayAction({ "imageArray": payload })
          }
          if (this.state.isLoading === true) {
            this.setState({ isLoading: false });
          }
        }
      }
    }

  }


  componentWillUnmount() {

    clearInterval(this.s3UrlIntervalFlag);
  }


  componentWillReceiveProps(nextProps) {

    let { allvisitList } = this.state;

    let outList = nextProps.VisitList.filter((obj) => obj.doctorOut != "in");
    let inList = nextProps.VisitList.filter((obj) => obj.doctorOut === "in");
    let visitListData = [];
    if (outList.length > 0) {
      visitListData = [...outList, ...inList];
    } else {
      visitListData = inList;
    }

    let eoutList = nextProps.eVisitList.filter((obj) => obj.doctorOut != "in");
    let einList = nextProps.eVisitList.filter((obj) => obj.doctorOut === "in");
    let evisitListData = [];
    if (eoutList.length > 0) {
      evisitListData = [...eoutList, ...einList];
    } else {
      evisitListData = einList;
    }




    if (nextProps.isReloadHome !== this.props.isReloadHome) {

      this.setState({ activeTab: "offline", search_date: nextProps.search_date, visitList: nextProps.VisitList, econsultation: false })
      if (nextProps.TagList.length > 0) {
        let payload = []
        for (let i in nextProps.TagList) {
          let obj = { "filePath": nextProps.TagList[i].image, "id": nextProps.TagList[i]._id }
          payload.push(obj)
        }
        this.props.s3SiginedUrlArrayAction({ "imageArray": payload })
      }
    }

    if (nextProps.isPatientRemarkUpdated !== this.props.isPatientRemarkUpdated) {
      //console.log(nextProps, "nextProps")

      let vistList = this.state.activeTab === "offline" ? nextProps.VisitList : this.state.activeTab === "online" ? nextProps.eVisitList : nextProps.outPatientList
      let tempData = this.state.formData
      if (this.state.formData) {
        let data = vistList.find((ele) => ele.pId === this.state.formData.patientId)
        //console.log(data, "dataaaaaaaa")
        if (data) {
          tempData.remark = data.patientRemark
        }
      }
      //console.log(tempData, "lllllllll")
      this.setState({ visitList: vistList, formData: tempData })

    }

    // if(nextProps.isVisitListUpdateS3SignedUrlOnlineList !== this.props.isVisitListUpdateS3SignedUrlOnlineList){
    //   this.setState({visitList:nextProps.eVisitList})
    // }

    // if(nextProps.isVisitListUpdateS3SignedUrlOutList !== this.props.isVisitListUpdateS3SignedUrlOutList){
    //   console.log(nextProps,"nextpropsoutlist") 
    //   this.setState({visitList:nextProps.outPatientList})
    // }

    if (nextProps.isVisitListUpdateS3SignedUrl !== this.props.isVisitListUpdateS3SignedUrl) {
      let vistList = this.state.activeTab === "offline" ? nextProps.VisitList : this.state.activeTab === "online" ? nextProps.eVisitList : nextProps.outPatientList
      this.setState({ visitList: vistList })
    }

    if (nextProps.isS3SignedUrlArr !== this.props.isS3SignedUrlArr) {
      this.props.UpdateVisitListS3SiginedUrlArrayAction()
    }

    if (nextProps.isTagList !== this.props.isTagList) {
      if (nextProps.TagList.length > 0) {

        let payload = []
        for (let i in nextProps.TagList) {
          let obj = { "filePath": nextProps.TagList[i].image, "id": nextProps.TagList[i]._id }
          payload.push(obj)
        }
        this.props.s3SiginedUrlArrayAction({ "imageArray": payload })
      }
    }


    if (nextProps.isVisitListUpdated !== this.props.isVisitListUpdated) {
      this.state.activeTab === "online" ? this.setState({ visitList: evisitListData, visitCount: nextProps.visitCount, eVisitCount: nextProps.eVisitCount, outPatientCount: nextProps.outPatientCount }) : this.state.activeTab === "offline" ? this.setState({ visitList: visitListData, visitCount: nextProps.visitCount, eVisitCount: nextProps.eVisitCount, outPatientCount: nextProps.outPatientCount }) : this.setState({ visitList: nextProps.outPatientList, visitCount: nextProps.visitCount, eVisitCount: nextProps.eVisitCount, outPatientCount: nextProps.outPatientCount });
    }

    if (nextProps.isAddToVisitList !== this.props.isAddToVisitList) {


      if (nextProps.TagList.length > 0) {
        let payload = []
        for (let i in nextProps.TagList) {
          let obj = { "filePath": nextProps.TagList[i].image, "id": nextProps.TagList[i]._id }
          payload.push(obj)
        }
        this.props.s3SiginedUrlArrayAction({ "imageArray": payload })

      }

      if (this.state.activeTab === "online") {
        this.setState({ visitList: evisitListData, visitCount: nextProps.visitCount, eVisitCount: nextProps.eVisitCount, outPatientCount: nextProps.outPatientCount, activeTab: "online" })
      }

      if (this.state.activeTab === "offline") {
        this.setState({ visitList: visitListData, visitCount: nextProps.visitCount, eVisitCount: nextProps.eVisitCount, outPatientCount: nextProps.outPatientCount, activeTab: "offline" })
      }
    }

    if (nextProps.isHolidayList !== this.props.isHolidayList) {
      this.setState({ holidayList: nextProps.HolidayList.list });
    }

    if (nextProps.isPatientDetail !== this.props.isPatientDetail) {
      if (nextProps.PatientDetail && nextProps.PatientDetail.data) {
        let formData = this.state.formData;
        formData["patientId"] = nextProps.PatientDetail.data._id;
        formData["patientClinicId"] =
          nextProps.PatientDetail.data.patientClinicId;
        // formData['patientEditId'] = nextProps.PatientDetail.data.pId;
        formData["title"] = nextProps.PatientDetail.data.title;
        formData["first_name"] = nextProps.PatientDetail.data.firstName;
        formData["last_name"] = nextProps.PatientDetail.data.lastName;
        formData["gender"] = nextProps.PatientDetail.data.gender;
        formData["dob"] = moment(nextProps.PatientDetail.data.dob).format(
          "YYYY-MM-DD"
        );
        formData["email"] = nextProps.PatientDetail.data.email;
        formData["height"] = nextProps.PatientDetail.data.height;
        formData["weight"] = nextProps.PatientDetail.data.weight;
        formData["city"] = nextProps.PatientDetail.data.city;
        formData["remark"] = nextProps.PatientDetail.data.remark
          ? nextProps.PatientDetail.data.remark
          : "";
        formData["dobd"] = new Date(nextProps.PatientDetail.data.dob);
        formData["age"] = nextProps.PatientDetail.data.age;
        formData["phone"] = nextProps.PatientDetail.data.phone;
        formData["econsultation"] = nextProps.PatientDetail.data.econsultation;
        this.setState({ formData: formData, pId: nextProps.PatientDetail.data._id });
        this.setState({ patientClinicIdError: "" });
      }
    }

    if (nextProps.isPatientDetailError !== this.props.isPatientDetailError) {
      this.setState({ patientClinicIdError: "Patient not found for this id." });
      this.setState({
        formData: {
          patientId: "",
          patientClinicId: "",
          patientEditId: "",
          title: "",
          first_name: "",
          last_name: "",
          gender: "",
          dob: "",
          age: "",
          email: "",
          height: "",
          weight: "",
          city: "",
          remark: "",
          dobd: "",
          phone: "",
          econsultation: false,
          fasting: "",
          pp: "",
          random: "",
          hbA1C: "",
          totalCholesterol: "",
          triglyceride: "",
          hdl: "",
          ldl: "",
          serumCreatinine: "",
          eFGR10: "",
          bun: "",
          acr: "",
          hb: "",
          tlc: "",
          platelet: "",
          sgot: "",
          sgpt: "",
          fib4: "",
          uricAcid: "",
          freeT4: "",
          t4: "",
          t3: "",
          tsh: "",
          cPeptideFasting: "",
          cPeptideStimulated: "",
          // islabTests: false
        }, pId: ""
      });
    } else {
      this.setState({ patientClinicIdError: "" });
    }


    if (nextProps.isVisitList !== this.props.isVisitList) {
      // let j = 0;
      // let visitList;

      // let blackArr = [];
      // let yellowArr = [];
      // let whiteArr = [];

      // visitList = nextProps.VisitList.data.data.map((key, i) => {

      // let vData = nextProps.VisitList.data.data;

      // for (let x in vData) {
      //   let key = vData[x];
      //   let patient = key.patient_data[0];
      //   if (patient == undefined) {
      //     continue;
      //   }
      //   let h = patient.height;
      //   let w = patient.weight;
      //   let g = patient.gender;
      //   let row = {
      //     id: key._id,
      //     sn: ++j,
      //     pId: patient._id,
      //     patientNo: patient.patientNo,
      //     patientId: patient.patientClinicId,
      //     patientClinicId: patient.patientClinicId,
      //     name:
      //       patient.title + " " + patient.firstName + " " + patient.lastName,
      //     age: PF.getAgeByDob(patient.dob),
      //     city: patient.city,
      //     share: key.doc_count + " / " + key.read_docs_count,
      //     read_share: key.read_docs_count,
      //     task: key.task_count + " / " + key.complete_tasks_count,
      //     complete_task: key.complete_tasks_count,
      //     diet: 0,
      //     in_time: moment(key.createdAt).format("hh:mm A"),
      //     next_visit_date: patient.nextDate,
      //     app: patient.app,
      //     status: key.status,
      //     height: h,
      //     weight: w,
      //     bmi: PF.getBMI(h, w),
      //     ibw: PF.getIBW(h, w, g),
      //     c1: PF.getMaintainWeight(h, w, g),
      //     c2: PF.getLooseWeight(h, w, g),
      //     c3: PF.getGainWeight(h, w, g),
      //     segCalorie: PF.getCalorie(h, w, g),
      //     calorieType: PF.getCalorieType(h, w, g),
      //     created_date: moment(key.createdAt).format("YYYY-MM-DD"),
      //     remark: key.remark,
      //     patientRemark: patient.remark,
      //     taskList: key.Tasks,
      //     documentList: key.Documents,
      //     educatorOut: key.educatorOut,
      //     doctorOut: key.doctorOut,
      //     patientEditId: key.educatorOut,
      //     title: patient.title,
      //     _id: key._id,
      //     firstName: patient.firstName,
      //     lastName: patient.lastName,
      //     gender: patient.gender,
      //     dob: patient.dob,
      //     email: patient.email,
      //     phone: patient.phone,
      //     notification_token: patient.notification_token,
      //     econsultation: key.econsultation,
      //     reportCount: key.reportCount,
      //     lastVisitDate: key.lastVisitDate,
      //     billingCycle: key.billingCycle,
      //     ...patient,
      //   };

      //   row.remark = patient.remark;
      //   row.flag = key.flag;
      //   row.patientStatus = key.patientStatus;
      //   row.language = patient.language;
      //   row.validPrescription = key.validPrescription;
      //   row.emrVisitId = key._id;
      //   if (row.educatorOut === "out") {
      //     yellowArr.push(row);
      //   } else if (row.doctorOut === "out") {
      //     blackArr.push(row);
      //   } else {
      //     whiteArr.push(row);
      //   }
      // }

      // visitList = whiteArr.concat(blackArr);
      // visitList = visitList.concat(yellowArr);
      // visitList = blackArr.concat(whiteArr);
      // visitList = visitList.concat(yellowArr);
      // allvisitList = visitList;

      // this.setState({ visitList, allvisitList }, () =>
      //   this.sortvisitListData(this.state.econsultation)
      // );
      if (this.state.activeTab === "online") {

        this.setState({ eVisitCount: nextProps.eVisitList.length, visitList: evisitListData });
        if (nextProps.TagList.length > 0) {
          let payload = []
          for (let i in nextProps.TagList) {
            let obj = { "filePath": nextProps.TagList[i].image, "id": nextProps.TagList[i]._id }
            payload.push(obj)
          }
          this.props.s3SiginedUrlArrayAction({ "imageArray": payload })
        }
      }

      if (this.state.activeTab === "offline") {

        this.setState({ visitCount: nextProps.VisitList.length, visitList: visitListData });
        if (nextProps.TagList.length > 0) {

          // if(nextProps.isTagList !== this.props.isTagList){

          let payload = []
          for (let i in nextProps.TagList) {
            let obj = { "filePath": nextProps.TagList[i].image, "id": nextProps.TagList[i]._id }
            payload.push(obj)
          }
          this.props.s3SiginedUrlArrayAction({ "imageArray": payload })
          //  }
        }

      }



      if (this.state.activeTab === "OutPatientList") {
        this.setState({ outPatientCount: nextProps?.outPatientList?.length, visitList: nextProps.outPatientList });
        if (nextProps.TagList.length > 0) {
          let payload = []
          for (let i in nextProps.TagList) {
            let obj = { "filePath": nextProps.TagList[i].image, "id": nextProps.TagList[i]._id }
            payload.push(obj)
          }
          this.props.s3SiginedUrlArrayAction({ "imageArray": payload })
        }
      }



      this.setState({ showProcessing: false });
      if (this.state.isLoading === true) {
        this.setState({ isLoading: false });
      }
    }

    // if(nextProps.isPatientOut !== this.props.isPatientOut){
    //     let element = document.getElementsByClassName(this.state.outId);

    //     element[0].classList.add("Ed-Out");
    //     this.setState({outId:''});
    // }


    if (nextProps.isPatientOut !== this.props.isPatientOut) {
      this.setState({ outId: "" });
      let visitList = this.state.visitList;
      for (let x in visitList) {
        if (this.state.outId === visitList[x].id) {
          visitList[x].educatorOut = "out";
        }
      }
      this.setState({ visitList });

      // setTimeout(function () {
      //   let params = {
      //     clinicId: localStorage.getItem("clinicId"),
      //   };
      //   appConstants.socket.emit("updateTaskScreen", params);
      // }, 1000);
      // this.successAlert("Patient Out Successfully");
    }

    if (this.state.isLoading === true) {
      this.setState({ isLoading: false });
    }

    if (
      nextProps.isPatientRegistration !== this.props.isPatientRegistration &&
      this.props.isPatientRegistration === false
    ) {
      this.setState({ showProcessing: false });

      this.successAlert("Patient successfully registered");
      //this.props.addVisitAction({"patient_id":nextProps.patientId});
      setTimeout(function () {
        let params = {
          clinicId: localStorage.getItem("clinicId"),
        };
        appConstants.socket.emit("addPatient", params);
      }, 1000);

      let formData = this.state.formData;
      let formArr = [];
      formData["patientId"] = "";
      formData["patientEditId"] = "";
      formData["title"] = "";
      formData["first_name"] = "";
      formData["last_name"] = "";
      formData["gender"] = "";
      formData["dob"] = "";
      formData["age"] = "";
      formData["email"] = "";
      formData["height"] = "";
      formData["weight"] = "";
      formData["city"] = "";
      formData["remark"] = "";
      formData["dobd"] = "";
      formData["phone"] = "";
      formData["econsultation"] = false;
      formData["fasting"] = "";
      formData["pp"] = "";
      formData["random"] = "";
      formData["hbA1C"] = "";
      formData["totalCholesterol"] = "";
      formData["triglyceride"] = "";
      formData["hdl"] = "";
      formData["ldl"] = "";
      formData["serumCreatinine"] = "";
      formData["eFGR10"] = "";
      formData["bun"] = "";
      formData["acr"] = "";
      formData["hb"] = "";
      formData["tlc"] = "";
      formData["platelet"] = "";
      formData["sgot"] = "";
      formData["sgpt"] = "";
      formData["fib4"] = "";
      formData["uricAcid"] = "";
      formData["freeT4"] = "";
      formData["t4"] = "";
      formData["t3"] = "";
      formData["tsh"] = "";
      formData["cPeptideFasting"] = "";
      formData["cPeptideStimulated"] = "";
      // formData["islabTests"] = false;
      this.setState({ formData: formData, patientClinicIdError: "", pId: "", formArr });
      //this.props.visitListAction(this.state.search_date)
      this.setState({ econsultation: isOnline, isOpdConsultation: true, isOnlineConsultation: false });
      isOnline = false;
    }

    if (
      nextProps.isPatientEdit !== this.props.isPatientEdit &&
      this.props.isPatientEdit === false
    ) {
      this.setState({ showProcessing: false });
      let tempData = JSON.stringify(this.state.formData)
      tempData = JSON.parse(tempData)

      if (!tempData.econsultation)
        this.props.visitListAction(moment(this.state.search_date).format("YYYY-MM-DD"))
      else this.props.visitListAction(moment(this.state.search_date).format("YYYY-MM-DD"), "", "", "online")

      let formData = this.state.formData;
      let formArr = [];
      formData["patientId"] = "";
      formData["patientEditId"] = "";
      formData["title"] = "";
      formData["first_name"] = "";
      formData["last_name"] = "";
      formData["gender"] = "";
      formData["dob"] = "";
      formData["age"] = "";
      formData["email"] = "";
      formData["height"] = "";
      formData["weight"] = "";
      formData["city"] = "";
      formData["remark"] = "";
      formData["dobd"] = "";
      formData["phone"] = "";
      formData["econsultation"] = false;
      formData["fasting"] = "";
      formData["pp"] = "";
      formData["random"] = "";
      formData["hbA1C"] = "";
      formData["totalCholesterol"] = "";
      formData["triglyceride"] = "";
      formData["hdl"] = "";
      formData["ldl"] = "";
      formData["serumCreatinine"] = "";
      formData["eFGR10"] = "";
      formData["bun"] = "";
      formData["acr"] = "";
      formData["hb"] = "";
      formData["tlc"] = "";
      formData["platelet"] = "";
      formData["sgot"] = "";
      formData["sgpt"] = "";
      formData["fib4"] = "";
      formData["uricAcid"] = "";
      formData["freeT4"] = "";
      formData["t4"] = "";
      formData["t3"] = "";
      formData["tsh"] = "";
      formData["cPeptideFasting"] = "";
      formData["cPeptideStimulated"] = "";
      // formData["islabTests"] = false;
      this.successAlert("Patient Successfully Updated");
      //this.setState({patientClinicIdError:"", formArr});
      this.setState({ formData: formData, patientClinicIdError: "", pId: "", formArr });
      //this.props.visitListAction(this.state.search_date)
      this.setState({ econsultation: isOnline, isOpdConsultation: true, isOnlineConsultation: false });
      isOnline = false;
      setTimeout(function () {
        let params = {
          clinicId: localStorage.getItem("clinicId"),
        };
        appConstants.socket.emit("addPatient", params);
      }, 1000);
    }
  }

  // isApp(cell, row) {
  //   // if (row.app) {
  //   //     return <i className="fa fa-check" aria-hidden="true"></i>;
  //   // } else {
  //   //     return <i className="red fa fa-times" aria-hidden="true"></i>;
  //   // }

  //   let app = "";

  //   if (row.app) {
  //     app = (
  //       <OverlayTrigger
  //         placement="bottom"
  //         overlay={
  //           <Tooltip id="remove">
  //             <span className="fa fa-check check" aria-hidden="true"></span>
  //           </Tooltip>
  //         }
  //       >
  //         {/* <span className='badge mobile-green-clr'><span className="fa fa-mobile" aria-hidden="true"></span></span>*/}
  //         <span className="badge1">
  //           <img className="clr-m" src={mobilegreenImg} alt="" />
  //         </span>
  //       </OverlayTrigger>
  //     );
  //   } else {
  //     app = (
  //       <OverlayTrigger
  //         placement="bottom"
  //         overlay={
  //           <Tooltip id="remove">
  //             <span className="fa fa-times cross" aria-hidden="true"></span>
  //           </Tooltip>
  //         }
  //       >
  //         {/*<span className='badge mobile-red-clr'><span className="fa fa-mobile" aria-hidden="true"></span></span>*/}
  //         <span className="badge1">
  //           <img className="clr-m" src={mobileImg} alt="" />
  //         </span>
  //       </OverlayTrigger>
  //     );
  //   }

  //   return app;
  // }

  isApp(cell, row) {
    let lastDiet =
      row && row.lastDiet && row.lastDiet.length > 0
        ? row.lastDiet[0].readStatus
        : "";

    let app = "";

    if (row.app) {
      app = (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="remove">
              <span className="fa fa-check check" aria-hidden="true"></span>
            </Tooltip>
          }
        >
          <span className="">
            <img className="clr-m" src={mobilegreenImg} alt="" />
          </span>
        </OverlayTrigger>
      );
    } else {
      app = (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="remove">
              <span className="fa fa-times cross" aria-hidden="true"></span>
            </Tooltip>
          }
        >
          <span className="">
            <img className="clr-m" src={mobileImg} alt="" />
          </span>
        </OverlayTrigger>
      );
    }
    return app;

  }

  getIndex(cell, row, enumObject, rowIndex) {
    return rowIndex + 1;
  }

  fetchReloadDashboardFun() {
    let _this = this;
    const search_date = _this.props.search_date;
    _this.setState({ search_date: search_date, activeTab: "offline" })
    setTimeout(function () {
      _this.props.visitListAction(search_date, "", "", "searchDate");
    }, 1000);
  }

  // outButton(cell, row) {
  //     let html = "";
  //     if (row.educatorOut !== 'out' && row.doctorOut !== 'out') {
  //         html = <OverlayTrigger placement="top" overlay={<Tooltip id="remove">Out</Tooltip>}>
  //             <Button simple bsStyle="danger" bsSize="xs" onClick={this.patientVisitOut.bind(this, row.id)}>
  //                 <i className="fa fa-times"></i>
  //             </Button>
  //         </OverlayTrigger>;
  //     }
  //     return html;

  // }

  backPage() {
    this.setState({ dietChatModal: false });
  }

  changeMealTime(time) {
    let formData = this.state.dietChartTableData;
    formData["mealTime"] = time;
    this.setState({ formData });
  }

  outButton(cell, row) {
    let html = "";
    if (row.educatorOut !== "out" && row.doctorOut === "out") {
      let readStatus = "read";
      for (let i in row.documentList) {
        if (row.documentList[i].status === "unread") {
          readStatus = "unread";
        }
      }

      for (let i in row.taskList) {
        if (row.taskList[i].status === "uncomplete") {
          readStatus = "unread";
        }
      }

      for (let i in row.lastDiet) {
        if (
          row.lastDiet[i].color === "red" &&
          moment().format("YYYY-MM-DD") ===
          moment(row.lastDiet[i].createdAt).format("YYYY-MM-DD")
        ) {
          readStatus = "unread";
        }
      }

      html = (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="remove">
              Out{readStatus === "unread" ? "" : "(No Work Pending)"}
            </Tooltip>
          }
        >
          <Button
            simple
            bsStyle={readStatus === "unread" ? "danger" : "success"}
            bsSize="xs"
            onClick={this.patientVisitOut.bind(this, row.id)}
          >
            <img
              title="Out Patient"
              className="table-action-ic"
              src={DeleteIc}
              alt="delete"
            />
          </Button>
        </OverlayTrigger>
      );
    }
    return html;
  }

  patientVisitOut(id) {
    this.setState({ outId: id });
    confirmAlert({
      title: "Confirm to out",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            let msg = "Patient Out Successfully";
            this.successAlert(msg);
            this.props.patientOutAction(id, this.state.econsultation);
          }
        },
        {
          label: "No",
        },
      ],
    });
  }

  _setTableOption() {
    if (this.state.isLoading) {
      return (
        <Loading
          type="bars"
          color="#000000"
          style={{ margin: "0px auto", width: "40px" }}
        />
      );
    } else {
      return "No data found!";
    }
  }

  getOutClass(cell, row) {
    if (row.educatorOut === "out") {
      return row.id + " Ed-Out";
    } else if (row.doctorOut === "out") {
      return row.id + " Dr-Out";
    } else {
      return row.id;
    }
  }

  getPatientOutClass(cell, row) {
    let { doctorOut, educatorOut, id, lastVisitDate, billingCycle, addVisit } =
      row;
    // if (doctorOut === 'out' && educatorOut === 'out') {
    //     return id + " Ed-Out";
    // }
    // else {
    let date1 = new Date(lastVisitDate);
    let date2 = new Date(addVisit);
    var paetientColor = "";
    if (billingCycle) {
      billingCycle = JSON.parse(billingCycle);
    }
    date1 = date1.setDate(date1.getDate() + billingCycle);
    date1 = moment(date1).format("YYYY-MM-DD");
    date2 = moment(date2).format("YYYY-MM-DD");
    if (lastVisitDate == null) {
      paetientColor = "register-patient";
    }
    // else if(new Date(date1) > new Date(date2)){
    else if (date1 > date2) {
      paetientColor = "reported-patient";
    } else {
      paetientColor = "followup-patient";
    }
    return id + " " + paetientColor;
    // }
  }

  /* nextButton(cell, row, enumObject, rowIndex) {
         return (<OverlayTrigger placement="top" overlay={<Tooltip id="NextVisit">Next Visit</Tooltip>}>
             <Link to={{ pathname: '/next-visit', state: { row: row } }}><i className="fa fa-calendar"></i> {row.next_visit_date !== null && row.next_visit_date ? moment(row.next_visit_date).format('DD-MM-YYYY') : ''} </Link>
         </OverlayTrigger>);
     }*/

  nextButton(cell, row, enumObject, rowIndex) {
    return (
      <>
        {/* <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="NextVisit">Next Visit</Tooltip>}
        >
          <span
            style={{ display: "inline-block", marginRight: "6px" }}
            onClick={() =>
              this.setState({
                nextVisit: true,
                nextVisitData: row,
                patientOut: false,
              })
            }
          >
            <img className="table-action-ic" src={ScheduleIc} alt="" />{" "}
            {row.next_visit_date !== null && row.next_visit_date
              ? moment(row.next_visit_date).format("DD-MM-YYYY")
              : ""}
          </span>
        </OverlayTrigger>
        {row.lastDiet.length != 0 && (
          <span style={{ display: "inline-block", marginRight: "6px" }}>
            {this.diet(cell, row)}
          </span>
        )}
        {row.patientStatus === "old" && row.flag === "yes" && (
          <span style={{ display: "inline-block", marginRight: "3px" }}>
            {this.emr(cell, row)}
          </span>
        )}
        <span style={{ display: "inline-block", marginRight: "3px" }}>
          {this.password(cell, row)}
        </span> */}

        <span style={{ display: "inline-block", marginRight: "3px" }}>
          {this.password(cell, row)}
        </span>
        {this.outButton(cell, row)}
        {this.moreDetailsButton(cell, row)}

      </>
    );
  }

  moreDetailsButton(cell, row) {
    return (
      <Link
        onClick={() => {
          let emrData = {};
          this.props.taskScreenAction(row);
          if (this.props.patientEmrData) {
            if (this.props.patientEmrData.patientId === row._id)
              emrData = {
                patientId: this.props.patientEmrData.patientId,
                isEmr: this.props.patientEmrData.isEmr,
              };
          }
          this.props.emrSetAction(emrData);
          let objStarStatus = {}
          let strPatientStarStatus = ""
          if (row?.patientStarStatus)
            objStarStatus = this.props.starStatusList.find(obj => obj._id === row.patientStarStatus)
          if (objStarStatus) {
            strPatientStarStatus = objStarStatus.title
          }
          this.setState({
            moreDetails: true,
            moreDetailsData: row,
            starStatus: strPatientStarStatus
          })
        }
        }
      >
        <img
          title="More Details"
          className="table-action-ic"
          src={MoreIc}
          alt="more"
        />
      </Link>
    );
  }

  onDismissNextVisit() {
    this.setState({ nextVisit: false });
    this.successAlert("Next Visit Successfully Submitted");
  }

  taskScreen(cell, row, enumObject, rowIndex) {
    return (
      <Link
        to="#"
        onClick={(e) => {
          this.handleTaskScreen(row);
        }}
      >
        {row.patientId}
      </Link>
    );
  }

  handleTaskScreen(row) {
    this.setState({ patient_idError: " " });
    let formData = this.state.formData;

    formData["patientId"] = row.pId;
    // formData['id'] = row.pId;
    formData["patientClinicId"] = row.patientClinicId;
    // formData['patientEditId'] = row.pId;
    formData["title"] = row.title ? row.title : "";
    formData["first_name"] = row.firstName ? row.firstName : "";
    formData["last_name"] = row.lastName ? row.lastName : "";
    formData["gender"] = row.gender ? row.gender : "";
    formData["dob"] = row.dob ? moment(row.dob).format("YYYY-MM-DD") : "";
    formData["age"] = row.age >= 0 ? parseInt(row.age, 10) : "";
    formData["email"] = row.email ? row.email : "";
    formData["height"] = row.height ? row.height : "";
    formData["weight"] = row.weight ? row.weight : "";
    formData["city"] = row.city ? row.city : "";
    formData["remark"] = row.patientRemark ? row.patientRemark : "";
    //formData['dobd'] = row.dob ? moment(row.dob).format('MM/DD/YYYY') : '';
    formData["dobd"] = row.dob ? new Date(row.dob) : "";
    formData["phone"] = row.phone ? parseInt(row.phone, 10) : "";
    formData["econsultation"] = row.econsultation;

    if (row.econsultation) {
      this.setState({ isOnlineConsultation: true, isOpdConsultation: false })
    } else this.setState({ isOpdConsultation: true, isOnlineConsultation: false })
    this.setState({ formData: formData, pId: row.pId });
  }

  successAlert(msg, link) {
    // this.setState({
    //   alert: (
    //     <SweetAlert
    //       success
    //       style={{ display: "block", marginTop: "-100px" }}
    //       title="Success"
    //       onConfirm={() => this.hideAlert()}
    //       onCancel={() => this.hideAlert()}
    //       confirmBtnBsStyle="info"
    //     >
    //       {msg}
    //     </SweetAlert>
    //   ),
    // });

    // let _this = this;
    // setTimeout(function () {
    //   _this.hideAlert();
    // }, 3000);

    this.state._notificationSystem.addNotification({
      title: (
        <span
          data-notify="icon"
          className="pe-7s-check"
          style={{
            fontSize: "30px",
            color: "#fff",
            padding: "0px !important",
            top: "0% !important",
          }}
        ></span>
      ),
      message: (
        <div>
          {/* <p>{from}</p> */}
          <span>{msg}</span>
        </div>
      ),
      level: "success",
      position: "tr",
      autoDismiss: 2,
    });
  }

  hideAlert() {
    this.setState({
      alert: false,
    });
  }

  handleChange(e) {
    let field = this.state.formData;
    field[e.target.name] = e.target.value;

    if (e.target.name === "age") {
      field["dob"] = moment().subtract(e.target.value, "years"); //(today.getFullYear()-e.target.value) + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    }

    if (
      e.target.name === "first_name" ||
      e.target.name === "last_name" ||
      e.target.name === "city"
    ) {
      field[e.target.name] = e.target.value.toUpperCase();
    }

    if (e.target.name === "phone") {
      if (e.target.value && e.target.value.length !== 10) {
        //this.setState({ phoneError: (<small className="text-danger">Phone number has to be 10 digits.</small>) })
      } else {
        //this.setState({ phoneError: null })
      }
    }

    if (e.target.name === "econsultation") {
      if (e.target.value === "true") isOnline = true;
      if (e.target.value === "false") isOnline = false;
      field[e.target.name] = isOnline ? true : false;
    }

    // if (e.target.name === "weight") {
    //   if (e.target.value && e.target.value.length > 5) {
    //     this.setState({ weightError: (<small className="text-danger">Maximum 5 Character Allowed</small>) })
    //   } else {
    //     this.setState({ weightError: null })
    //   }
    // }

    // if (e.target.name === "height") {
    //   if (e.target.value && e.target.value.length > 3) {
    //     this.setState({ heightError: (<small className="text-danger">Maximum 3 Character Allowed</small>) })
    //   } else {
    //     this.setState({ heightError: null })
    //   }
    // }

    this.setState({ formData: field });
  }

  handleDob(date) {
    var selectedDate = moment(date).format("YYYY-MM-DD");
    var today = moment().format("YYYY-MM-DD");
    let field = this.state.formData;
    field["age_color"] = "green";

    if (selectedDate >= today) {
      this.setState({ formData: field });
      this.setState({ dobError: "Invalid Date." });
    } else {
      field["dob"] = moment(date).format("YYYY-MM-DD");
      field["dobd"] = new Date(date);
      let dateDiff = new Date().getFullYear() - new Date(date).getFullYear();
      field["age"] = dateDiff;
      field["is_dob"] = true;
      this.setState({ formData: field, dobError: "" });
    }
  }

  handleAge(e) {
    e.preventDefault();
    let field = this.state.formData;
    field["age"] = e.target.value;
    field["is_dob"] = false;
    field["age_color"] = "red";
    let dob = moment().subtract("years", e.target.value);

    field["dob"] = moment(dob._d).format("YYYY-MM-DD");
    //console.log(field["dob"]);
    field["dobd"] = dob._d;
    //field['dobd'] = "";
    let ageDisable = false;
    if (e.target.value.length > 0) {
      ageDisable = true;
    }

    this.setState({ formData: field, ageDisable: ageDisable });
  }

  action(cell, row, enumObject, rowIndex) {
    return this.nextButton(cell, row, enumObject, rowIndex);
  }

  emr(cell, row, enumObject, rowIndex) {
    let html = "";
    //   if (row.educatorOut !== 'out' && row.doctorOut !== 'out') {

    //     }
    if (row.patientStatus === "old") {
      if (row.flag === "yes") {
        html = (
          <span
            onClick={() =>
              localStorage.setItem("patientVisit", JSON.stringify(row))
            }
          >
            <Link
              to={{
                pathname: `print/${row.id}/${row.pId}/brand/${row.language}`,
              }}
            >
              <img
                alt="Prescription"
                className="table-action-ic"
                title="Prescription"
                src={PrescriptionGreenIc}
              ></img>
            </Link>
          </span>
        );
      } else {
        html = "";
      }
    }
    return html;
  }

  password(cell, row) {
    let html = "";
    html = (
      <Link onClick={(e) => this.showPassword(cell, row)}>
        <img
          className="table-action-ic"
          alt="Password"
          title="Password"
          src={PasswordIc}
        ></img>
      </Link>
    );
    return html;
  }

  ageContent(cell, row) {
    return (
      <div className={"ageColor_" + row.age_color}>
        {row && row.age === "" ? "" : parseInt(row.age, 10)}
      </div>
    );
  }

  cityContent(cell, row) {
    let html = "";
    html = <span>{row?.city}</span>;
    return html;
  }

  diet(cell, row) {
    let html = "";
    row.lastDiet.length != 0
      ? (html = (
        <Link onClick={(e) => this.getRedirect(row)}>
          <img
            className="table-action-ic"
            alt="Diet"
            title="Diet"
            src={DietIc}
          ></img>
        </Link>
      ))
      : (html = "");
    return html;
  }

  showPassword(cell, row) {
    let tempObj = {
      patientId: row.patientId,
      userName: row.firstName + " " + row.lastName,
      password: row.show_password,
    };
    this.setState({
      showPassword: !this.state.showPassword,
      patientPasswordData: tempObj,
    });
  }

  getRedirect(row) {
    let tempDietChartTableData = {};
    let tempPatienData = {
      name: row.name,
      patientId: row._id,
      age: row.age,
      weight: row.weight,
      height: row.height,
      gender: row.gender,
      patientClinicId: row.patientClinicId
    };
    tempDietChartTableData.visitId = row.id;
    tempDietChartTableData.patientId = row._id;
    tempDietChartTableData.patientClinicId = row.patientClinicId;
    tempDietChartTableData.app = row.app;
    tempDietChartTableData.notification_token = row.notification_token;
    tempDietChartTableData.remark = row.patientRemark;
    tempDietChartTableData.type = "";
    tempDietChartTableData.treatmentType = row.lastDiet[0].treatmentType;
    tempDietChartTableData.optionCount = row.lastDiet[0].optionCount;
    tempDietChartTableData.dietType = row.lastDiet[0].dietType;
    tempDietChartTableData.dietLanguage = row.lastDiet[0].dietLanguage;
    tempDietChartTableData.treatmentId = row.lastDiet[0].treatmentId;
    tempDietChartTableData.treatmentName = row.lastDiet[0].treatmentName;
    tempDietChartTableData.startTimeDelay = row.lastDiet[0].startTimeDelay;
    tempDietChartTableData.dietId = row.lastDiet[0]._id;
    tempDietChartTableData.color = row.lastDiet[0].color;
    tempDietChartTableData.patient = tempPatienData;
    tempDietChartTableData.dietOption = 2;
    tempDietChartTableData.mealType = row.lastDiet[0].dietType;
    tempDietChartTableData.selectCalorie = row.lastDiet[0].calorie;
    tempDietChartTableData.email = row.email;
    tempDietChartTableData.firstName = row.firstName;
    tempDietChartTableData.comments = row.lastDiet[0].comments;
    tempDietChartTableData.firstName = row.firstName;
    tempDietChartTableData.mealTime = row.lastDiet[0].mealTime;
    tempDietChartTableData.ptVisitDate = row.lastDiet[0].createdAt;
    tempDietChartTableData.shareDate = row.lastDiet[0].isShareDate;
    tempDietChartTableData.dietLanguage = row.language ? row.language : "1";
    tempDietChartTableData.selectedComments = row.lastDiet[0].comments;
    this.setState({
      dietChartTableData: tempDietChartTableData,
      dietId: row.lastDiet[0]._id,
      dietChatModal: true,
    });
    //this.props.history.push("/diet-history", { row: id });
  }

  allValidate(check) {
    if (!check) {
      formArr = [];
      Object.keys(rules).forEach(function (key) {
        formArr[key] = "TT";
      });
      this.setState({
        formArr,
      });
    }
    if (validation.passes()) {
      if (
        this.state.formData["dob"] === "" &&
        this.state.formData["age"] === ""
      ) {
        this.setState({ dobError: "The DOB or Age is required" });
      } else {
        return 1;
      }
    }
  }

  patientRegistration(evt) {
    evt.preventDefault();

    let formData = this.state.formData;
    formData["patient_id"] = formData.patientId;
    formData["patientId"] = formData.patientId;

    if (this.allValidate(false)) {
      let activeTabVal = formData.econsultation ? "online" : "offline"
      this.setState({ showProcessing: true, activeTab: activeTabVal });
      if (
        this.state.formData.patientId &&
        this.state.formData.patientId !== ""
      ) {
        this.props.patientEditAction(this.state);
        // this.props.addVisitAction(this.state.formData);
        this.setState({ patientClinicIdError: "" });
      } else {
        this.props.patientRegistrationAction(this.state);
        this.setState({ ageDisable: false });
      }
      this.setState({ patientClinicIdError: "" });
    }
    //return validation.errors()
  }

  nameContent(cell, row, enumObject, rowIndex) {
    let colorCls = "";

    if (row.doctorOut === "out" && row.educatorOut === "out") {
      colorCls = "pt-black-color";
    } else {
      colorCls = "pt-blue-color";
    }

    let _row = row;
    _row.activeTab = this.state.activeTab;
    let strPatientStarStatus = ""
    // row.patientStarStatus.toString() === "0" ? "" :
    // row.patientStarStatus.toString() === "1" ? "***" :
    //   row.patientStarStatus.toString() === "2" ? "#" :
    //     row.patientStarStatus.toString() === "3" ? "@" :
    //       row.patientStarStatus.toString() === "4" ? "*****" :
    //         <span style={{ "color": "red" }}>***</span>
    let objStarStatus = {}
    if (row?.patientStarStatus)
      objStarStatus = this.props.starStatusList.find(obj => obj._id === row.patientStarStatus)
    if (objStarStatus)
      strPatientStarStatus = objStarStatus.title

    var remarkDr = "";

    if (row.messageForEducator && row.messageForEducator !== "") {
      remarkDr = <OverlayTrigger placement="bottom" overlay={<Tooltip id="remove">{row.messageForEducator}</Tooltip>}>
        <span className="badge bgred drs-left" ><span className="glyphicon" aria-hidden="true">DR</span></span>
      </OverlayTrigger>
    }

    var remarkP = "";

    if (row.patientRemark && row.patientRemark !== "") {
      remarkP = <OverlayTrigger placement="bottom" overlay={<Tooltip id="remove">{row.patientRemark}</Tooltip>}>
        <span className="badge bgred drs-left" ><span className="glyphicon" aria-hidden="true">R</span></span>
      </OverlayTrigger>
    }


    return (
      <Link
        className="black-txt"
        to="#"
        onClick={(e) => {
          this.handleTaskScreen(row);
        }}
      >
        <div className="doc-label">
          <div className={!row.app ? colorCls : ""}>
            {row.name}{strPatientStarStatus}
            {(row.patientRemark != "" || row.messageForEducator) &&
              <span style={{ paddingLeft: "15px", display: "flex" }}>
                {remarkDr != "" && <span> {remarkDr}</span>}
                {row.patientRemark != "" &&
                  <span>{remarkP}</span>
                }
              </span>
            }

          </div>
        </div>
      </Link>
    );
  }

  onPatientId(e) {
    e.preventDefault();
    let formData = this.state.formData;

    //let id = formData.patientClinicId;
    let data = {
      id: formData.patientClinicId,
      clinic_id: localStorage.getItem("clinicId"),
    };

    this.setState({ chkStatus: true });

    this.props.getPatientDetailAction(data);
  }

  onClear(e) {
    e.preventDefault();
    //  formData['patientClinicId'] = '';

    this.setState({
      formData: {
        patientId: "",
        patientClinicId: "",
        patientEditId: "",
        title: "",
        first_name: "",
        last_name: "",
        gender: "",
        dob: "",
        age: "",
        email: "",
        height: "",
        weight: "",
        city: "",
        remark: "",
        dobd: "",
        phone: "",
      },
    });
  }

  validDate(current) {
    var yesterday = moment().toDate();
    return current.isBefore(yesterday);
  }

  handleSearch(data) {
    let search_date = moment(data).format("YYYY-MM-DD");
    this.setState({
      search_date: search_date,
      visitCount: 0,
      eVisitCount: 0,
      outPatientCount: 0,
      activeTab: "offline"
    });
    this.props.visitListAction(search_date, "", "", "searchDate");
  }

  renderDay(props, currentDate, selectedDate) {
    let list = this.state.holidayList;
    let holiday_date = JSON.parse(localStorage.getItem("holiday_date"));
    let calenderDate = moment(currentDate).format("YYYY-MM-DD");
    let todayDate = moment().format("YYYY-MM-DD");

    var extClass = "";

    let exit = 0;
    let exit1 = 0;
    let currentDate2 = 0;
    for (let x in list) {
      let dates = list[x].calendarDate;
      if (dates[0]) {
        for (let j in dates) {
          let eventDate = moment(dates[j]).format("YYYY-MM-DD");
          let newDate = moment(currentDate).format("YYYY-MM-DD");
          if (eventDate === newDate) {
            exit = 1;
            props["title"] = list[x].message;
            if (list[x].type === "leave") {
              exit1 = 1;
            }
          }
        }
      }
    }
    //clinic leave admin side
    if (holiday_date.length > 0) {
      for (let p in holiday_date) {
        let holidayDate = moment(holiday_date[p]).format("YYYY-MM-DD");
        let newDate = moment(currentDate).format("YYYY-MM-DD");
        if (holidayDate === newDate) {
          exit = 1;
          exit1 = 1;

          extClass = " weekoffPink-bg";
        }
      }
    }
    //end clinic leave admin side
    // current date color
    if (calenderDate === todayDate) {
      exit = 1;
      currentDate2 = 1;
    }
    // current date color end
    if (exit === 1) {
      if (exit1 === 1) {
        extClass = extClass !== "" ? extClass : " red-bg";
      } else if (currentDate2 === 1) {
        extClass = " green-bg";
      } else {
        extClass = extClass !== "" ? extClass : " red-bg";
      }
    } else {
      extClass = " gray";
    }

    let weekDays = JSON.parse(localStorage.getItem("is_week_off"));

    if (weekDays.length > 0) {
      for (let i in weekDays) {
        if (weekDays[i].day === currentDate.day()) {
          extClass = " weekoffPink-bg";
        }
      }
    }
    props["className"] = props["className"] + extClass;

    return <td {...props}>{currentDate.date()}</td>;
  }

  sortvisitListData(econsStatus) {
    let { allvisitList, econsultation, eVisitCount, visitCount } = this.state;
    econsultation = econsStatus;
    let visitList = [];
    if (econsStatus === true) {
      visitList = allvisitList.filter((item) => item.econsultation);
      eVisitCount = visitList.filter((item) => item.doctorOut === "in").length;
      visitCount = allvisitList.filter(
        (item) => !item.econsultation && item.doctorOut === "in"
      ).length;
    }

    if (econsStatus === false) {
      visitList = allvisitList.filter((item) => !item.econsultation);
      visitCount = visitList.filter((item) => item.doctorOut === "in").length;
      eVisitCount = allvisitList.filter(
        (item) => item.econsultation && item.doctorOut === "in"
      ).length;
    }

    if (this.props.history?.location?.goToHome) {
      let homePid = this.props.history?.location?.goToHome;
      var localindex_index = visitList
        .map(function (el) {
          return el.pId;
        })
        .indexOf(homePid);
      if (localindex_index != -1) {
        let localData = visitList[localindex_index];
        visitList.splice(localindex_index, 1);
        visitList.unshift(localData);
      }
    }

    this.setState({
      ...this.state,
      econsultation,
      visitList,
      eVisitCount,
      visitCount,
    });
  }

  search_date(props) {
    return (
      <FormGroup className="mop-0">
        <div className="form-liness-outline w550 sm-wwi btn-Mng">
          <VisitListButton
            visitCount={this.props.visitCount}
            eVisitCount={this.props.eVisitCount}
            outPatientCount={this.props.outPatientCount}
            getVisitList={this.getVisitList}
            activedTab={this.state.activeTab}
          />

          {/* <Datetime
                    timeFormat={false}
                    inputProps={{ placeholder: "DD-MM-YYYY",readOnly:true }}
                    dateFormat={"DD-MM-YYYY"}
                    maxDate={new Date()}
                    defaultValue={new Date()}
                    renderDay={ this.renderDay }
                    onChange={(event) => this.handleSearch(event)}
                    className='cs-datepick'
                /> */}
          <Calendar
            selectedDate={this.state.search_date}
            handleChange={(e) => this.handleSearch(e, "date")}
          />
          {props.searchField}
          {props.clearBtn}
        </div>
      </FormGroup>
    );
  }

  async getVisitList(mode) {
    varActiveTab = mode
    this.setState({ activeTab: mode })
    if (mode === "offline") {
      let outList = this.props.VisitList.filter((obj) => obj.doctorOut != "in");
      let inList = this.props.VisitList.filter((obj) => obj.doctorOut === "in");
      let visitListData = [];
      if (outList.length > 0) {
        visitListData = [...outList, ...inList];
      } else {
        visitListData = inList;
      }
      //console.log(this.props.VisitList,"visitList")
      (!this.props.isOfflineApiCalled && this.props.VisitList.length) === 0 ? this.props.visitListAction(moment(this.state.search_date).format("YYYY-MM-DD")) :
        this.setState({ visitList: visitListData, econsultation: false, isLoading: false })
    }

    if (mode === "online") {
      let outList = this.props.eVisitList.filter((obj) => obj.doctorOut != "in");
      let inList = this.props.eVisitList.filter((obj) => obj.doctorOut === "in");
      let evisitListData = [];
      if (outList.length > 0) {
        evisitListData = [...outList, ...inList];
      } else {
        evisitListData = inList;
      }
      (!this.props.isOnilneApiCalled && this.props.eVisitList.length === 0) ?
        this.props.visitListAction(moment(this.state.search_date).format("YYYY-MM-DD"), "", "", "online")
        : this.setState({ visitList: evisitListData, econsultation: true, isLoading: false })
    }

    if (mode === "OutPatientList") {
      (!this.props.isOutListApiCalled && this.props.outPatientList.length) === 0 ? this.props.visitListAction(moment(this.state.search_date).format("YYYY-MM-DD"), "", "", "OutPatientList") :
        this.setState({ visitList: this.props.outPatientList, isLoading: false })
    }

  }

  tagsContent(cell, row, enumObject, rowIndex) {

    let html = ""
    html = <span className="doc-label-flex doc-label" style={{ flexWrap: 'wrap' }}>{
      row.patientATag && row.patientATag.map((obj, key) => {
        return (<img title={obj.tagName} src={obj.s3Path}></img>)
      })
    }
      {row.patientBTag && row.patientBTag.map((obj, key) => {
        return (<img title={obj.tagName} src={obj.s3Path}></img>)
      })
      }
      {row.patientCTag && row.patientCTag.map((obj, key) => {
        return (<img title={obj.tagName} src={obj.s3Path}></img>)
      })
      }

      {/* {row.patientCTag && row.patientCTag.map((obj,key)=>{
       let date1 = moment(obj.createdAt);
       let date2=moment(row.createDate);
       let dateDiffrence=0
       if(date1<date2)
          dateDiffrence =date2.diff(date1, 'days')
      else dateDiffrence =date1.diff(date2, 'days') 
       
        console.log(date1,date2,dateDiffrence,row.name,"3 days")
       if(dateDiffrence<=3){
          filteredCtagArr.push(obj)
          return (<img  title={obj.tagName} src={obj.s3Path}></img>)
       }
          
      //return (<img  src={obj.s3Path}></img>)
      })
     } */}
    </span>

    return html
  }

  getLabData(data) {
    let tempData = this.state.formData
    tempData = { ...data }
    this.setState({ formData: tempData, labTests: false })


  }

  onDismissMoreDetail() {
    this.setState({
      moreDetails: false,
      // moreDetailsData: {},
    })
    this.successAlert("Data Saved Successfully")
  }

  onDismissLabTests() {
    this.setState({ labTests: false })
    this.successAlert("Lab Data Saved Successfully")
  }


  render() {
    validation = new Validator(this.state.formData, rules, mess);
    validation.passes();
    validation.fails();
    //let title = this.state.formData.title;

    const options = {
      afterDeleteRow: this.handleDeleteButtonClick,
      noDataText: this._setTableOption(),
      searchPanel: (props) => this.search_date(props),
      searchPosition: "left",
      clearSearch: true,
    };
    //console.log(this.state, "oooooooooo")
    return (
      <Row>
        {/* <div
          className="actionProcess"
          style={{ display: this.state.showProcessing ? "block" : "none" }}
        >
          <img src={loadingImg} alt="Loading" width="40px" />
          <center>Logging In - Please Wait</center>
        </div> */}
        <NotificationSystem ref="notificationSystem" style={style} />
        <Col sm={12} md={7}>
          <div className="bg-boxess-doctor w-sm12 table-responsive p-low-btn">
            <VisitListTableReceptioonist
              visitList={this.state.visitList}
              options={options}
              getIndex={this.getIndex}
              getPatientOutClass={this.getPatientOutClass}
              taskScreen={this.taskScreen}
              getOutClass={this.getOutClass}
              tagsContent={this.tagsContent}
              nameContent={this.nameContent}
              ageContent={this.ageContent}
              cityContent={this.cityContent}
              isApp={this.isApp}
              action={this.action}
            />
          </div>
        </Col>
        <Col sm={12} md={5}>
          <div className="no-padding" id="task-content">
            <div
              className="main-content spc-cal"
              style={{ padding: "0px 0px" }}
            >
              {this.state.alert}
              <Col className="educator-header padmomb">
                <Row>
                  <Col md={12}>
                    <Card
                      title="Patient Registration"
                      content={
                        <Form horizontal>
                          {/*<Col sm={12} className="patienSearchBox pl10">
                                                    <FormGroup>
                                                       <Col sm={2}>
                                                         </Col>
                                                          <Col sm={6}>
                                                            <FormControl type="text" name="patientClinicId" placeholder="Patient Id" id="patientClinicId" value={this.state.formData.patientClinicId} onChange={e => { this.handleChange(e); }} autoComplete="off" />
                                                             <span className="errorMsg">
                                                                {this.state.patientClinicIdError}
                                                                {this.state.formArr.patientClinicId && validation.errors.first('patientClinicId')}</span>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <button type="button" onClick={this.onPatientId.bind(this)} className="btn-fill btn-wd btn btn-info ss-r">Search</button>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <button type="button" onClick={(e)=>{this.onClear(e)}} className="btn-fill ss-r btn-wd btn btn-info mTpv">Clear</button>
                                                        </Col>
                                                    </FormGroup>
                                                  </Col>*/}

                          <Col sm={12} className="ml-10">
                            <FormGroup>
                              <Col componentClass={ControlLabel} sm={4}>
                                Title <span className="star">*</span>
                              </Col>
                              <Col sm={8}>
                                <select
                                  className="form-control"
                                  name="title"
                                  value={
                                    this.state.formData.title
                                      ? this.state.formData.title
                                      : ""
                                  }
                                  onChange={(event) => this.handleChange(event)}
                                >
                                  <option value="">Select title</option>
                                  {titleOptions.map(function (item) {
                                    // let selected = false;
                                    // if (title === item.label) {
                                    //     selected = true;
                                    // }
                                    return (
                                      <option
                                        key={item.value}
                                        value={item.label}
                                      >
                                        {item.label}
                                      </option>
                                    );
                                  })}
                                </select>
                                <span className="errorMsg">
                                  {this.state.titleError}
                                  {this.state.formArr.title &&
                                    validation.errors.first("title")}
                                </span>
                              </Col>
                            </FormGroup>
                            <FormGroup>
                              <Col componentClass={ControlLabel} sm={4}>
                                First Name <span className="star">*</span>
                              </Col>
                              <Col sm={8}>
                                <FormControl
                                  type="text"
                                  name="first_name"
                                  id="first_name"
                                  value={
                                    this.state.formData.first_name
                                      ? this.state.formData.first_name
                                      : ""
                                  }
                                  onChange={(e) => {
                                    this.handleChange(e);
                                  }}
                                  autoComplete="off"
                                />
                                <small>
                                  Note:-Do not write any title in the first
                                  name, please select it from the title drop
                                  down.
                                </small>
                                <span className="errorMsg">
                                  {this.state.first_nameError}
                                  {this.state.formArr.first_name &&
                                    validation.errors.first("first_name")}
                                </span>
                              </Col>
                            </FormGroup>

                            <FormGroup>
                              <Col
                                componentClass={ControlLabel}
                                sm={4}
                                className="um-mandentory"
                              >
                                Last Name
                              </Col>
                              <Col sm={8}>
                                <FormControl
                                  type="text"
                                  name="last_name"
                                  id="last_name"
                                  className="unmandt-f"
                                  value={
                                    this.state.formData.last_name
                                      ? this.state.formData.last_name
                                      : ""
                                  }
                                  onChange={(e) => {
                                    this.handleChange(e);
                                  }}
                                  autoComplete="off"
                                />
                                <span className="errorMsg">
                                  {this.state.last_nameError}
                                  {this.state.formArr.last_name &&
                                    validation.errors.first("last_name")}
                                </span>
                              </Col>
                            </FormGroup>

                            <FormGroup>
                              <Col componentClass={ControlLabel} sm={4}>
                                Gender <span className="star">*</span>
                              </Col>
                              <Col sm={8}>
                                <select
                                  className="form-control"
                                  name="gender"
                                  value={
                                    this.state.formData.gender
                                      ? this.state.formData.gender
                                      : ""
                                  }
                                  onChange={(e) => {
                                    this.handleChange(e);
                                  }}
                                >
                                  <option value="">-- Select Gender --</option>
                                  <option
                                    value="Female"
                                    selected={
                                      this.state.formData.gender === "Female"
                                        ? true
                                        : false
                                    }
                                  >
                                    Female
                                  </option>
                                  <option
                                    value="Male"
                                    selected={
                                      this.state.formData.gender === "Male"
                                        ? true
                                        : false
                                    }
                                  >
                                    Male
                                  </option>
                                  {/* <option value="Other" selected={this.state.formData.gender==='Other'?true:false}>Other</option> */}
                                </select>
                                <span className="errorMsg">
                                  {this.state.genderError}
                                  {this.state.formArr.gender &&
                                    validation.errors.first("gender")}
                                </span>
                              </Col>
                            </FormGroup>

                            <FormGroup className="date_sel">
                              <Col componentClass={ControlLabel} sm={4}>
                                DOB/Age<span className="star">*</span>
                              </Col>
                              <Col sm={5} className="cus_datepicker">
                                <DatePicker
                                  selected={
                                    this.state.formData.dobd
                                      ? this.state.formData.dobd
                                      : ""
                                  }
                                  placeholderText="dd/mm/yyyy"
                                  className="form-control"
                                  name="dob"
                                  maxDate={new Date()}
                                  onChange={this.handleDob}
                                  disabled={this.state.ageDisable}
                                  dateFormat="dd/MM/yyyy"
                                  //value={this.state.formData.dobd?this.state.formData.dobd:''}
                                  //value={this.state.formData.dobd && this.state.formData.is_dob === true?this.state.formData.dobd:''}
                                  isValidDate={this.validDate}
                                  closeOnSelect={false}
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  autoComplete="off"
                                  customInput={
                                    <MaskedInput
                                      mask="11/11/1111"
                                      placeholder="dd/mm/yyyy"
                                    />
                                  }
                                />
                                <span className="errorMsg">
                                  {this.state.dobError}
                                  {this.state.formArr.dob &&
                                    validation.errors.first("dob")}
                                </span>
                              </Col>
                              <Col sm={3}>
                                {/* <FormControl type="text" name="age" id="age" disabled value={this.state.formData.age}/>
                                                <span className="errorMsg">
                                                {this.state.ageError}
                                                {this.state.formArr.age && validation.errors.first('age')}</span> */}

                                <FormControl
                                  type="text"
                                  name="age"
                                  id="age"
                                  value={
                                    this.state.formData.age >= 0
                                      ? this.state.formData.age
                                      : ""
                                  }
                                  onChange={(e) => {
                                    this.handleAge(e);
                                  }}
                                  autoComplete="off"
                                />
                                <span className="errorMsg">
                                  {this.state.ageError}
                                  {this.state.formArr.age &&
                                    validation.errors.first("age")}
                                </span>
                              </Col>
                            </FormGroup>

                            <FormGroup>
                              <Col
                                componentClass={ControlLabel}
                                sm={4}
                                className="um-mandentory"
                              >
                                Phone
                                {localStorage.getItem("is_mobile_no_mandatory") === "yes" ?
                                  <span className="star">*</span> : ""
                                }
                              </Col>
                              <Col sm={8}>
                                <FormControl
                                  type="text"
                                  name="phone"
                                  id="phone"
                                  className="unmandt-f"
                                  maxLength={12}
                                  value={
                                    this.state.formData.phone
                                      ? this.state.formData.phone
                                      : ""
                                  }
                                  onChange={(e) => {
                                    this.handleChange(e);
                                  }}
                                  autoComplete="off"
                                />
                                <span className="errorMsg">
                                  {this.state.phoneError}
                                  {this.state.formArr.phone &&
                                    validation.errors.first("phone")}
                                </span>
                              </Col>
                            </FormGroup>
                          </Col>
                          <Col sm={12}>
                            {localStorage.getItem("isEmailFacility") ===
                              "yes" ? (
                              <FormGroup>
                                <Col
                                  componentClass={ControlLabel}
                                  sm={4}
                                  className="um-mandentory"
                                >
                                  Email
                                </Col>
                                <Col sm={8}>
                                  <FormControl
                                    type="email"
                                    name="email"
                                    id="email"
                                    className="unmandt-f"
                                    value={
                                      this.state.formData.email
                                        ? this.state.formData.email
                                        : ""
                                    }
                                    onChange={(e) => {
                                      this.handleChange(e);
                                    }}
                                    autoComplete="off"
                                  />
                                  <span className="errorMsg">
                                    {this.state.emailError}
                                    {this.state.formArr.email &&
                                      validation.errors.first("email")}
                                  </span>
                                </Col>
                              </FormGroup>
                            ) : (
                              ""
                            )}

                            <FormGroup>
                              <Col componentClass={ControlLabel} sm={4}>
                                Height(CM)
                                <span style={{ color: "blue" }}>*</span>
                                <br />
                                <small>Optional</small>
                              </Col>
                              <Col sm={8}>
                                <FormControl
                                  type="text"
                                  name="height"
                                  id="height"
                                  maxLength="4"
                                  value={
                                    this.state.formData.height
                                      ? this.state.formData.height
                                      : ""
                                  }
                                  onChange={(e) => {
                                    if (e.target.value.length < 4) {
                                      this.handleChange(e, "height");
                                      this.setState({ ...this.state, lengthHeight: false });
                                    } else this.setState({ ...this.state, lengthHeight: true });
                                  }}
                                  autoComplete="off"
                                />
                                {this?.state?.lengthHeight && (
                                  <div style={{ color: "red" }}>
                                    <p style={{ fontSize: "13px" }}>
                                      Maximum 3 Character Allowed
                                    </p>
                                  </div>
                                )}
                                <span className="errorMsg">
                                  {this.state.heightError}
                                  {this.state.formArr.height &&
                                    validation.errors.first("height")}
                                </span>
                              </Col>
                            </FormGroup>

                            <FormGroup>
                              <Col componentClass={ControlLabel} sm={4}>
                                Weight(KG)
                                <span style={{ color: "blue" }}>*</span>
                                <br />
                                <small>Optional</small>
                              </Col>
                              <Col sm={8}>
                                <FormControl
                                  type="text"
                                  name="weight"
                                  id="weight"
                                  maxLength="7"
                                  value={
                                    this.state.formData.weight
                                      ? this.state.formData.weight
                                      : ""
                                  }
                                  onChange={(e) => {
                                    if (e.target.value.length < 7) {
                                      this.handleChange(e, "weight");
                                      this.setState({ ...this.state, lengthWeight: false });
                                    } else this.setState({ ...this.state, lengthWeight: true });
                                  }}
                                  autoComplete="off"
                                />
                                {this?.state?.lengthWeight && (
                                  <div style={{ color: "red" }}>
                                    <p style={{ fontSize: "13px" }}>
                                      Maximum 5 Character Allowed
                                    </p>
                                  </div>
                                )}
                                <span className="errorMsg">
                                  {this.state.weightError}
                                  {this.state.formArr.weight &&
                                    validation.errors.first("weight")}
                                </span>
                              </Col>
                            </FormGroup>

                            <FormGroup>
                              <Col
                                componentClass={ControlLabel}
                                sm={4}
                                className="um-mandentory"
                              >
                                City
                              </Col>
                              <Col sm={8}>
                                <FormControl
                                  type="text"
                                  name="city"
                                  id="city"
                                  className="unmandt-f"
                                  value={
                                    this.state.formData.city
                                      ? this.state.formData.city
                                      : ""
                                  }
                                  onChange={(e) => {
                                    this.handleChange(e);
                                  }}
                                  autoComplete="off"
                                />
                                <span className="errorMsg">
                                  {this.state.cityError}
                                  {this.state.formArr.city &&
                                    validation.errors.first("city")}
                                </span>
                              </Col>
                            </FormGroup>

                            <FormGroup>
                              <Col
                                componentClass={ControlLabel}
                                sm={4}
                                className="um-mandentory"
                              >
                                Remark
                              </Col>
                              <Col sm={8}>
                                <FormControl
                                  rows="4"
                                  componentClass="textarea"
                                  name="remark"
                                  bsClass="form-control unmandt-f"
                                  value={
                                    this.state.formData.remark
                                      ? this.state.formData.remark
                                      : ""
                                  }
                                  onChange={(e) => {
                                    this.handleChange(e);
                                  }}
                                  autoComplete="off"
                                />
                                <span className="errorMsg">
                                  {this.state.remarkError}
                                  {this.state.formArr.remark &&
                                    validation.errors.first("remark")}
                                </span>
                              </Col>
                            </FormGroup>
                            <FormGroup>
                              <Col sm={12} md={4}></Col>
                              <Col sm={12} md={8} className="e-consultation">
                                <Col
                                  componentClass={ControlLabel}
                                  className="um-mandentory"
                                >
                                  <form className="consultation-radio">
                                    <input
                                      name="econsultation"
                                      type="radio"
                                      id="OPD-Consultation"
                                      value={false}
                                      onClick={(e) => {
                                        this.setState({ isOpdConsultation: true, isOnlineConsultation: false })
                                        this.handleChange(e);
                                      }}
                                      checked={
                                        this.state.isOpdConsultation ===
                                          true
                                          ? "true"
                                          : ""
                                      }
                                    />
                                    <label for="OPD-Consultation">
                                      OPD Consultation
                                    </label>
                                    <br />
                                    <input
                                      name="econsultation"
                                      type="radio"
                                      id="online-consultation"
                                      value={true}
                                      onClick={(e) => {
                                        this.setState({ isOnlineConsultation: true, isOpdConsultation: false })
                                        this.handleChange(e);
                                      }}
                                      checked={
                                        this.state.isOnlineConsultation ===
                                          true
                                          ? "true"
                                          : ""
                                      }
                                    />
                                    <label for="online-consultation">
                                      Online Consultation
                                    </label>
                                  </form>
                                </Col>
                              </Col>
                              <span className="errorMsg">
                                {this.state.econsultationError}
                                {this.state.formArr.econsultation &&
                                  validation.errors.first("econsultation")}
                              </span>
                            </FormGroup>
                          </Col>
                          <FormGroup>
                            <Col sm={12}>
                              <Col sm={4}>
                                <button
                                  type="button"
                                  onClick={this.patientRegistration.bind(this)}
                                  className="btn-fill btn-wd btn btn-primary"
                                // disabled={this.state.showProcessing}
                                >
                                  {this.state.formData.patientId &&
                                    this.state.formData.patientId !== ""
                                    ? "Update"
                                    : "Save"}
                                </button>
                              </Col>
                              <Col sm={4}>
                                {this.state.formData.patientId &&
                                  this.state.formData.patientId !== "" && (
                                    <button
                                      type="button"
                                      onClick={(e) => {
                                        this.setState({ formData: {}, pId: "" })
                                      }
                                      }
                                      className="btn-fill btn-wd btn btn-primary"
                                    // disabled={this.state.showProcessing}
                                    >
                                      Clear
                                    </button>
                                  )}
                              </Col>

                            </Col>
                          </FormGroup>
                        </Form>

                      }
                    />
                  </Col>
                </Row>
              </Col>
            </div>
          </div>
        </Col>
        <Modal
          backdrop="static"
          className="pa-next-visit"
          show={this.state.nextVisit}
          onHide={() => this.setState({ nextVisit: false })}
          dialogClassName="modal-lg"
        >
          <Modal.Header closeButton className="bg-thumbcolor">
            <Modal.Title id="example-modal-sizes-title-lg">
              Set Next Visit
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="Knowledge-Share ">
            <Row>
              <Col md={12}>
                <NextVisit
                  nextVisitData={this.state.nextVisitData}
                  patientOutFun={this.patientVisitOut}
                  patientOut={this.state.patientOut}
                  onDismissNextVisit={this.onDismissNextVisit}
                />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
        <Modal
          className="pa-patient-password"
          show={this.state.showPassword}
          onHide={() =>
            this.setState({ showPassword: false, patientPasswordData: {} })
          }
        >
          <Modal.Header closeButton className="bg-thumbcolor">
            <Modal.Title id="example-modal-sizes-title-lg">
              User Password
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="Knowledge-Share card">
            <div className="clinic-user-password">
              <div>
                <b>Patient Id: </b>
                <span>{this.state.patientPasswordData.patientId}</span>
              </div>
              <div>
                <b>User Name: </b>
                <span>{this.state.patientPasswordData.userName}</span>
              </div>
              <div>
                <b>Password: </b>
                <span>{this.state.patientPasswordData.password}</span>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          backdrop="static"
          className="pa-task-screen"
          show={this.state.dietChatModal}
          onHide={this.backPage}
          dialogClassName="modal-lg"
        >
          <button type="button" className="close" onClick={this.backPage}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
          <Modal.Body className="Knowledge-Share ">
            <Row>
              <Col md={12}>
                {this.state.dietChatModal && (
                  <DietChat
                    state={this.state.dietChartTableData}
                    backPage={this.backPage}
                    dietId={this.state.dietId}
                    onClickTime={this.changeMealTime}
                    color={this.state.dietChartTableData.color}
                  />
                )}
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
        <Modal
          backdrop="static"
          className="pa-next-visit modal-more-info"
          show={this.state.moreDetails}
          onHide={() => this.setState({ moreDetails: false })}
          dialogClassName="modal-xl"
        >
          <Modal.Header closeButton className="bg-thumbcolor">
            <Modal.Title id="example-modal-sizes-title-lg">
              <div className="row">
                <div className="col-md-8 col-sm-12 text-left">
                  <div className="title">
                    {`${this.state.moreDetailsData.firstName
                      }
                                            - 
                                        ${this.state.moreDetailsData.patientId
                      } \u00A0\u00A0`}
                    {this.state.starStatus !== "" && <>|<span
                      style={{
                        display: "inline-block",
                        margin: "0 5px",
                      }}
                    >{this.state.starStatus}
                    </span> </>
                    }
                    |
                    <span
                      style={{
                        display: "inline-block",
                        margin: "0 5px",
                      }}
                    >
                      Age
                      {this.state.moreDetailsData &&
                        this.state.moreDetailsData?.age != 0
                        ? ":"
                        : ""}{" "}
                      {parseFloat(
                        this.state.moreDetailsData.age
                      ).toFixed()}
                      {` Y / ${this.state.moreDetailsData.gender === "Male"
                        ? "M"
                        : "F"
                        }`}
                    </span>
                    {this.state.moreDetailsData.phone !== "" && (
                      <>
                        |
                        <span
                          style={{
                            display: "inline-block",
                            margin: "0 5px",
                          }}
                        >
                          contact: {this.state.moreDetailsData.phone}
                        </span>{" "}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="Knowledge-Share ">
            <PatientMoreDetailEducator
              moreDetails={this.state.moreDetailsData}
              search_date={this.state.search_date}
              onDismissMoreDetail={this.onDismissMoreDetail}
            />
          </Modal.Body>
        </Modal>
        {/* <Modal
          backdrop="static"
          className="pa-next-visit"
          show={this.state.labTests}
          onHide={() => this.setState({ labTests: false })}
          dialogClassName="modal-lg"
        >
          <Modal.Header closeButton className="bg-thumbcolor">
            <Modal.Title id="example-modal-sizes-title-lg">
              Lab Tests
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="Knowledge-Share ">
            <Row>
              <Col md={12}>
                <LabTest
                  patientLabData={this.state.formData}
                  getLabData={this.getLabData}
                  onDismissLabTests={this.onDismissLabTests}
                />
              </Col>
            </Row>
          </Modal.Body>
        </Modal> */}
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    VisitList: state.home.VisitList,
    isVisitList: state.home.isVisitList,
    isVisitListError: state.home.isVisitListError,
    eVisitList: state.home.eVisitList,
    outPatientList: state.home.outPatientList,
    visitListActiveTab: state.home.visitListActiveTab,
    otherVisitDate: state.home.otherVisitDate,
    isVisitListUpdated: state.home.isVisitListUpdated,
    visitCount: state.home.visitCount,
    eVisitCount: state.home.eVisitCount,
    outPatientCount: state.home.outPatientCount,
    isAddToVisitList: state.home.isAddToVisitList,
    isOutListApiCalled: state.home.isOutListApiCalled,
    isOnilneApiCalled: state.home.isOnilneApiCalled,
    isOfflineApiCalled: state.home.isOfflineApiCalled,
    isStarStatusList: state.home.isStarStatusList,
    isStarStatusListError: state.home.isStarStatusListError,
    starStatusList: state.home.starStatusList,
    isReloadHome: state.home.isReloadHome,
    patientEmrData: state.emr.patientEmrData,

    PatientOut: state.home.PatientOut,
    isPatientOut: state.home.isPatientOut,
    isPatientOutError: state.home.isPatientOutError,

    PatientRegistrationMessage: state.home.PatientRegistration,
    isPatientRegistration: state.home.isPatientRegistration,
    isPatientRegistrationError: state.home.isPatientRegistrationError,

    PatientEditMessage: state.home.PatientEdit,
    isPatientEdit: state.home.isPatientEdit,
    isPatientEditError: state.home.isPatientEditError,

    PatientDetail: state.home.PatientDetail,
    isPatientDetail: state.home.isPatientDetail,
    isPatientDetailError: state.home.isPatientDetailError,
    PatientDetailMessage: state.home.message,

    addVisit: state.patient.addVisit,
    isAddVisit: state.patient.isAddVisit,
    isAddVisitError: state.patient.isAddVisitError,

    HolidayList: state.home.HolidayList,
    isHolidayList: state.home.isHolidayList,
    isHolidayListError: state.home.isHolidayListError,

    isTagList: state.home.isTagList,
    isTagListError: state.home.isTagListError,
    TagList: state.home.TagList,

    isS3SignedUrlArr: state.home.isS3SignedUrlArr,
    isVisitListUpdateS3SignedUrlOnlineList: state.home.isVisitListUpdateS3SignedUrlOnlineList,
    isVisitListUpdateS3SignedUrlOutList: state.home.isVisitListUpdateS3SignedUrlOutList,
    isPatientRemarkUpdated: state.home.isPatientRemarkUpdated
  };
}
export default withRouter(
  connect(mapStateToProps, {
    patientRegistrationAction,
    patientEditAction,
    visitListAction,
    patientOutAction,
    getPatientDetailAction,
    addVisitAction,
    holidayListAction,
    setVisitListActiveTabAction,
    updateVisitListAction,
    tagListAction,
    s3SiginedUrlArrayAction,
    UpdateVisitListS3SiginedUrlArrayAction,
    UpdateVisitListS3SiginedUrlArrayOnlineListAction,
    UpdateVisitListS3SiginedUrlArrayOutListAction,
    getOnlineVisitListAction,
    starStatusListAction,
    taskScreenAction,
    emrSetAction
  })(ReceptionistScreen)
);
